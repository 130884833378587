import { CustomerEdge } from '../graphql/codegen/schemas'
import useCurrentUser from './useCurrentUser'

// hooks

export const useAreFailureNotificationsEnabled = () => {
  const currentUser = useCurrentUser()

  if (!currentUser) return false

  return (currentUser.customers?.edges as CustomerEdge[]).some(edge => edge.node?.config?.failure_notifications_enabled === true)
}
