import { CellView } from './CellView'
import { List, ListItem } from '@mui/material'
import { isEmpty, size } from 'lodash'
import React, { FC } from 'react'

// types

export type _ErrorValue = { error_code: string; error_message: string }
type _ErrorsCellViewProps = { value?: { value: _ErrorValue[] } }
type _ExpandedViewProps = { errors: _ErrorValue[] }

// functions

export const getErrorsColumn = (areFailureNotificationsEnabled: boolean) =>
  areFailureNotificationsEnabled ? [{ headerName: 'Errors', accessor: 'errors', disableSort: true, Cell: ErrorsCell }] : []

// components

const ErrorsCell: FC<_ErrorsCellViewProps> = ({ value }) => {
  if (!value?.value || isEmpty(value.value)) return null

  const errors = value.value
  const textValue = size(errors) > 1 ? errors.map(error => `'${error.error_message}'`).join(', ') : errors[0].error_message

  return <CellView expandedView={<ExpandedView errors={errors} />} header="Errors" textValue={textValue} />
}

export const ExpandedView: FC<_ExpandedViewProps> = ({ errors }) => (
  <List sx={{ my: -0.75, p: 0 }}>
    {errors.map((error, index) => (
      <ListItem key={index} sx={{ fontSize: 14, px: 0 }}>
        {error.error_message}
      </ListItem>
    ))}
  </List>
)
