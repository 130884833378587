import { AlertCircle } from 'react-feather'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import css from './style.module.scss'

function DocumentPlaceholder({ error, ...rest }: { [k: string]: any; error?: boolean }) {
  const loadingState = (
    <>
      <Skeleton height={24} width={200} />
      {/* @ts-ignore */}
      {[...Array(5).keys()].map((_, idx) => {
        return (
          <div className={css.paragraph} key={`documentSkeleton-para-${idx}`}>
            <h2>
              <Skeleton width="30%" />
            </h2>
            <Skeleton count={4} />
          </div>
        )
      })}
    </>
  )

  const errorState = (
    <div className={css.errorState}>
      <AlertCircle />
      <h3>Something went wrong fetching this document.</h3>
    </div>
  )

  return (
    <div className={css.documentPlaceholder} {...rest}>
      {error ? errorState : loadingState}
    </div>
  )
}

export default DocumentPlaceholder
