import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

// functions

const createResources = (businessWorkflow?: string) => ({
  en: {
    translation: {
      ...(businessWorkflow === 'Order Management' || businessWorkflow === 'Revenue Recognition'
        ? { deal: 'deal', Deal: 'Deal', deals: 'deals', Deals: 'Deals' }
        : { deal: 'folder', Deal: 'Folder', deals: 'folders', Deals: 'Folders' })
    }
  }
})

const createI18n = (businessWorkflow?: string) => {
  i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: createResources(businessWorkflow || 'Revenue Recognition')
  })
  return i18n
}
export { createI18n }
