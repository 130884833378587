import 'tippy.js/dist/tippy.css'
import { Check, X } from 'react-feather'
import Loader from '../Loader'
import React from 'react'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from './style.module.scss'

export default function SaveProgressIcon({ error, loading, success }: { error: any; loading: boolean; success: boolean }) {
  return (
    <span className={css.progressIconWrapper}>
      {loading ? (
        <Loader size="s" />
      ) : error ? (
        <Tippy content={error?.graphQLErrors?.[0]?.message}>
          <X className={clsx(css.xMark, css.error)} />
        </Tippy>
      ) : success ? (
        <Check className={clsx(css.checkMark, css.success)} onAnimationEnd={(e: any) => e.target.classList.remove(css.success)} />
      ) : null}
    </span>
  )
}
