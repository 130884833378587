import { useDocumentsQuery } from '../graphql/codegen/hooks'
import { useIsAnnotator } from './useUserAccess'
import useCurrentUser from './useCurrentUser'

// hooks

export const useIsAssignedToMe = (documentId?: string) => {
  const currentUser = useCurrentUser()
  const { data: documentsData } = useDocumentsQuery({ variables: { documentId }, skip: !documentId })
  const isAnnotator = useIsAnnotator()

  return isAnnotator && documentsData?.documents?.edges[0]?.node?.internal_assignee?.id === currentUser?.id
}
