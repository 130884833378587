import { ArrowLeft, Download } from 'react-feather'
import { Attachment } from '../../graphql/codegen/schemas'
import { BasePDFDoc } from '../PDFDoc'
import { DealAttachmentsDocument, DocumentAttachmentsDocument } from '../../graphql/codegen/hooks'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { SPREADSHEET_FILE_EXTENSIONS, TEXT_FILE_EXTENSIONS, createFileFromBlob } from '../../utils/fileUtils'
import { SpreadsheetFileViewer } from '../SpreadsheetViewer'
import { TextFileViewer } from '../TextFileViewer'
import { Typography } from '@mui/material'
import { useApolloClient } from '@apollo/client'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useKeyPressed } from '../../hooks/useKeyPressed'
import Loader from '../Loader'
import React, { FC, useCallback, useEffect, useState } from 'react'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useAsync from '../../hooks/useAsync'

// hooks

const useAttachment = (id: string, dealId: string, documentId: string) => {
  const [attachment, setAttachment] = useState<null | Attachment>(null)
  const client = useApolloClient()

  useEffect(() => {
    const query = dealId ? DealAttachmentsDocument : DocumentAttachmentsDocument
    const variables = dealId ? { dealId } : { documentId }

    client.query({ query, variables }).then(res => {
      const atts = dealId ? res.data.deal_attachments : res.data.document_attachments
      const att = atts.find((a: any) => a.id === id)

      setAttachment(att)
    })
  }, [id, documentId, dealId, client])

  return attachment
}

// components

export const AttachmentOverlay: FC = () => {
  const [loading, setLoading] = useState(false)
  const [tooltipContent, setTooltipContent] = useState('')
  const { attachmentId, dealId, documentId } = useParams<{ attachmentId: string; dealId: string; documentId: string }>()
  const { url } = useRouteMatch()

  const attachment = useAttachment(attachmentId, dealId, documentId)
  const hasDownloadAccess = useUserAccess({ feature: Features.ATTACHMENT, permission: Permissions.DOWNLOAD })
  const history = useHistory()

  const isEscapePressed = useKeyPressed(event => event.key === 'Escape')

  const isPdf = attachment?.name.slice(-4) === '.pdf' || attachment?.has_pdf_preview
  const isSpreadsheet = Boolean(attachment?.name.match(SPREADSHEET_FILE_EXTENSIONS))
  const isTxt = Boolean(attachment?.name.match(TEXT_FILE_EXTENSIONS))

  const prefix = dealId ? `/deals/${dealId}` : `/documents/${documentId}`

  const { data, error } = useAsync(async () => {
    if (!attachment?.id) return null

    const { data } = await axiosClient.get(`${prefix}/attachments/${attachmentId}?preview=1`, { responseType: 'blob' })

    return isTxt ? await data.text() : isSpreadsheet ? createFileFromBlob(data, attachment.name) : URL.createObjectURL(data)
  }, [attachment?.id, isSpreadsheet, isTxt])

  // functions

  const handleClose = useCallback(() => history.replace(url.split('attachments')[0] + 'attachments'), [history, url])

  const handleDownload = async () => {
    if (attachment?.id && (attachment?.alias || attachment?.name)) {
      setLoading(true)

      const data = await axiosClient
        .get(`${prefix}/attachments/${attachment.id}`, {
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            const total = progressEvent.total ?? 0
            const progress = total ? Math.round((progressEvent.loaded / total) * 100) : 0

            setTooltipContent(`Downloading File: ${progress}%`)
          }
        })
        .then(response => {
          setLoading(false)
          setTooltipContent('')

          return response?.data
        })
        .catch(error => {
          setLoading(false)
          setTooltipContent('')

          return error
        })

      const href = URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = attachment?.alias || attachment?.name
      document.body.appendChild(a)
      a.click()
    }
  }

  // effects

  useEffect(() => {
    if (isEscapePressed) handleClose()
  }, [handleClose, isEscapePressed])

  // render

  return (
    <div className={css.overlay}>
      <div className={css.header}>
        <WithTooltip content="Close Preview" style={{ cursor: 'pointer' }}>
          <span aria-label="Close Preview" onClick={handleClose}>
            <ArrowLeft />
          </span>
        </WithTooltip>

        <span>{attachment?.alias || attachment?.name}</span>

        {hasDownloadAccess && (
          <WithTooltip content={tooltipContent || loading ? 'Downloading File…' : 'Download File'} style={{ cursor: 'pointer' }}>
            {loading ? <Loader variant="light" /> : <Download aria-label="Download File" onClick={handleDownload} />}
          </WithTooltip>
        )}
      </div>

      <div className={css.attachmentWrapper}>
        {error && <Typography sx={{ color: 'white' }}>An error occurred. This file format may not be supported for preview.</Typography>}

        {!error && !data && <Loader variant="light" />}

        {data &&
          (isPdf ? (
            <BasePDFDoc data={data} error={error} />
          ) : isSpreadsheet ? (
            <SpreadsheetFileViewer file={data} id={attachmentId} />
          ) : isTxt ? (
            <TextFileViewer textContent={data} />
          ) : (
            <img alt={attachment?.name} src={data} />
          ))}
      </div>
    </div>
  )
}
