import React, { useEffect, useState } from 'react'

export default function Clock() {
  const [time, setTime] = useState('')

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    const tick = () => {
      const d = new Date()
      const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
      const t = d.toLocaleString().replace(/(.*)\D\d+/, '$1') + ' ' + dtf.formatToParts(d).find(item => item.type === 'timeZoneName')?.value

      setTime(t)
      timeout = setTimeout(tick, 10000)
    }
    tick()

    return () => clearTimeout(timeout)
  }, [])

  return <time>{time}</time>
}
