import React, { CSSProperties, ReactNode } from 'react'
import css from './style.module.scss'

export default function Card({ children, style, ...rest }: { children: ReactNode; rest?: any; style?: CSSProperties }) {
  return (
    <div className={css.root} style={style} {...rest}>
      {children}
    </div>
  )
}
