import { isEmpty, size } from 'lodash'
import { useMemo } from 'react'
import useCurrentUser from './useCurrentUser'

// hooks

export const useSupportedFileExtensions = ({ appendZip = false, multiple = false } = {}) => {
  const currentUser = useCurrentUser()

  const extensions = useMemo(() => {
    const extensions = currentUser?.customers?.edges?.[0]?.node?.metadata?.supported_file_extensions || []

    return appendZip ? [...extensions, '.zip'] : extensions
  }, [appendZip, currentUser])

  const extensionsMessage = useMemo(
    () =>
      isEmpty(extensions)
        ? ''
        : size(extensions) === 1
        ? extensions[0]
        : `${extensions.slice(0, -1).join(', ')} ${multiple ? 'and' : 'or'} ${extensions.slice(-1)}`,
    [extensions, multiple]
  )

  return { extensions, extensionsMessage }
}
