/**
 * NOTE: The save/autosave logic in this component is precarious – tread carefully and test changes thoroughly.
 */
import { DataTableIcon } from './DataTableIcon'
import { EnhancedTextField } from './EnhancedTextField'
import { FeedbackDataTableButton } from '../Feedback'
import { debounce } from 'lodash'
import { useIsFeedbackPage } from '../../pages/FeedbackPage'
import { useNestedChildDataPointsPanelContext } from '../dialogs/NestedChildDataPointsPanel'
import { useTranslation } from 'react-i18next'
import DateInput from './DateInput'
import Dropdown from './Dropdown'
import FreeText from './FreeText'
import NumberInput from './NumberInput'
import Percentage from './Percentage'
import React, { ChangeEvent, FC, KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import WithTooltip from '../WithTooltip'
import type { DataPoint } from '../../graphql/codegen/schemas'

// types

type _DataPointInputProps = {
  annotations: any
  ariaLabel?: string
  dataPoint: DataPoint
  dealIsFinalized: boolean
  editDataPointValueMutation: any
  isDisabled?: boolean
  isExpanded?: boolean
  isFocused: any
  isNestedChildDataPoint?: boolean
  isOptionMulti: boolean
  openModal: any
  setFocused: any
  setValue: any
  value: any
}

// constants

const DELAY_TO_SAVE_INPUT_VALUES = 3000
const FINALIZED_DEAL_TOOLTIP_DELAY = [500, 0]

// functions

const getFormattedNumber = (value: string | number | null | undefined) => (!value ? value : Number(String(value)?.replace(/,/g, ''))) // Remove commas from stringified numbers.

// components

export const DataPointInput: FC<_DataPointInputProps> = ({
  annotations,
  ariaLabel,
  dataPoint,
  dealIsFinalized,
  editDataPointValueMutation,
  isDisabled = false,
  isExpanded,
  isFocused,
  isNestedChildDataPoint,
  isOptionMulti,
  openModal,
  setFocused = () => null,
  setValue,
  value
}) => {
  const { t } = useTranslation()

  const FINALIZED_DEAL_MESSAGE = useMemo(() => `Finalized ${t('deals')} cannot be updated.`, [t])

  const [initialValue, setInitialValue] = useState(value)
  const [shouldSaveUsingDebounce, setShouldSaveUsingDebounce] = useState(false)
  const { expandedNestedChildContentRef, expandedNestedChildDataPoint, setExpandedNestedChildDataPoint } = useNestedChildDataPointsPanelContext()
  const { field_type, value_format } = dataPoint.data_point_field || {}

  const abortControllerRef = useRef(new AbortController())
  const hasUserInteractedAfterSaveRef = useRef(false)
  const lastSavedValueRef = useRef(value)

  const isFeedbackPage = useIsFeedbackPage()

  const handleChange = () => {
    abortControllerRef.current?.abort()

    hasUserInteractedAfterSaveRef.current = true
  }

  const handleSave = useCallback(
    (data: string | string[]) => {
      abortControllerRef.current = new AbortController()
      lastSavedValueRef.current = data

      // Prevents double save when `value` is modified by the server via a GraphQL response (e.g., user submits "$12" and the server responds with "$12.00").
      if (!hasUserInteractedAfterSaveRef.current) return

      editDataPointValueMutation({
        variables: { dataPointId: dataPoint.id, [field_type === 'MULTI_SELECT_DROP_DOWN' ? 'values' : 'value']: data },
        context: { fetchOptions: { signal: abortControllerRef.current.signal } }
      })

      hasUserInteractedAfterSaveRef.current = false
    },
    [dataPoint.id, editDataPointValueMutation, field_type]
  )

  const handleSaveDebouncedValues = useCallback(
    (currentValue: string) => {
      // NOTE: This conditional logic is questionable; however, it has been operating since 02/18/2022 (commit: 7da1344).
      if (
        (currentValue !== '' && initialValue !== null && initialValue !== undefined && (currentValue !== undefined || currentValue !== null)) ||
        currentValue === null
      ) {
        handleSave(currentValue)
      }
    },
    [handleSave, initialValue]
  )

  const handleChange_OMSDD = (values: string[]) => {
    // @ts-ignore
    if (values?.length < dataPoint?.value_list?.length) {
      const removedOption = dataPoint?.value_list?.filter((item: any) => !values?.includes(item)) || []
      const itemAnnotations = annotations?.filter((annotation: any) => annotation?.label_name === removedOption[0]) || []

      if (itemAnnotations?.length > 0) {
        if (removedOption[0]) {
          openModal(removedOption[0], itemAnnotations?.length)
        } else {
          console.error('DatapointInput.tsx error: Option not found')
        }
      } else {
        handleSave(values) // Delete option.
      }
    } else {
      handleSave(values) // Add option.
    }
  }

  const saveDebouncedInputValue = useCallback(debounce(handleSaveDebouncedValues, DELAY_TO_SAVE_INPUT_VALUES), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlur = (event: SyntheticEvent<HTMLInputElement>) => {
    saveDebouncedInputValue.cancel()

    if (event.currentTarget.value !== lastSavedValueRef.current) handleSave(event.currentTarget.value)
  }

  const handleSaveOnEnterKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      saveDebouncedInputValue.cancel()

      const currentValue = (event.currentTarget as HTMLInputElement).value
      const dataPointValue = dataPoint.value_iso_date || dataPoint.display_value || ''
      const hasValueChanged = currentValue !== dataPointValue && getFormattedNumber(currentValue) !== getFormattedNumber(dataPointValue)

      if (hasValueChanged) handleSave(currentValue)
    }
  }

  const nestedChildDataPointTextFieldProps = useMemo(
    () =>
      isNestedChildDataPoint && {
        expandedContainer: expandedNestedChildContentRef?.current,
        isExpanded: dataPoint.id === expandedNestedChildDataPoint?.id,
        maxRows: expandedNestedChildDataPoint ? 0 : 4,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          handleChange()
          setShouldSaveUsingDebounce(Boolean(!expandedNestedChildDataPoint)) // Do not autosave if the input is inside the <ExpandedNestedChildPanel>.
          setValue(event.currentTarget.value || null)
        },
        onExpandButtonClick: () => setExpandedNestedChildDataPoint?.(dataPoint)
      },
    [dataPoint, expandedNestedChildContentRef, expandedNestedChildDataPoint, isNestedChildDataPoint, setValue, setExpandedNestedChildDataPoint]
  )

  useEffect(() => {
    setInitialValue(value)
  }, [value])

  // Autosave when the user changes the input value.
  useEffect(() => {
    if (!shouldSaveUsingDebounce || (initialValue === '' && value) || getFormattedNumber(initialValue) === getFormattedNumber(value)) return

    saveDebouncedInputValue(value)
  }, [initialValue, saveDebouncedInputValue, shouldSaveUsingDebounce, value])

  switch (field_type) {
    case 'TEXT':
    case 'ADDRESS':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <EnhancedTextField
            ariaLabel={ariaLabel}
            handleBlur={handleBlur}
            hyperlinkUrl={dataPoint?.hyperlink_url}
            isDisabled={dealIsFinalized || isDisabled}
            isFocused={isFocused}
            maxRows={8}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange()
              setShouldSaveUsingDebounce(true)
              setValue(event.currentTarget.value || null)
            }}
            setFocused={setFocused}
            value={value || ''}
            {...nestedChildDataPointTextFieldProps}
          />
        </WithTooltip>
      )

    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'DURATION':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <FreeText
            ariaLabel={ariaLabel}
            handleBlur={handleBlur}
            isDisabled={dealIsFinalized || isDisabled}
            isFocused={isFocused}
            onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
              handleChange()
              setShouldSaveUsingDebounce(true)
              setValue(e.currentTarget.value || null)
            }}
            onKeyDown={handleSaveOnEnterKeyDown}
            setFocused={setFocused}
            value={value}
          />
        </WithTooltip>
      )

    case 'BOOLEAN':
    case 'DROP_DOWN':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <Dropdown
            ariaLabel={ariaLabel}
            data_point_field={dataPoint.data_point_field}
            isDisabled={dealIsFinalized || isDisabled}
            isFocused={isFocused}
            onChange={v => {
              handleChange()
              setShouldSaveUsingDebounce(false)
              setValue(v?.label || '')
              handleSave(v?.label || '')
            }}
            setFocused={setFocused}
            value={value ? { value: value.toLowerCase().replace(' ', '_'), label: value } : null}
          />
        </WithTooltip>
      )

    case 'MULTI_SELECT_DROP_DOWN':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <Dropdown
            ariaLabel={ariaLabel}
            data_point_field={dataPoint.data_point_field}
            isDisabled={dealIsFinalized || isDisabled}
            isExpanded={isExpanded}
            isFocused={isFocused}
            isMulti
            onChange={(v: any) => {
              handleChange()
              setShouldSaveUsingDebounce(false)
              if (isOptionMulti) {
                handleChange_OMSDD(v?.map((v: any) => v.label) || [])
              } else {
                setValue(v)
                handleSave(v?.map((v: any) => v.label) || [])
              }
            }}
            setFocused={setFocused}
            value={dataPoint.value_list?.map((v: any) => ({ label: v, value: v }))}
          />
        </WithTooltip>
      )

    case 'PERCENTAGE':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <Percentage
            ariaLabel={ariaLabel}
            handleBlur={handleBlur}
            isDisabled={dealIsFinalized || isDisabled}
            isFocused={isFocused}
            onChange={e => {
              handleChange()
              setShouldSaveUsingDebounce(true)
              setValue(e.currentTarget.value || null)
            }}
            onKeyDown={handleSaveOnEnterKeyDown}
            setFocused={setFocused}
            value={value}
          />
        </WithTooltip>
      )

    case 'FLOAT':
    case 'NUMBER':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <NumberInput
            ariaLabel={ariaLabel}
            isDisabled={dealIsFinalized || isDisabled}
            isFloat={field_type === 'FLOAT'}
            onBlur={handleBlur}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              handleChange()
              if (e?.currentTarget?.value === 'INVALID_VALUE') {
                setShouldSaveUsingDebounce(false)
                return
              }
              setShouldSaveUsingDebounce(true)
              setValue(e?.currentTarget?.value ? e?.currentTarget?.value : null)
            }}
            onKeyDown={handleSaveOnEnterKeyDown}
            value={value}
          />
        </WithTooltip>
      )

    case 'DATA_TABLE':
      return isFeedbackPage ? <FeedbackDataTableButton /> : <DataTableIcon dataPoint={dataPoint} />

    case 'DATE':
      return (
        <WithTooltip content={dealIsFinalized && !isFeedbackPage ? FINALIZED_DEAL_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <DateInput
            ariaLabel={ariaLabel}
            displayFormat={value_format}
            isDisabled={dealIsFinalized || isDisabled}
            onCalendarClose={() => {
              handleChange()
              if (value !== lastSavedValueRef.current) handleSave(value)
            }}
            onChange={v => {
              handleChange()
              setShouldSaveUsingDebounce(false)
              setFocused(false)
              setValue(v || '')
            }}
            setFocused={setFocused}
            value={value}
          />
        </WithTooltip>
      )

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field_type: ${field_type}`)
      }
      return null
  }
}
