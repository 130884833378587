export const CciRolesWithFeaturesDocument = `
  query cciRolesWithFeatures {
    cci_roles {
      id
      name
      features {
        edges {
          node {
            name
            permission
          }
        }
      }
    }
  }
`

export const CciUsersDocument = `
  query cciUsers {
    cci_users {
      id
      first_name
      last_name
      user_name
      roles {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const CurrentUserDocument = `
  query currentUser {
    current_user {
      id
      user_name
      first_name
      last_name
      is_super_admin
      customers {
        edges {
          node {
            id
            name
          }
        }
      }
      roles {
        edges {
          node {
            id
            name
            features {
              edges {
                node {
                  id
                  name
                  permission
                }
              }
            }
          }
        }
      }
    }
  }
`
