import { CommentsDataDocument, NotesTabCountDocument, useCreateCommentMutation } from '../../graphql/codegen/hooks'
import { dataPoints } from '../../graphql/queries/dataPoint.graphql'
import { useParams } from 'react-router-dom'
import CommentBox from '../CommentBox'
import React from 'react'
import css from './style.module.scss'

export default function CommentEditor() {
  const { dataPointId, dealId, documentId } = useParams<{ dataPointId: string; dealId?: string; documentId?: string }>()
  const [createCommentMutation, commentState] = useCreateCommentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: dataPoints, variables: { id: dataPointId } },
      { query: CommentsDataDocument, variables: { resourceId: dealId || documentId! } },
      { query: NotesTabCountDocument, variables: { resourceId: dealId || documentId! } }
    ]
  })

  const handleCreate = (text: string) => {
    createCommentMutation({ variables: { dataPointId, text } })
  }

  return (
    <div className={css.bottomRow}>
      <CommentBox commentState={commentState} handleCreate={handleCreate} />
    </div>
  )
}
