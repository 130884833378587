import { Box, Button, Tab, Tabs } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../hooks/useUserAccess'
import { Link } from 'react-router-dom'
import { SearchBar } from '../../../../../components/DataPointPanel/SearchBar'
import { debounce, isEmpty } from 'lodash'
import { formatKey } from '../../../../../utils/stringUtils'
import { useCciLeftPanelContext } from '../CCI_LeftPanel'
import { useCciMainContext } from '../../../CCI_Main'
import React, { useCallback, useEffect, useRef, useState } from 'react'

// components

export const CCI_LeftPanel_Controls = () => {
  const { setExpandAllCallback, tab } = useCciMainContext()
  const { activeKeys, searchTerm, searchableData, setActiveKeys, setSearchTerm, setSearchableData } = useCciLeftPanelContext()
  const [searchPlaceholder, setSearchPlaceholder] = useState('Search…')
  const [tabsKey, setTabsKey] = useState(0)

  const hasAdminAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ADMIN })
  const hasChecklistTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.CHECKLIST_TAB })
  const hasIntegrationsTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.INTEGRATIONS_TAB })
  const hasAccessControlTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ROLES_TAB })
  const hasUsersTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.USERS_TAB })

  const tabsWrapperRef = useRef<HTMLDivElement>(null)

  const generateActiveKeys = useCallback(() => {
    if (!searchableData) return []

    switch (tab) {
      case undefined:
      case 'checklist':
        return searchableData.map((item: any) => formatKey(item))
      case 'integrations':
        return searchableData.map((item: any) => formatKey(item.node.id))
      case 'roles':
        return searchableData.map((item: any) => formatKey(item.id))
      case 'users':
        return searchableData.map((item: any) => formatKey(item.user_name))
      default:
        return []
    }
  }, [searchableData, tab])

  // Re-render Tabs when the left panel is resized.
  useEffect(() => {
    if (tabsWrapperRef.current) {
      const resizeObserver = new ResizeObserver(debounce(() => setTabsKey(previousKey => previousKey + 1), 300))

      resizeObserver.observe(tabsWrapperRef.current)

      return () => resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    setExpandAllCallback(() => () => setActiveKeys(generateActiveKeys))
  }, [generateActiveKeys, setActiveKeys, setExpandAllCallback])

  useEffect(() => {
    if (searchTerm && (!tab || tab === 'checklist')) {
      setActiveKeys(generateActiveKeys)
    }
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (tab) {
      case undefined:
      case 'checklist':
        setSearchPlaceholder('Search checklist items…')
        break
      case 'documentTypes':
        setSearchableData(null)
        break
      case 'roles':
        setSearchPlaceholder('Search roles…')
        break
      case 'users':
        setSearchPlaceholder('Search users…')
        break
      default:
        setSearchPlaceholder('Search…')
    }
  }, [setSearchableData, tab])

  return (
    <>
      <Box ref={tabsWrapperRef} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs key={tabsKey} scrollButtons value={tab || 'checklist'}>
          {(hasAdminAccess || hasChecklistTabAccess) && <Tab component={Link} label="Checklist" to="/config/checklist" value="checklist" />}

          {(hasAdminAccess || hasIntegrationsTabAccess) && <Tab component={Link} label="Integrations" to="/config/integrations" value="integrations" />}

          {(hasAdminAccess || hasAccessControlTabAccess) && <Tab component={Link} label="Roles" to="/config/roles" value="roles" />}

          {(hasAdminAccess || hasUsersTabAccess) && <Tab component={Link} label="Users" to="/config/users" value="users" />}
        </Tabs>
      </Box>

      {searchableData && (
        <>
          <SearchBar placeholder={searchPlaceholder} setFilter={setSearchTerm} value={searchTerm} />

          <Box display="flex" gap={0.5} justifyContent="flex-end" sx={{ pb: 2, px: 2 }}>
            <Button
              color="inherit"
              disabled={activeKeys.length === searchableData?.length}
              onClick={() => setActiveKeys(generateActiveKeys)}
              size="small"
              variant="contained"
            >
              Expand all
            </Button>

            <Button color="inherit" disabled={isEmpty(activeKeys)} onClick={() => setActiveKeys([])} size="small" variant="contained">
              Collapse all
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
