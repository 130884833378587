import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import React from 'react'
import css from './style.module.scss'
import logo from '../../assets/logo-full-dark.png'
import useCurrentUser from '../../hooks/useCurrentUser'

interface ErrorPageProps {
  message: string
}

function ErrorPage({ message }: ErrorPageProps) {
  const currentUser = useCurrentUser()
  const history = useHistory()

  return (
    <div className={css.container}>
      <div className={css.card}>
        <div className={css.logo}>
          <img alt="Klarity Logo" src={logo} />
        </div>

        <h2>{message}</h2>

        <Button onClick={() => history.push('/')}>{`Return to the ${currentUser ? 'Dashboard' : 'home page'}`}</Button>
      </div>
    </div>
  )
}

export default ErrorPage
