import { AiOutlineTable } from 'react-icons/ai'
import { FiCheckCircle, FiSlash, FiXOctagon } from 'react-icons/fi'
import { IconButton } from '@mui/material'
import { TableStatuses, useProcessTablesMutation } from '../../../app/restApi'
import { every, isEmpty } from 'lodash'
import { grey } from '@mui/material/colors'
import { useDocumentPageWrapperContext } from '../../DocumentPageWrapper'
import Loader from '../../Loader'
import React, { FC, useMemo, useState } from 'react'
import WithTooltip from '../../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'

// types

export type _TableCell = {
  bbox_xy: {
    page_height: number
    page_number: number
    page_width: number
    x1: number
    x2: number
    y1: number
    y2: number
  }
  column: number
  text: string
}

export type TablesQueryResult = {
  isError: boolean
  isTableStatusFetching: boolean
  isTablesDataFetching: boolean
  tableStatus?: TableStatuses
  tables: _TableCell[][][][]
}

export enum TablesQueryStatuses {
  ERROR,
  EXCEPTION_DOCUMENT_TOO_LARGE,
  LOADING_TABLES,
  LOADING_TABLE_STATUS,
  TABLES_LOADED,
  TABLES_LOADED_EMPTY,
  TABLES_NOT_PROCESSED,
  TABLES_PROCESSED
}

enum TablesQueryStatusLabels {
  ERROR = 'Error loading table data. Click to try again.',
  EXCEPTION_DOCUMENT_TOO_LARGE = 'Document too large to process tables. Please contact support.',
  LOADING_TABLES = 'Loading table data',
  LOADING_TABLE_STATUS = 'Checking status of table data',
  TABLES_LOADED = 'Table data loaded',
  TABLES_LOADED_EMPTY = 'No tables detected',
  TABLES_NOT_PROCESSED = 'Click to process table data',
  TABLES_PROCESSED = '' // No label required. Table data will begin loading automatically without user interaction.
}

type _TablesQueryButtonProps = {
  tablesQueryResult: TablesQueryResult
  totalPages: number
}

// constants

export const TABLES_QUERY_STATUS_LABEL = new Map([
  [TablesQueryStatuses.ERROR, TablesQueryStatusLabels.ERROR],
  [TablesQueryStatuses.EXCEPTION_DOCUMENT_TOO_LARGE, TablesQueryStatusLabels.EXCEPTION_DOCUMENT_TOO_LARGE],
  [TablesQueryStatuses.LOADING_TABLE_STATUS, TablesQueryStatusLabels.LOADING_TABLE_STATUS],
  [TablesQueryStatuses.LOADING_TABLES, TablesQueryStatusLabels.LOADING_TABLES],
  [TablesQueryStatuses.TABLES_LOADED, TablesQueryStatusLabels.TABLES_LOADED],
  [TablesQueryStatuses.TABLES_LOADED_EMPTY, TablesQueryStatusLabels.TABLES_LOADED_EMPTY],
  [TablesQueryStatuses.TABLES_NOT_PROCESSED, TablesQueryStatusLabels.TABLES_NOT_PROCESSED],
  [TablesQueryStatuses.TABLES_PROCESSED, TablesQueryStatusLabels.TABLES_PROCESSED]
])

// components

export const TablesQueryButton: FC<_TablesQueryButtonProps> = ({ tablesQueryResult, totalPages }) => {
  const { activeDocumentId } = useDocumentPageWrapperContext()
  const [processTables, { isLoading: isProcessTablesLoading }] = useProcessTablesMutation()

  const { isError, isTableStatusFetching, isTablesDataFetching, tableStatus, tables } = tablesQueryResult

  const [tablesQueryStatus, setTablesQueryStatus] = useState(TablesQueryStatuses.LOADING_TABLE_STATUS)

  const isButtonEnabled = tablesQueryStatus === TablesQueryStatuses.ERROR || tablesQueryStatus === TablesQueryStatuses.TABLES_NOT_PROCESSED

  useMemo(() => {
    if (totalPages > 60) {
      setTablesQueryStatus(TablesQueryStatuses.EXCEPTION_DOCUMENT_TOO_LARGE)
    } else if (isError && !isTablesDataFetching) {
      // It is possible to manually request table data even if `useGetTableStatusQuery` yields an error,
      // so this condition should be skipped if table data is loading or has been successfully fetched.
      setTablesQueryStatus(TablesQueryStatuses.ERROR)
    } else if (isTableStatusFetching) {
      setTablesQueryStatus(TablesQueryStatuses.LOADING_TABLE_STATUS)
    } else if (isProcessTablesLoading || isTablesDataFetching) {
      setTablesQueryStatus(TablesQueryStatuses.LOADING_TABLES)
    } else if (tableStatus === TableStatuses.TABLES_PROCESSED) {
      if (every(tables, table => isEmpty(table))) {
        setTablesQueryStatus(TablesQueryStatuses.TABLES_LOADED_EMPTY)
      } else {
        setTablesQueryStatus(TablesQueryStatuses.TABLES_LOADED)
      }
    } else if (tableStatus === TableStatuses.TABLES_NOT_PROCESSED) {
      setTablesQueryStatus(TablesQueryStatuses.TABLES_NOT_PROCESSED)
    }
  }, [isError, isProcessTablesLoading, isTableStatusFetching, isTablesDataFetching, tableStatus, tables, totalPages])

  return (
    <WithTooltip content={TABLES_QUERY_STATUS_LABEL.get(tablesQueryStatus)} style={{ marginRight: 16 }}>
      <IconButton
        aria-label={TABLES_QUERY_STATUS_LABEL.get(tablesQueryStatus)}
        className={css.toolbarButton}
        disabled={!isButtonEnabled}
        onClick={() => processTables({ documentId: activeDocumentId })}
        sx={{
          color: grey[400],
          m: 0,
          p: '11px',
          '&::after': {
            borderRight: `1px solid ${grey[400]}`,
            content: '""',
            height: 'calc(100% - 2px)',
            position: 'absolute',
            right: -8,
            top: 1,
            width: '1px'
          },
          '&:hover': { background: 'none', color: grey[800] }
        }}
      >
        <AiOutlineTable />

        {tablesQueryStatus !== TablesQueryStatuses.TABLES_NOT_PROCESSED && (
          <span
            className={clsx(
              css.statusIcon,
              (tablesQueryStatus === TablesQueryStatuses.ERROR || tablesQueryStatus === TablesQueryStatuses.EXCEPTION_DOCUMENT_TOO_LARGE) && css.error
            )}
          >
            {(tablesQueryStatus === TablesQueryStatuses.LOADING_TABLE_STATUS || tablesQueryStatus === TablesQueryStatuses.LOADING_TABLES) && (
              <Loader size="xs" />
            )}

            {tablesQueryStatus === TablesQueryStatuses.TABLES_LOADED && <FiCheckCircle />}

            {tablesQueryStatus === TablesQueryStatuses.TABLES_LOADED_EMPTY && <FiSlash />}

            {(tablesQueryStatus === TablesQueryStatuses.ERROR || tablesQueryStatus === TablesQueryStatuses.EXCEPTION_DOCUMENT_TOO_LARGE) && <FiXOctagon />}
          </span>
        )}
      </IconButton>
    </WithTooltip>
  )
}
