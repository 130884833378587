import { Box, Typography } from '@mui/material'
import { flattenObjectToPathMap } from '../../../../../../../utils/objectUtils'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useTranslation } from 'react-i18next'
import RadioButton from '../SharedInputs/RadioButton/RadioButton'
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

type _ExtractionPathSelectorProps = {
  externalMetadata: object
  handleSelection: (selectedOption?: _ExtractionPathOption) => void
  networkErrorMessage?: string
  suggestedExtractionPaths?: string[] | null
}

export type _ExtractionPathOption = { label: string; type: ExtractionPathSelectionTypes; value: string }

// enums

enum ExtractionPathSelectionTypes {
  AUTO_GENERATED = 'auto_generated',
  MANUALLY_SELECTED = 'manually_selected',
  MANUALLY_TYPED = 'manually_typed'
}

enum RadioButtonChoices {
  MANUAL = 'MANUAL',
  SUGGESTED = 'SUGGESTED'
}

enum RadioButtonLabels {
  MANUAL = 'Manually',
  SUGGESTED = 'Suggested by Klarity'
}

// components

export const ExtractionPathSelector: FC<_ExtractionPathSelectorProps> = ({
  externalMetadata,
  handleSelection,
  networkErrorMessage,
  suggestedExtractionPaths
}) => {
  const { selectedItem } = useCciMainContext()
  const { name: checklistFieldName } = selectedItem

  const flattenedExternalMetadata = useMemo(() => flattenObjectToPathMap(externalMetadata), [externalMetadata])

  const allExtractionPathOptions = useMemo(
    () => Object.keys(flattenedExternalMetadata).map(key => ({ label: key, type: ExtractionPathSelectionTypes.MANUALLY_SELECTED, value: String(key) })),
    [flattenedExternalMetadata]
  )

  const suggestedExtractionPathOptions = useMemo(
    () =>
      suggestedExtractionPaths
        ?.filter(path => Object.keys(flattenedExternalMetadata).includes(path))
        .map(path => ({ label: path, type: ExtractionPathSelectionTypes.AUTO_GENERATED, value: path })) || [],
    [flattenedExternalMetadata, suggestedExtractionPaths]
  )

  const [errorMessage, setErrorMessage] = useState('')
  const [radioButtonChoice, setRadioButtonChoice] = useState<RadioButtonChoices>(RadioButtonChoices.SUGGESTED)
  const [selectedOption, setSelectedOption] = useState<_ExtractionPathOption | undefined>(suggestedExtractionPathOptions[0])
  const { t } = useTranslation()

  const isSelectedOptionManuallyTyped = useMemo(
    () => selectedOption && !allExtractionPathOptions?.some(({ value }) => value === selectedOption.value),
    [allExtractionPathOptions, selectedOption]
  )

  useMemo(() => {
    setSelectedOption(radioButtonChoice === RadioButtonChoices.SUGGESTED ? suggestedExtractionPathOptions[0] : allExtractionPathOptions[0])
  }, [allExtractionPathOptions, radioButtonChoice, suggestedExtractionPathOptions])

  useEffect(() => {
    if (radioButtonChoice === RadioButtonChoices.SUGGESTED && networkErrorMessage) {
      setErrorMessage(
        `Klarity encountered an error while trying to suggest an extraction path: ${networkErrorMessage}\n\nPlease try again later. You may also manually select the extraction path or submit a different sample ${t(
          'deal'
        )} URL.`
      )
    } else if (radioButtonChoice === RadioButtonChoices.SUGGESTED && isEmpty(suggestedExtractionPathOptions)) {
      setErrorMessage(
        `Klarity could not find an extraction path for “${checklistFieldName}” in the sample ${t(
          'deal'
        )}’s metadata. Please rerun the auto suggestion to try again. You may also manually select the extraction path or submit a different sample ${t(
          'deal'
        )} URL.`
      )
    } else if (isSelectedOptionManuallyTyped) {
      setErrorMessage(
        `Warning: This extraction path is not recognized in the sample ${t(
          'deal'
        )}’s metadata. Ensure the path (which is case-sensitive) is correct before proceeding. Incorrect paths may lead to incorrect data extraction.`
      )
    } else {
      setErrorMessage('')
    }
  }, [checklistFieldName, isSelectedOptionManuallyTyped, networkErrorMessage, radioButtonChoice, suggestedExtractionPathOptions, t])

  useEffect(() => {
    handleSelection(selectedOption)
  }, [handleSelection, selectedOption])

  return (
    <>
      <Box className={css.radioRow} key={radioButtonChoice}>
        <Typography component="label" htmlFor="automation-tag-value-select-input" sx={{ flexShrink: 0, fontWeight: 600, mr: 2, width: 164 }} variant="body2">
          Select Extraction Path
        </Typography>

        <Box sx={{ width: '100%' }}>
          <RadioButton
            handleChange={(event: ChangeEvent<HTMLInputElement>) => setRadioButtonChoice(event.target.value as RadioButtonChoices)}
            id={RadioButtonChoices.SUGGESTED}
            isSelected={radioButtonChoice === RadioButtonChoices.SUGGESTED}
            label={RadioButtonLabels.SUGGESTED}
            name={RadioButtonChoices.SUGGESTED}
            style={{ fontSize: 14, marginRight: 16 }}
            value={RadioButtonChoices.SUGGESTED}
          />

          <RadioButton
            handleChange={(event: ChangeEvent<HTMLInputElement>) => setRadioButtonChoice(event.target.value as RadioButtonChoices)}
            id={RadioButtonChoices.MANUAL}
            isSelected={radioButtonChoice === RadioButtonChoices.MANUAL}
            label={RadioButtonLabels.MANUAL}
            name={RadioButtonChoices.MANUAL}
            style={{ fontSize: 14, marginRight: 16 }}
            value={RadioButtonChoices.MANUAL}
          />
        </Box>
      </Box>

      <Box sx={{ ml: 22.5 }}>
        {radioButtonChoice === RadioButtonChoices.SUGGESTED ? (
          <SelectInput
            id="suggested-extraction-path-input"
            isError={Boolean(errorMessage)}
            onChange={(option: _ExtractionPathOption) => setSelectedOption(option)}
            options={suggestedExtractionPathOptions}
            placeholder=""
            value={selectedOption}
          />
        ) : (
          <SelectInput
            creatable
            escapeClearsValue={Boolean(isSelectedOptionManuallyTyped)}
            formatCreateLabel={(value: string) => value}
            id="manual-extraction-path-input"
            isClearable
            isError={Boolean(errorMessage)}
            onChange={(option: _ExtractionPathOption) => setSelectedOption(option)}
            onCreateOption={(value: string) => setSelectedOption({ label: value, type: ExtractionPathSelectionTypes.MANUALLY_TYPED, value })}
            options={allExtractionPathOptions}
            placeholder=""
            styles={{ clearIndicator: isSelectedOptionManuallyTyped ? { visibility: 'visible' } : {} }}
            value={selectedOption}
          />
        )}

        {errorMessage && (
          <Typography color="error" sx={{ display: 'inline-block', lineHeight: 1.5, mt: 1, whiteSpace: 'pre-line' }} variant="caption">
            {errorMessage}
          </Typography>
        )}

        {selectedOption && !isSelectedOptionManuallyTyped && (
          <Typography sx={{ display: 'inline-block', lineHeight: 1.5, mt: 1 }} variant="caption">
            Sample value:&nbsp;&nbsp;
            <Typography sx={{ fontWeight: 600, wordBreak: 'break-word' }} variant="caption">
              {String(flattenedExternalMetadata[selectedOption.value])}
            </Typography>
          </Typography>
        )}
      </Box>
    </>
  )
}
