import { CciDocumentTypesDocument, useCci_EditDocumentTypesMutation } from '../../../../../graphql/codegen/hooks'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../CCI_Main'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'
import type { DocumentType } from '../../RightPanel/RightPanel_components/ChecklistTab/EditDocumentTypes'

export default function ReviewDocTypeEdits() {
  const { closeModal, modalContent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [changes, setChanges] = useState<Array<string> | undefined>(undefined)
  const documentTypeList: DocumentType[] = modalContent.documentTypeList
  const initialDocumentTypeList: DocumentType[] = modalContent.initialDocumentTypeList

  const [editDocumentTypes, { error: editDocTypesError, loading: editDocTypesLoading }] = useCci_EditDocumentTypesMutation({
    onCompleted: () => setModalSuccess('Document types successfully updated.'),
    refetchQueries: [{ query: CciDocumentTypesDocument }]
  })

  const generateChanges = () => {
    const changes: string[] = []

    if (initialDocumentTypeList.find(({ id }, index) => id !== documentTypeList[index].id)) {
      changes.push('document types have been reordered')
    }

    initialDocumentTypeList.forEach(({ id, name }) => {
      const documentType = documentTypeList.find(documentType => documentType.id === id && documentType.name !== name)

      if (documentType) {
        changes.push(`“${name}” has been renamed to “${documentType.name}”`)
      }
    })

    documentTypeList.forEach(({ id, name }: DocumentType) => {
      if (!id) {
        changes.push(`new document type: “${name}”`)
      }
    })

    return isEmpty(changes) ? undefined : changes
  }

  // eslint-disable-next-line
    useEffect(() => { setChanges(generateChanges()); }, [])

  useEffect(() => {
    if (editDocTypesLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating document types…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [editDocTypesLoading])

  useEffect(() => {
    if (editDocTypesError) {
      setMessageTitle('Error updating document types:')
      setModalError(editDocTypesError)
    }
    // eslint-disable-next-line
    }, [editDocTypesError])

  const handleSubmit = () => editDocumentTypes({ variables: { doc_types_id_name_list: JSON.stringify(documentTypeList) } })

  return (
    <>
      <div style={{ marginBottom: '32px' }}>
        <h4>Changes:</h4>

        <ul>
          {changes?.map((change, index: number) => (
            <li key={`${change}${index}`} style={{ listStyleType: 'disc', margin: '4px 0 0 16px' }}>
              {change}
            </li>
          ))}
        </ul>
      </div>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={!confirmed ? 'Type “confirm” to proceed.' : ''}>
          <Button disabled={!confirmed} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
