import { VscInbox } from 'react-icons/vsc'
import Button from '../Button'
import React, { Dispatch, SetStateAction } from 'react'
import css from './style.module.scss'

export default function EmptyState({ setClearAll, variant = 'table' }: { setClearAll?: Dispatch<SetStateAction<boolean>>; variant?: 'table' | 'inbox' }) {
  return (
    <div className={css.emptyState}>
      <div>
        <div>
          <VscInbox />
        </div>

        {variant === 'table' ? (
          <>
            <p>No results found for this query.</p>

            <Button onClick={() => setClearAll?.(true)}>Clear Search</Button>
          </>
        ) : (
          <p>All caught up 🎉</p>
        )}
      </div>
    </div>
  )
}
