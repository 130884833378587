import { TENANT_GOOGLE_CIR_PROD, TENANT_GOOGLE_CIR_TESTING } from '../utils/tenantUtils'
import useCurrentUser from './useCurrentUser'

// hooks

export const useIsSpecificGoogleTenantNonCalculationField = (externalSource: string) => {
  const currentUser = useCurrentUser()

  return Boolean(
    currentUser?.customers?.edges.some(edge => [TENANT_GOOGLE_CIR_PROD, TENANT_GOOGLE_CIR_TESTING].includes(edge?.node?.id!)) &&
      externalSource !== 'CALCULATION'
  )
}
