import { ActiveComponents, useCciMainContext } from '../../../../../CCI_Main'
import { Box, Tooltip, Typography } from '@mui/material'
import { CREATE_FIELD_REFETCH_QUERIES } from '../../../../Modal_components/ChecklistGptTab/CreateNewField'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../../../../../app'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import {
  useCreateFieldExtractionMethodInstanceMutation,
  useEditFieldExtractionMethodInstanceMutation,
  useExtractionMethodsQuery
} from '../../../../../../../graphql/codegen/hooks'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

export type _ExtractionMethodConfig = {
  description?: string
  extraction_method_id: string
  extraction_method_instance_id?: string
  extraction_method_name: string
  is_enabled?: boolean
}

export type _ExtractionMethodOption = { label: string; value: string | undefined }

// constants

const LABEL = 'AI Provider'

const PLACEHOLDER = 'Select an AI provider'

// hooks

export const useExtractionMethodConfiguration = () => {
  const { closeModal, expandAllCallback, selectedItem, setActiveComponent, setSelectedItem } = useCciMainContext()
  const { data: extractionMethodsData } = useExtractionMethodsQuery()
  const { setErrorMessage } = useAppContext()

  const [extractionMethodConfig, setExtractionMethodConfig] = useState<_ExtractionMethodConfig | null>(null)

  // Use a ref, not state, because this value must be up-to-date when the edit mutation is called, regardless of the current render cycle.
  const extractionMethodIdRef = useRef<string | undefined>(undefined)

  const extractionMethodList = useMemo(
    () => extractionMethodsData?.extraction_methods?.edges.map(item => ({ label: item?.node?.display_name || '', value: item?.node?.id || '' })) || [],
    [extractionMethodsData]
  )

  const [createFieldExtractionMethodInstanceMutation] = useCreateFieldExtractionMethodInstanceMutation({
    awaitRefetchQueries: true,
    onCompleted: data => {
      closeModal({ isForced: true })
      expandAllCallback?.()
      setActiveComponent(ActiveComponents.EDIT_FIELD)

      setSelectedItem({
        ...selectedItem,
        extraction_method_instances_config: [data.create_field_extraction_method_instance?.data_point_field?.extraction_method_instances_config || []],
        prompt_fragments: data.create_field_extraction_method_instance?.data_point_field?.prompt_fragments || []
      })

      extractionMethodIdRef.current = undefined

      toast.success('Field successfully created', { autoClose: 5000 })
    },
    onError: () => {
      closeModal({ isForced: true })

      setErrorMessage(`Error saving ${LABEL}`)
    },
    refetchQueries: CREATE_FIELD_REFETCH_QUERIES
  })

  const [editFieldExtractionMethodInstanceMutation] = useEditFieldExtractionMethodInstanceMutation({
    onCompleted: () => {
      closeModal({ isForced: true })

      extractionMethodIdRef.current = undefined

      toast.success(`${LABEL} saved`, { autoClose: 5000 })
    },
    onError: () => {
      closeModal({ isForced: true })

      setErrorMessage(`Failed to update ${LABEL}`)
    },
    update: (cache, { data }) => {
      const updatedExtractionMethodConfig = data?.edit_field_extraction_method_instance?.data_point_field?.extraction_method_instances_config?.[0]

      cache.modify({
        id: cache.identify({ __typename: 'DataPointField', id: selectedItem.id }),
        fields: { extraction_method_instances_config: () => [updatedExtractionMethodConfig] }
      })

      setSelectedItem({ ...selectedItem, extraction_method_instances_config: [updatedExtractionMethodConfig] })
    }
  })

  // `dataPointFieldId` is passed from <CreateNewField> via `createDataPointFieldMutation.onCompleted` (before the ID is assigned to `selectedItem`).
  const createExtractionMethodConfig = useCallback(
    (dataPointFieldId: string) => {
      createFieldExtractionMethodInstanceMutation({
        variables: {
          dataPointFieldId,
          extractionDescription: '',
          extractionMethodId: extractionMethodIdRef.current || '',
          isEnabled: true
        }
      })
    },
    [createFieldExtractionMethodInstanceMutation]
  )

  const editExtractionMethodConfig = useCallback(() => {
    editFieldExtractionMethodInstanceMutation({
      variables: {
        dataPointFieldId: selectedItem.id,
        extractionMethodId: extractionMethodIdRef.current,
        extractionMethodInstanceId: extractionMethodConfig!.extraction_method_instance_id,
        isEnabled: true
      }
    })
  }, [editFieldExtractionMethodInstanceMutation, extractionMethodConfig, selectedItem])

  const setExtractionMethodId = useCallback((value: string | undefined) => {
    extractionMethodIdRef.current = value
  }, [])

  useEffect(() => {
    if (selectedItem) {
      const extractionMethodConfig = JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}')

      setExtractionMethodConfig(extractionMethodConfig)
    } else {
      setExtractionMethodConfig(null)
    }
  }, [selectedItem])

  return {
    createExtractionMethodConfig,
    editExtractionMethodConfig,
    extractionMethodId: extractionMethodIdRef.current,
    extractionMethodList,
    setExtractionMethodId
  }
}

// components

export const ExtractionMethodInput: FC = () => {
  const { selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage } = useCciMainContext()
  const {
    actionTypeMap,
    createExtractionMethodConfig,
    editExtractionMethodConfig,
    extractionMethodId,
    extractionMethodList,
    fieldValues,
    isEditView,
    setExtractionMethodId
  } = useCciChecklistGptContext()
  const [selectedExtractionMethod, setSelectedExtractionMethod] = useState<_ExtractionMethodOption | null>(null)

  const actionType = useMemo(
    () => actionTypeMap[fieldValues?.action_type_id || selectedItem?.action_type?.id] || null,
    [actionTypeMap, fieldValues?.action_type_id, selectedItem]
  )

  const initialExtractionMethodConfig = useMemo(() => JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}'), [selectedItem])

  const optionList = useMemo(() => {
    if (!actionType) return extractionMethodList

    return extractionMethodList.filter(item => actionType.extraction_methods?.edges.some((edge: any) => edge.node.id === item.value))
  }, [actionType, extractionMethodList])

  const handleChange = ({ label, value }: _ExtractionMethodOption) => {
    setSelectedExtractionMethod({ label, value })
    setExtractionMethodId(value)

    if (isEditView && value && value !== initialExtractionMethodConfig.extraction_method_id) {
      setIsModalOpen(true)
      setModalLoading(true)
      setModalLoadingMessage('Saving…')

      if (isEmpty(initialExtractionMethodConfig)) {
        createExtractionMethodConfig(selectedItem.id)
      } else {
        editExtractionMethodConfig()
      }
    }
  }

  // effects

  useEffect(() => {
    // Initialize the selected extraction method.
    if (!selectedExtractionMethod) {
      if (!isEmpty(initialExtractionMethodConfig)) {
        setSelectedExtractionMethod({ label: initialExtractionMethodConfig.extraction_method_name, value: initialExtractionMethodConfig.extraction_method_id })
      } else if (actionType) {
        const defaultMethod = optionList.find(({ value }) => value === actionType.default_extraction_method?.id)

        setSelectedExtractionMethod(defaultMethod || null)
      }
    }
  }, [actionType, extractionMethodId, initialExtractionMethodConfig, optionList, selectedExtractionMethod, selectedItem])

  useEffect(() => {
    if (selectedExtractionMethod?.value) setExtractionMethodId(selectedExtractionMethod.value)
  }, [selectedExtractionMethod, setExtractionMethodId])

  // render

  if (isEmpty(optionList)) return null

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="extraction-method-type-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}

        {isEditView && (
          <Tooltip arrow placement="right" title="Changes are saved automatically after selecting an option.">
            <InfoOutlinedIcon sx={{ color: grey[500], fontSize: 16, ml: 0.5, mt: 0.25, position: 'absolute' }} />
          </Tooltip>
        )}
      </Typography>

      <SelectInput
        id="extraction-method-type-input"
        onChange={handleChange}
        options={optionList}
        placeholder={selectedExtractionMethod ? selectedExtractionMethod.label : PLACEHOLDER}
        value={selectedExtractionMethod}
      />
    </Box>
  )
}
