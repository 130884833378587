import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography } from '@mui/material'
import { Opening, useOpening } from '@hoologic/use-opening'
import { Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { grey } from '@mui/material/colors'
import React, { FC, ReactNode, SyntheticEvent } from 'react'
import copy from 'copy-to-clipboard'

// types

type _CopyableContentDialogProps = {
  expandedView?: ReactNode
  header: string
  opening: Opening
  text: string
}

// components

export const CopyableContentDialog: FC<_CopyableContentDialogProps> = ({ expandedView, header, opening, text }) => {
  const copiedOpening = useOpening()

  const handleCopy = (event: SyntheticEvent) => {
    event.stopPropagation()

    copy(text, { format: 'text/plain' })

    copiedOpening.open()
  }

  return (
    <Dialog PaperProps={{ sx: { minWidth: '60vh' } }} onClose={opening.close} open={opening.isOpen} sx={{ zIndex: Z_INDEX_OVERLAY }}>
      <DialogTitle>{header}</DialogTitle>

      <DialogContent>
        <Box sx={{ border: `1px solid ${grey[300]}`, borderRadius: 1, maxHeight: 240, overflow: 'auto', p: 1.5 }}>
          {expandedView || <Typography sx={{ fontSize: 14 }}>{text}</Typography>}
        </Box>
      </DialogContent>

      <DialogActions>
        {!expandedView && (
          <Button onClick={handleCopy} variant="outlined">
            Copy all
          </Button>
        )}

        <Button color="primary" onClick={opening.close} variant="contained">
          Done
        </Button>

        <Snackbar autoHideDuration={3000} onClose={copiedOpening.close} open={copiedOpening.isOpen}>
          <Alert severity="success">Copied.</Alert>
        </Snackbar>
      </DialogActions>
    </Dialog>
  )
}
