import { InlineQueryMenu, QueryNames, QuerySubNames } from '../../InlineQueryMenu'
import { useCounterPartiesByNameLazyQuery, useSetDealCounterpartyMutation, useSetDocumentCounterpartyMutation } from '../../../graphql/codegen/hooks'
import { useModalContext } from '../../../app'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../TypeToConfirm'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

export default function ChangeCounterparty() {
  const { closeModal, modalContent, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useModalContext()
  const [clearSelectedValue, setClearSelectedValue] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState('')
  const [existingCounterparty, setExistingCounterparty] = useState<{ id: string; name: string } | undefined>(undefined)
  const [queryVars, setQueryVars] = useState<any>(undefined)
  const [newCounterpartyName, setNewCounterpartyName] = useState('')
  const { t } = useTranslation()

  const [getCounterParties, { data: counterParties, loading: counterPartiesLoading }] = useCounterPartiesByNameLazyQuery({ fetchPolicy: 'no-cache' })

  const [updateDealCounterparty, { loading: updateDealCounterpartyLoading }] = useSetDealCounterpartyMutation({
    onError: error => {
      setModalError({ title: `Change ${t('Deal')} Counterparty Error:`, message: error?.message })
    },
    onCompleted: () => {
      setModalSuccess({ title: 'Success!', message: `The ${t('deal')}’s customer has been changed to: ${existingCounterparty?.name || newCounterpartyName}` })
    }
  })

  const [updateDocumentCounterparty, { loading: updateDocumentCounterpartyLoading }] = useSetDocumentCounterpartyMutation({
    onError: error => {
      setModalError({ title: 'Change Document Counterparty Error:', message: error?.message })
    },
    onCompleted: () => {
      setModalSuccess({ title: 'Success!', message: `The document’s customer has been changed to: ${existingCounterparty?.name || newCounterpartyName}` })
    }
  })

  const handleChange = (existingCounterparty?: { id: string; name: string }, newCounterpartyName?: string) => {
    if (existingCounterparty) {
      setExistingCounterparty({ ...existingCounterparty })
      setNewCounterpartyName('')
    } else if (newCounterpartyName) {
      setExistingCounterparty(undefined)
      setNewCounterpartyName(newCounterpartyName)
    } else {
      setExistingCounterparty(undefined)
      setNewCounterpartyName('')
    }
  }

  const handleSubmit = () => {
    if (modalContent?.itemType === 'Deal' && modalContent?.dealId && (existingCounterparty || newCounterpartyName)) {
      updateDealCounterparty({
        variables: { dealId: modalContent.dealId, counter_party_id: existingCounterparty?.id || undefined, name: newCounterpartyName || undefined }
      })
    } else if (modalContent?.itemType === 'Document' && modalContent?.documentId) {
      updateDocumentCounterparty({
        variables: { documentId: modalContent.documentId, counter_party_id: existingCounterparty?.id || undefined, name: newCounterpartyName || undefined }
      })
    } else {
      let errorMessage = ''
      if (!modalContent?.itemType) {
        errorMessage = 'Item Type is missing'
      } else if (modalContent?.itemType === 'Deal' && !modalContent?.dealId) {
        errorMessage = `${t('Deal')} ID is missing`
      } else if (modalContent?.itemType === 'Document' && !modalContent?.documentId) {
        errorMessage = 'Document ID is missing'
      }
      setModalError({ title: 'Change Counterparty Error:', message: errorMessage })
    }
  }

  useEffect(() => {
    setQueryVars({ required: {}, optional: { excludeCounterPartyIds: modalContent?.counterpartyId ? [modalContent.counterpartyId] : undefined } })
    getCounterParties({ variables: { excludeCounterPartyIds: modalContent?.counterpartyId ? [modalContent.counterpartyId] : undefined } })
  }, [getCounterParties, modalContent?.counterpartyId])

  useEffect(() => {
    if (updateDealCounterpartyLoading || updateDocumentCounterpartyLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating Customer…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
  }, [updateDealCounterpartyLoading, updateDocumentCounterpartyLoading])

  useEffect(() => {
    if (!modalContent?.itemType) {
      setIsDisabled(true)
      setDisabledMessage('Item type is missing')
    } else if (modalContent?.itemType === 'Deal' && !modalContent?.dealId) {
      setIsDisabled(true)
      setDisabledMessage(`${t('Deal')} ID is missing`)
    } else if (modalContent?.itemType === 'Document' && !modalContent?.documentId) {
      setIsDisabled(true)
      setDisabledMessage('Document ID is missing')
    } else if (existingCounterparty?.id && existingCounterparty.id === modalContent?.counterpartyId) {
      setIsDisabled(true)
      setDisabledMessage('Cannot select the current Customer')
    } else if (!existingCounterparty?.id && !newCounterpartyName) {
      setIsDisabled(true)
      setDisabledMessage('Select an existing Customer or create a new one')
    } else {
      setIsDisabled(false)
      setDisabledMessage('')
    }
    // eslint-disable-next-line
  }, [existingCounterparty?.id, newCounterpartyName])

  return (
    <>
      <div className={css.textRow}>
        <h4>{modalContent?.itemType}:</h4>

        <WithTooltip content={modalContent?.itemName || ''}>
          <p>{modalContent?.itemName}</p>
        </WithTooltip>
      </div>

      <div className={css.textRow}>
        <h4>Current Customer:</h4>

        <WithTooltip content={modalContent?.counterpartyName || 'None'}>
          <p>{modalContent?.counterpartyName || 'None'}</p>
        </WithTooltip>
      </div>

      <div className={css.textRow}>
        <h4>Select an existing Customer or create a new one:</h4>
      </div>

      <InlineQueryMenu
        clearSelectedValue={clearSelectedValue}
        handleChange={handleChange}
        isLoading={counterPartiesLoading}
        queryData={counterParties}
        queryFunction={getCounterParties}
        queryItemName="customers"
        queryName={QueryNames.COUNTERPARTIES_BY_NAME}
        queryPlaceholder="Select an existing Customer or create a new one"
        querySubName={QuerySubNames.CHANGE_COUNTERPARTY}
        queryVars={queryVars}
        setClearSelectedValue={setClearSelectedValue}
      />

      {((existingCounterparty && existingCounterparty?.id !== modalContent?.counterpartyId) || newCounterpartyName) && (
        <>
          <p style={{ marginTop: '16px', textAlign: 'center' }}>Are you sure you want to update this {modalContent?.itemType}’s customer?</p>

          <TypeToConfirm setConfirmed={setConfirmed} />
        </>
      )}

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content={isDisabled ? disabledMessage : confirmed ? '' : 'Type Confirm to proceed'}>
          <Button disabled={isDisabled ? true : !confirmed} onClick={handleSubmit}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}
