import { Box, Button, IconButton, InputAdornment, List, ListItem, TextField, Tooltip, Typography } from '@mui/material'
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, DropResult, Droppable } from 'react-beautiful-dnd'
import { FieldTypes } from '../CreateInputs/FieldTypeInput'
import { MatchFieldConfigEdge } from '../../../../../../../graphql/codegen/schemas'
import { isEmpty, size, sortBy } from 'lodash'
import { reorderList } from '../../../../../../../utils/cci'
import { useContextInit } from '../../../../../../../hooks/useContextInit'
import { useMatchingChildrenDataPointFieldsQuery } from '../../../../../../../graphql/codegen/hooks'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InfoIcon from '@mui/icons-material/Info'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'

// types

type _DataPointField = {
  documentType?: _SelectInput
  documentTypeList?: _SelectInput[]
  fieldType: FieldTypes
  label: string
  optionalRequired?: _SelectInput
  value: string
}

type _ExistingFieldProps = { dragHandleProps?: DraggableProvidedDragHandleProps; isFirst: boolean; matchField: _DataPointField }

type _MatchFieldsContext = {
  dataPointFieldList: _DataPointField[]
  fieldType?: FieldTypes
  isLoading: boolean
  isReadOnly: boolean
  isViewOnly: boolean
  matchFieldList: _DataPointField[]
  setMatchFieldList: Dispatch<SetStateAction<_DataPointField[]>>
}

type _MatchFieldsProps = { handleData?: (type: string, value: any) => void; isReadOnly?: boolean; isViewOnly?: boolean; list: MatchFieldConfigEdge[] }

enum OptionalRequired {
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

enum OptionalRequiredLabels {
  OPTIONAL = 'Optional',
  REQUIRED = 'Required'
}

export type _SelectInput = { label: string; value: string }

// context

const MatchFieldsContext = createContext<_MatchFieldsContext | null>(null)

// constants

const optionalRequiredList = [
  { label: OptionalRequiredLabels.OPTIONAL, value: OptionalRequired.OPTIONAL },
  { label: OptionalRequiredLabels.REQUIRED, value: OptionalRequired.REQUIRED }
]

const REFERENCE = 'Reference'

// functions

const getInputProps = (dragHandleProps: DraggableProvidedDragHandleProps | undefined, optionalReferenceRequiredLabel: string) => ({
  endAdornment: (
    <InputAdornment position="end" sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '100%' }}>
        <Typography color="text.disabled" fontSize={12}>
          {optionalReferenceRequiredLabel}
        </Typography>

        {dragHandleProps && (
          <Box {...dragHandleProps} component="span" sx={{ display: 'flex', alignItems: 'center' }} tabIndex={-1}>
            <DragHandleIcon />
          </Box>
        )}
      </Box>
    </InputAdornment>
  )
})

// hooks

const useMatchFieldsContext = () => useContextInit(MatchFieldsContext)

// components

const ExistingField: FC<_ExistingFieldProps> = ({ dragHandleProps, isFirst, matchField }) => {
  const { isReadOnly, isViewOnly } = useMatchFieldsContext()

  const optionalReferenceRequiredLabel = isFirst
    ? REFERENCE
    : matchField.optionalRequired?.value === OptionalRequired.OPTIONAL
    ? OptionalRequiredLabels.OPTIONAL
    : OptionalRequiredLabels.REQUIRED

  const value = `${matchField.label}${matchField.documentType ? ` — ${matchField.documentType.label}` : ''}`

  if (isReadOnly) {
    return <Typography fontSize={isViewOnly ? undefined : 14}>{`${value} — ${optionalReferenceRequiredLabel}`}</Typography>
  }

  return (
    <TextField
      InputProps={{
        ...getInputProps(dragHandleProps, optionalReferenceRequiredLabel),
        style: { background: 'white', fontSize: 14 }
      }}
      disabled
      fullWidth
      size="small"
      sx={{ fontSize: 14 }}
      value={value}
    />
  )
}

export const MatchFields: FC<_MatchFieldsProps> = ({ handleData = () => undefined, isReadOnly = false, isViewOnly = false, list = [] }) => {
  const { data, loading: isLoading } = useMatchingChildrenDataPointFieldsQuery()
  const [fieldType, setFieldType] = useState<FieldTypes>()

  const [matchFieldList, setMatchFieldList] = useState(() =>
    list.reduce<_DataPointField[]>(
      (previous, { node }) =>
        node?.data_point_field
          ? [
              ...previous,
              {
                documentType: node.document_type?.id && node.document_type.name ? { label: node.document_type.name, value: node.document_type.id } : undefined,
                fieldType: node.data_point_field.field_type as FieldTypes,
                label: node.data_point_field.name,
                optionalRequired: node.is_optional ? optionalRequiredList[0] : optionalRequiredList[1],
                value: node.data_point_field.id
              }
            ]
          : previous,
      []
    )
  )

  const dataPointFieldList = useMemo(
    () =>
      data?.matching_children_data_point_fields?.edges
        ? sortBy(
            data.matching_children_data_point_fields.edges.reduce<_DataPointField[]>(
              (previous, current) =>
                current?.node
                  ? [
                      ...previous,
                      {
                        ...(current.node.document_types &&
                          !isEmpty(current.node.document_types.edges) && {
                            documentTypeList: current.node.document_types.edges.reduce<_SelectInput[]>(
                              (previous, current) => (current?.node ? [...previous, { label: current.node.name, value: current.node.id }] : previous),
                              []
                            )
                          }),
                        fieldType: current.node.field_type as FieldTypes,
                        label: current.node.name,
                        value: current.node.id
                      }
                    ]
                  : previous,
              []
            ),
            'label'
          )
        : [],
    [data?.matching_children_data_point_fields]
  )

  const context = useMemo<_MatchFieldsContext>(
    () => ({ dataPointFieldList, fieldType, isLoading, isReadOnly, isViewOnly, matchFieldList, setFieldType, setMatchFieldList }),
    [dataPointFieldList, fieldType, isLoading, isReadOnly, isViewOnly, matchFieldList]
  )

  useMemo(() => {
    if (isEmpty(matchFieldList)) {
      setFieldType(undefined)
    } else if (size(matchFieldList) === 1) {
      setFieldType(dataPointFieldList.find(dataPointField => dataPointField.fieldType === matchFieldList[0].fieldType)?.fieldType)
    }
  }, [dataPointFieldList, matchFieldList])

  useEffect(
    () => {
      handleData(
        'match_children_fields',
        matchFieldList.map((matchField, index) => ({
          is_optional: matchField.optionalRequired?.value === OptionalRequired.OPTIONAL,
          is_truth_field: !index,
          data_point_field_id: matchField.value,
          ...(matchField.documentType && { document_type_id: matchField.documentType.value })
        }))
      )

      handleData('matchFieldType', matchFieldList[0]?.fieldType)

      handleData(
        'matchFieldLabelList',
        matchFieldList.map(matchField => {
          const field = dataPointFieldList.find(dataPointField => dataPointField.value === matchField.value)

          const documentType = field?.documentTypeList?.find(documentType => documentType.value === matchField.documentType?.value)

          return `${field?.label}${documentType ? ` — ${documentType.label}` : ''}`
        })
      )
    },
    [matchFieldList] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleDelete = (index: number) => setMatchFieldList(previous => previous.filter((_, localIndex) => localIndex !== index))

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return

    setMatchFieldList(previous => reorderList(previous, result.source.index, result.destination!.index))
  }

  return (
    <Box alignItems="baseline" display="flex">
      {!isViewOnly && (
        <Typography component="label" sx={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between', mr: 2, width: 164 }} variant="body2">
          <>Match Fields</>

          {!isReadOnly && (
            <Tooltip arrow placement="top-start" title="This match field is the matching reference.">
              <InfoIcon color="primary" sx={{ left: 8, position: 'relative' }} />
            </Tooltip>
          )}
        </Typography>
      )}

      <MatchFieldsContext.Provider value={context}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {({ droppableProps, innerRef, placeholder }) => (
              <Box flex={1}>
                <Box>
                  <List {...droppableProps} ref={innerRef} sx={{ py: 0 }}>
                    {matchFieldList.map((matchField, index) => (
                      <Draggable
                        draggableId={`${matchField.value}-${matchField.documentType?.value}`}
                        index={index}
                        isDragDisabled={isReadOnly || size(matchFieldList) < 2}
                        key={`${matchField.value}-${matchField.documentType?.value}`}
                      >
                        {({ dragHandleProps, draggableProps, innerRef }) => (
                          <ListItem
                            {...draggableProps}
                            ref={innerRef}
                            style={draggableProps.style}
                            sx={{ ...(isViewOnly ? { justifyContent: 'flex-end', pb: 0 } : { pb: 1 }), pt: 0, px: 0 }}
                          >
                            <ExistingField dragHandleProps={dragHandleProps} isFirst={!index} matchField={matchField} />

                            {isReadOnly ? null : (
                              <IconButton color="error" onClick={() => handleDelete(index)}>
                                <HighlightOffIcon fontSize="small" />
                              </IconButton>
                            )}
                          </ListItem>
                        )}
                      </Draggable>
                    ))}

                    {placeholder}
                  </List>

                  <NewField />
                </Box>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </MatchFieldsContext.Provider>
    </Box>
  )
}

const NewField: FC = () => {
  const { dataPointFieldList, fieldType, isLoading, isReadOnly, matchFieldList, setMatchFieldList } = useMatchFieldsContext()
  const [dataPointField, setDataPointField] = useState<_DataPointField | null>(null)

  const filteredDataPointFieldList = useMemo<_DataPointField[]>(
    () =>
      dataPointFieldList
        .filter(
          dataPointField =>
            !matchFieldList.some(
              matchField =>
                matchField.value === dataPointField.value &&
                (!matchField.documentType ||
                  size(matchFieldList.filter(matchFieldTwo => matchFieldTwo.value === dataPointField.value)) === size(matchField.documentTypeList))
            )
        )
        .filter(dataPointField => !fieldType || dataPointField.fieldType === fieldType),
    [dataPointFieldList, fieldType, matchFieldList]
  )

  const filteredDocumentTypeList = useMemo<_SelectInput[]>(
    () =>
      dataPointField?.documentTypeList
        ? sortBy(
            dataPointField.documentTypeList.filter(
              documentType =>
                !matchFieldList.some(matchField => matchField.value === dataPointField.value && matchField.documentType?.value === documentType.value)
            ),
            'label'
          )
        : [],
    [dataPointField?.documentTypeList, dataPointField?.value, matchFieldList]
  )

  const addMatchField = useCallback(() => {
    if (dataPointField) {
      setMatchFieldList(previous => [...previous, dataPointField])

      setDataPointField(null)
    }
  }, [dataPointField, setMatchFieldList])

  const dataPointFieldEnterCallback = useMemo(
    () => (!dataPointField?.documentTypeList || dataPointField?.documentType ? addMatchField : undefined),
    [addMatchField, dataPointField?.documentType, dataPointField?.documentTypeList]
  )

  const documentTypeEnterCallback = useMemo(() => (dataPointField?.documentType ? addMatchField : undefined), [addMatchField, dataPointField?.documentType])

  const handleDocumentTypeChange = useCallback(
    (documentType: _SelectInput | null) => {
      if (dataPointField) {
        setDataPointField({ ...dataPointField, documentType: documentType || undefined })
      }
    },
    [dataPointField]
  )

  const handleOptionalRequiredChange = useCallback(
    (optionalRequired: _SelectInput | null) => {
      if (dataPointField) {
        setDataPointField({ ...dataPointField, optionalRequired: optionalRequired || optionalRequiredList[1] })
      }
    },
    [dataPointField]
  )
  if (isReadOnly) return null

  return (
    <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
      <Box sx={{ flex: 1 }}>
        <SelectInput
          isClearable
          isDisabled={isLoading}
          onChange={setDataPointField}
          onEnter={dataPointFieldEnterCallback}
          options={filteredDataPointFieldList}
          placeholder={`Select a${isEmpty(matchFieldList) ? '' : 'nother'} match field`}
          value={dataPointField}
        />
      </Box>

      {Boolean(dataPointField?.documentTypeList) && (
        <Box sx={{ flex: 1 }}>
          <SelectInput
            isClearable
            isDisabled={isLoading}
            onChange={handleDocumentTypeChange}
            onEnter={documentTypeEnterCallback}
            options={filteredDocumentTypeList}
            placeholder="Select a document type"
            value={dataPointField?.documentType || null}
          />
        </Box>
      )}

      {!isEmpty(matchFieldList) && Boolean(dataPointField) && (
        <Box sx={{ width: 115 }}>
          <SelectInput
            isDisabled={isLoading}
            onChange={handleOptionalRequiredChange}
            options={optionalRequiredList}
            value={dataPointField?.optionalRequired || optionalRequiredList[1]}
          />
        </Box>
      )}

      <Button disabled={!dataPointField || (dataPointField.documentTypeList && !dataPointField.documentType)} onClick={addMatchField} variant="contained">
        Add
      </Button>
    </Box>
  )
}
