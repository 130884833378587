import { Box, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useErrorCheck } from '../../../../../hooks/useErrorCheck'
import React, { ChangeEvent, FC, useState } from 'react'

// types

type _MatchPromptProps = { handleData: (key: string, value: any) => void; isDisabled?: boolean; ruleConfig: string }

// constants

const ERROR_MATCH_PROMPT_REQUIRED = 'Match Prompt'

// components

export const MatchPrompt: FC<_MatchPromptProps> = ({ handleData, isDisabled = false, ruleConfig }) => {
  const [prompt, setPrompt] = useState(() => {
    let result = ''

    try {
      result = JSON.parse(ruleConfig).match_config.matching_instructions
    } catch (error) {
      console.error('JSON.parse() error:', error)
    }

    return result
  })

  useErrorCheck(isEmpty(prompt), [prompt], ERROR_MATCH_PROMPT_REQUIRED)

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    handleData('matching_prompt_description', event.target.value)

    setPrompt(event.target.value)
  }

  return (
    <Box sx={{ alignItems: 'baseline', display: 'flex' }}>
      <Typography component="label" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Match Prompt
      </Typography>

      <TextField
        disabled={isDisabled}
        inputProps={{ sx: { fontSize: 14, '&.Mui-disabled': { color: grey[800], WebkitTextFillColor: `${grey[800]} !important` } } }}
        multiline
        onChange={handleChange}
        sx={{ flex: 1, '& .Mui-disabled': { backgroundColor: grey[100] } }}
        value={prompt}
      />
    </Box>
  )
}
