import { DataPoint } from '../graphql/codegen/schemas'
import { isEmpty } from 'lodash'
import { useDataPointsForGroupQuery } from '../graphql/codegen/hooks'

// constants

const SUMMARY_GROUP_NAME = 'Summary'

// hooks

const useSummaryDataPoints = (resourceId: string) => {
  const { data: dataPointsForGroupData } = useDataPointsForGroupQuery({ variables: { group: SUMMARY_GROUP_NAME, resourceId } })

  if (!dataPointsForGroupData || isEmpty(dataPointsForGroupData.data_points_for_group)) return null

  return dataPointsForGroupData.data_points_for_group as DataPoint[]
}

export default useSummaryDataPoints
