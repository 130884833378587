import '@elastic/react-search-ui-views/lib/styles/styles.css'
import { ErrorBoundary, Facet, Paging, PagingInfo, Results, ResultsPerPage, SearchBox, SearchProvider, Sorting, WithSearch } from '@elastic/react-search-ui'
import { Layout } from '@elastic/react-search-ui-views'
import { SearchResult } from '@elastic/search-ui'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import Card from '../../../components/Card'
import React from 'react'

// constants

const CONNECTOR = new AppSearchAPIConnector({
  endpointBase: 'https://2027360632a546de9775a13ad4d5c3bb.ent-search.us-east-1.aws.cloud.es.io',
  engineName: 'revrec-demo',
  searchKey: 'search-vh9ypgvuinjcsbrpoybesukr'
})

const CONFIG = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: CONNECTOR,
  autocompleteQuery: { suggestions: { size: 10, types: { documents: { fields: ['content__raw', 'counter_party', 'document_name', 'document_type'] } } } },
  searchQuery: {
    facets: { document_type: { type: 'value' }, counter_party: { type: 'value' } },
    result_fields: {
      content__raw: { raw: { size: 300 }, snippet: { fallback: true, size: 300 } },
      counter_party: { raw: {}, snippet: { fallback: true } },
      document_name: { raw: {}, snippet: { fallback: true } },
      document_type: { raw: {}, snippet: { fallback: true } },
      url: { raw: {} }
    },
    search_fields: { content__raw: {}, counter_party: {}, document_name: {}, document_type: {} }
  }
}

// components

const PagingInfoView = ({ end, searchTerm, start, totalResults }: { end: number; searchTerm: string; start: number; totalResults: number }) => (
  <div className="sui-paging-info">
    Showing <strong>{`${start}–${end}`}</strong> out of <strong>{totalResults}</strong>
    {searchTerm ? (
      <>
        {' '}
        for: <em>{searchTerm}</em>
      </>
    ) : (
      ''
    )}
  </div>
)

const ResultView = ({ onClickLink, result }: { onClickLink: () => void; result: SearchResult }) => (
  <li className="sui-result" style={{ marginTop: 16, paddingBottom: 8 }}>
    <div className="sui-result__header">
      <a dangerouslySetInnerHTML={{ __html: result.document_name.snippet }} href={result.url.raw} onClick={onClickLink}></a>
    </div>

    <div className="sui-result__body">
      <ul className="sui-result__details">
        <li>
          <span dangerouslySetInnerHTML={{ __html: result.document_type.snippet }}></span>,{' '}
          <span dangerouslySetInnerHTML={{ __html: result.counter_party.snippet }}></span>
        </li>

        <li dangerouslySetInnerHTML={{ __html: `${result.content__raw.snippet}…` }} style={{ marginTop: 12 }}></li>
      </ul>
    </div>
  </li>
)

export const SearchTab = () => (
  <SearchProvider config={CONFIG}>
    <Card>
      <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => (
          <div className="App">
            <ErrorBoundary>
              <Layout
                bodyContent={<Results resultView={ResultView} shouldTrackClickThrough titleField="document_name" urlField="url" />}
                bodyFooter={
                  <div style={{ marginTop: 16 }}>
                    <Paging />
                  </div>
                }
                bodyHeader={
                  wasSearched && (
                    <>
                      <div style={{ marginBottom: -20 }}>
                        <PagingInfo view={PagingInfoView} />
                      </div>

                      <ResultsPerPage />
                    </>
                  )
                }
                header={<SearchBox autocompleteSuggestions />}
                sideContent={
                  wasSearched && (
                    <>
                      <Sorting
                        label="Sort By"
                        sortOptions={[
                          { direction: '', name: 'Relevance', value: '' },
                          { direction: 'asc', name: 'Created At', value: 'created_at' },
                          { direction: 'asc', name: 'Document Name', value: 'document_name' }
                        ]}
                      />

                      <Facet field="document_type" label="Document Type" />

                      <Facet field="counter_party" label="Counterparty" />
                    </>
                  )
                }
              />
            </ErrorBoundary>
          </div>
        )}
      </WithSearch>
    </Card>
  </SearchProvider>
)
