import '../../../../pdfjs-dist-local/web/pdf_viewer.css'
import { EventBus, PDFLinkService, PDFViewer } from '../../../../pdfjs-dist-local/web/pdf_viewer'
import { PDFDocumentProxy } from '../../../../pdfjs-dist-local/types/display/api'
import { debounce } from 'lodash'
import React, { PureComponent } from 'react'

type PdfViewerProps = {
  pdfDocument: PDFDocumentProxy
  pdfScaleValue: number | string
}

/**
 * A basic PDF viewer derived from the <PdfHighlighter> component in Klarity's `pdf-highlighter` package.
 */
export class PdfViewer extends PureComponent<PdfViewerProps> {
  containerNode: HTMLDivElement | null | undefined = null
  debouncedScaleValue: ReturnType<typeof debounce>
  eventBus = new EventBus()
  linkService = new PDFLinkService({ eventBus: this.eventBus, externalLinkTarget: 2 })
  resizeObserver: ResizeObserver
  unsubscribe = () => {}
  viewer: PDFViewer | null = null

  constructor(props: PdfViewerProps) {
    super(props)

    this.debouncedScaleValue = debounce(this.handleScaleValue, 500)
    this.resizeObserver = new ResizeObserver(this.debouncedScaleValue)
  }

  attachRef = (ref?: HTMLDivElement | null) => {
    const { eventBus, resizeObserver: observer } = this

    this.containerNode = ref
    this.unsubscribe()

    if (ref) {
      const { ownerDocument } = ref

      eventBus.on('pagesinit', this.onDocumentReady)
      ownerDocument.defaultView?.addEventListener('resize', this.debouncedScaleValue)

      if (observer) observer.observe(ref)

      this.unsubscribe = () => {
        eventBus.off('pagesinit', this.onDocumentReady)
        ownerDocument.defaultView?.removeEventListener('resize', this.debouncedScaleValue)

        if (observer) observer.disconnect()
      }
    }
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidUpdate(previous: PdfViewerProps) {
    if (this.viewer && previous.pdfScaleValue !== this.props.pdfScaleValue) {
      this.viewer.currentScaleValue = this.props.pdfScaleValue
    }

    if (previous.pdfDocument !== this.props.pdfDocument) {
      this.init()
    }
  }

  handleScaleValue = () => {
    if (this.viewer) {
      this.viewer.currentScaleValue = this.props.pdfScaleValue
    }
  }

  init() {
    const { pdfDocument } = this.props

    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNode,
        enhanceTextSelection: true,
        eventBus: this.eventBus,
        linkService: this.linkService,
        removePageBorders: true
      })

    this.linkService.setDocument(pdfDocument)
    this.linkService.setViewer(this.viewer)
    this.viewer?.setDocument(pdfDocument)

    window.PdfViewer = this
  }

  onDocumentReady = () => {
    this.handleScaleValue()
  }

  render() {
    return (
      <div onContextMenu={event => event.preventDefault()} ref={this.attachRef}>
        <div className="pdfViewer" />
      </div>
    )
  }
}
