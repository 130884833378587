import { FiXSquare } from 'react-icons/fi'
import Button from '../../../../../../components/Button'
import FreeText from '../../../../../../components/DatapointInput/FreeText'
import React, { KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

export default function TextInput({
  displayName,
  handleData,
  placeholder,
  value,
  variableName
}: {
  displayName: string
  handleData: any
  placeholder?: string
  value?: string
  variableName: string
}) {
  const [isFocused, setFocused] = useState(false)
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (isFocused && e.key === 'Enter') {
      setFocused(false)
    }
  }

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{displayName}</h4>
        <FreeText
          isFocused={isFocused}
          onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
            handleData(variableName, e.currentTarget.value)
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder || value || ''}
          setFocused={setFocused}
          value={value || ''}
        />
        {value && (
          <Button
            className={css.cciButton}
            icon={<FiXSquare />}
            onClick={() => {
              handleData(variableName, null)
            }}
          />
        )}
      </div>
    </>
  )
}
