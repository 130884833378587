import { Admin } from '../pages/Admin'
import { AdminOnlyRoute, AuthRoute, ProtectedRoute } from '../utils/authRouteUtils'
import { CciAccessControlPage } from '../new/pages/CciAccessControl'
import { Dashboard } from '../pages/Dashboard'
import { Features, Permissions, useUserAccess } from '../hooks/useUserAccess'
import { Logout } from '../pages/Logout'
import { OKTA_LOGIN_REDIRECT_PATH } from '../utils/apiUtils'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAppContext } from '.'
import Analytics from '../pages/Analytics'
import CCI_Main from '../pages/CCI'
import ContactUs from '../pages/ContactUs'
import DealPage from '../pages/DealPage'
import DocumentPage from '../pages/DocumentPage'
import FeedbackPage from '../pages/FeedbackPage'
import Layout from '../components/Layout'
import Login from '../pages/Login'
import NotFoundPage from '../pages/NotFoundPage'
import React from 'react'
import usePromptOnUnmount from '../hooks/usePromptOnUnmount'

// components

export function Router() {
  const hasAttachmentAccess = useUserAccess({ feature: Features.ATTACHMENTS_DASHBOARD, permission: Permissions.READ })
  const hasCCIAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.READ })

  const { pendingMutationCount } = useAppContext()
  const isMutationPending = pendingMutationCount > 0

  usePromptOnUnmount(
    isMutationPending,
    'Your changes are currently being saved. Do not leave this page until the changes have been saved.\n\nClick “Cancel” now to stay on this page.'
  )

  return (
    <Switch>
      <Route exact path="/logout">
        <Logout />
      </Route>

      {/* Redirect to '/' if user is logged in */}
      <AuthRoute exact path="/login">
        <Login />
      </AuthRoute>
      <AuthRoute exact path="/authorization-code/callback">
        <Login />
      </AuthRoute>
      <AuthRoute exact path={OKTA_LOGIN_REDIRECT_PATH}>
        <Login />
      </AuthRoute>

      {/* Redirect to '/login' without current user */}
      <ProtectedRoute exact path="/analytics">
        <Layout>
          <Analytics />
        </Layout>
      </ProtectedRoute>
      {/* The route '/' is used for deals for deal-level customers, documents for document-level customers.
        Dashboard/documents is only for deal customers that also have access to the documents list. */}
      <ProtectedRoute exact path={['/', '/dashboard/documents', '/dashboard/deals', hasAttachmentAccess ? '/dashboard/attachments' : '', `/dashboard/search`]}>
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/documents/:documentId">
        <Layout>
          <DocumentPage />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/deals/:dealId">
        <Layout>
          <DealPage />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/config/access_control">{hasCCIAccess && <CciAccessControlPage />}</ProtectedRoute>
      <ProtectedRoute path={['/config/:tab', '/config']}>
        {hasCCIAccess && (
          <Layout>
            <CCI_Main />
          </Layout>
        )}
      </ProtectedRoute>
      <ProtectedRoute path="/downloads/:download_artifact_uuid/:file_name">
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/preview/:previewId">
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/feedback/documents/:documentId">
        <Layout>
          <FeedbackPage />
        </Layout>
      </ProtectedRoute>

      {/* Only accessible if current user is a super admin */}
      <AdminOnlyRoute path="/tenants">
        <Admin />
      </AdminOnlyRoute>

      <Redirect from="/admin" to="/tenants" />

      {/* Accessible regardless of session */}
      <Route exact path="/contact-us">
        <Layout>
          <ContactUs />
        </Layout>
      </Route>
      <Route path="*">
        <Layout>
          <NotFoundPage />
        </Layout>
      </Route>
    </Switch>
  )
}
