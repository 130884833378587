import { Box, Button, IconButton, InputAdornment, List, ListItem, TextField, Typography } from '@mui/material'
import { CollisionTypes } from '../../ChecklistTab/SharedInputs/CollisionTypeInput'
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, DropResult, Droppable } from 'react-beautiful-dnd'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { Sources } from '../../ChecklistTab/CreateInputs/SourceInput'
import { _SelectInput as _DocumentType } from '../../ChecklistTab/SharedInputs/MatchFields'
import { isEmpty } from 'lodash'
import { reorderList } from '../../../../../../../utils/cci'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useContextInit } from '../../../../../../../hooks/useContextInit'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import React, { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'

// types

type _DraggableItemProps = { hasFullEditAccess: boolean; index: number; resolutionStrategy: string }
type _ExistingFieldProps = { dragHandleProps?: DraggableProvidedDragHandleProps; resolutionStrategy: string }
type _NewFieldProps = { documentTypeList: _DocumentType[] }
type _ResolutionStrategyContext = { handleData: (type: string, value: string[]) => void; resolutionStrategyList: string[] }

type _ResolutionStrategyInputProps = {
  collisionType: CollisionTypes
  handleData: (type: string, value: any) => void
  resolutionStrategyList: string[]
  source: Sources
}

// context

const ResolutionStrategyContext = createContext<_ResolutionStrategyContext | null>(null)

// functions

const getInputProps = (dragHandleProps: DraggableProvidedDragHandleProps) => ({
  endAdornment: (
    <InputAdornment {...dragHandleProps} position="end" tabIndex={-1}>
      <DragHandleIcon />
    </InputAdornment>
  )
})

// hooks

const useResolutionStrategyContext = () => useContextInit(ResolutionStrategyContext)

// components

const DraggableItem: FC<_DraggableItemProps> = ({ hasFullEditAccess, index, resolutionStrategy }) => {
  const { handleData, resolutionStrategyList } = useResolutionStrategyContext()

  const handleDelete = (index: number) =>
    handleData(
      'resolution_strategy',
      resolutionStrategyList.filter((_, localIndex) => localIndex !== index)
    )

  return (
    <Draggable draggableId={resolutionStrategy} index={index} isDragDisabled={!hasFullEditAccess || resolutionStrategyList.length < 2} key={resolutionStrategy}>
      {({ dragHandleProps, draggableProps, innerRef }) => (
        <ListItem {...draggableProps} ref={innerRef} style={draggableProps.style} sx={{ pb: 1, pt: 0, px: 0 }}>
          <ExistingField dragHandleProps={dragHandleProps} resolutionStrategy={resolutionStrategy} />

          {hasFullEditAccess && (
            <IconButton color="error" onClick={() => handleDelete(index)}>
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          )}
        </ListItem>
      )}
    </Draggable>
  )
}

const ExistingField: FC<_ExistingFieldProps> = ({ dragHandleProps, resolutionStrategy }) => {
  const { resolutionStrategyList } = useResolutionStrategyContext()

  return (
    <TextField
      InputProps={{
        ...(dragHandleProps && (resolutionStrategyList.length > 1 || undefined) && getInputProps(dragHandleProps)),
        style: { background: 'white', fontSize: 14 }
      }}
      disabled
      fullWidth
      size="small"
      sx={{ fontSize: 14 }}
      value={resolutionStrategy}
    />
  )
}

const NewField: FC<_NewFieldProps> = ({ documentTypeList }) => {
  const { handleData, resolutionStrategyList } = useResolutionStrategyContext()
  const [resolutionStrategy, setResolutionStrategy] = useState('')

  const documentType = useMemo<_DocumentType | null>(
    () => (resolutionStrategy ? { label: resolutionStrategy, value: resolutionStrategy } : null),
    [resolutionStrategy]
  )

  const filteredDocumentTypeList = useMemo<_DocumentType[]>(
    () => documentTypeList.filter(documentType => !resolutionStrategyList.some(resolutionStrategy => resolutionStrategy === documentType.value)),
    [documentTypeList, resolutionStrategyList]
  )

  const addResolutionStrategy = useCallback(() => {
    if (resolutionStrategy) {
      handleData('resolution_strategy', [...resolutionStrategyList, resolutionStrategy])

      setResolutionStrategy('')
    }
  }, [handleData, resolutionStrategy, resolutionStrategyList])

  const handleChange = useCallback((documentType: _DocumentType | null) => setResolutionStrategy(documentType?.value || ''), [])

  return (
    <Box display="flex" gap={1} mb={1}>
      <Box flex={1}>
        <SelectInput
          isClearable
          onChange={handleChange}
          onEnter={addResolutionStrategy}
          options={filteredDocumentTypeList}
          placeholder="Select a document type"
          value={documentType}
        />
      </Box>

      <Button disabled={!resolutionStrategy} onClick={addResolutionStrategy} variant="contained">
        Add
      </Button>
    </Box>
  )
}

export const ResolutionStrategyInput: FC<_ResolutionStrategyInputProps> = ({ collisionType, handleData, resolutionStrategyList, source }) => {
  const { docTypesLoading: isDocumentTypeListLoading, documentTypes: documentTypeList } = useCciChecklistGptContext()
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const context = useMemo<_ResolutionStrategyContext>(() => ({ handleData, resolutionStrategyList }), [resolutionStrategyList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (collisionType !== CollisionTypes.CASCADE) {
      handleData('resolution_strategy', null)
    }
  }, [collisionType]) // eslint-disable-line react-hooks/exhaustive-deps

  if (source !== Sources.INTERNAL || collisionType !== CollisionTypes.CASCADE || isDocumentTypeListLoading || isEmpty(documentTypeList)) return null

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return

    handleData('resolution_strategy', reorderList(resolutionStrategyList, result.source.index, result.destination.index))
  }

  return (
    <ResolutionStrategyContext.Provider value={context}>
      <Box sx={{ alignItems: 'baseline', display: 'flex', mb: -1 }}>
        <Typography sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
          Resolution Strategy
        </Typography>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {({ droppableProps, innerRef, placeholder }) => (
              <Box sx={{ flex: 1 }}>
                <List {...droppableProps} ref={innerRef} sx={{ pb: 0 }}>
                  {resolutionStrategyList.map((resolutionStrategy: string, index: number) => (
                    <DraggableItem hasFullEditAccess={hasFullEditAccess} index={index} key={resolutionStrategy} resolutionStrategy={resolutionStrategy} />
                  ))}

                  {placeholder}
                </List>

                {hasFullEditAccess && <NewField documentTypeList={documentTypeList} />}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </ResolutionStrategyContext.Provider>
  )
}
