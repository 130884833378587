import React, { Ref, forwardRef, useEffect, useRef } from 'react'
import useCombinedRefs from '../../hooks/useCombinedRefs'

interface CheckboxProps {
  indeterminate?: boolean
  loading: boolean
  name: string
}

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef(({ indeterminate, loading, ...rest }: CheckboxProps, ref: Ref<HTMLInputElement>) => {
  const defaultRef = useRef<HTMLElement>()
  const resolvedRef = useCombinedRefs(ref, defaultRef)

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  // return <input type="checkbox" ref={resolvedRef} {...rest} disabled={loading} />
  return (
    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <input ref={resolvedRef} type="checkbox" {...rest} disabled={loading} />
    </div>
  )
})

export default IndeterminateCheckbox
