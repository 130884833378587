import { Box } from '@mui/material'
import { DisplayYesOrNo } from '../../../Utils'
import { MatchFields } from './SharedInputs/MatchFields'
import { Sources } from './CreateInputs/SourceInput'
import { displayExtractionFieldType } from './SharedInputs/ExtractionFieldTypeInput'
import { getFieldTypeLabel, getMatchRuleLabel, getSourceLabel } from '../../../../../../utils/cci'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../../CCI_Main'
import { useTranslation } from 'react-i18next'
import React from 'react'
import css from './style.module.scss'

export default function ViewField() {
  const { selectedItem } = useCciMainContext()
  const { t } = useTranslation()

  return (
    <>
      {selectedItem ? (
        <div className={css.editField}>
          <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{selectedItem?.name || 'View Field'}</h3>
          {selectedItem?.description && (
            <div className={css.reviewDiv}>
              <h4 style={{ alignSelf: 'baseline' }}>{`Description:`}</h4>
              <p>{selectedItem?.description}</p>
            </div>
          )}
          {selectedItem?.group && (
            <div className={css.reviewDiv}>
              <h4>{`Group:`}</h4>
              <p>{selectedItem?.group}</p>
            </div>
          )}
          {selectedItem?.source && (
            <div className={css.reviewDiv}>
              <h4>{`Source:`}</h4>
              <p>{getSourceLabel(selectedItem?.source)}</p>
            </div>
          )}
          {/* {selectedItem?.neutral_tag && <div className={css.reviewDiv}><h4>{`Neutral Tag:`}</h4><p>{selectedItem?.neutral_tag}</p></div>} */}
          {selectedItem?.field_type && (
            <div className={css.reviewDiv}>
              <h4>{`Field Type:`}</h4>
              <p>{getFieldTypeLabel(selectedItem.field_type)}</p>
            </div>
          )}

          {!isEmpty(selectedItem?.extraction_field_type) && (
            <div className={css.reviewDiv}>
              <h4>Extraction Type:</h4>

              <p>{displayExtractionFieldType(selectedItem.extraction_field_type)}</p>
            </div>
          )}

          {selectedItem?.source === Sources.MATCHING && (
            <>
              <Box className={css.reviewDiv} sx={{ alignItems: 'baseline' }}>
                <h4>Match Fields:</h4>

                <MatchFields
                  isReadOnly
                  isViewOnly
                  key={JSON.stringify(selectedItem.match_config.match_fields.edges)}
                  list={selectedItem.match_config.match_fields.edges}
                />
              </Box>

              <Box className={css.reviewDiv}>
                <h4>Match Rule:</h4>

                <p>{getMatchRuleLabel(selectedItem.match_config.rule)}</p>
              </Box>
            </>
          )}

          {selectedItem?.options?.length > 0 && (
            <div className={css.reviewDiv} style={{ alignItems: 'baseline', display: 'flex' }}>
              <h4>Options:</h4>

              <div>
                {selectedItem?.options?.map((option: any, index: number) => (
                  <div className={css.reviewOptions} key={option + index}>
                    <p style={{ width: '100%' }}>{option}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedItem?.value_format && (
            <div className={css.reviewDiv}>
              <h4>{`Value Format:`}</h4>
              <p>{selectedItem?.value_format}</p>
            </div>
          )}
          {selectedItem?.default_value && (
            <div className={css.reviewDiv}>
              <h4>{`Default Value:`}</h4>
              <p>{selectedItem?.default_value}</p>
            </div>
          )}
          {selectedItem?.collision_type && (
            <div className={css.reviewDiv}>
              <h4>{`Collision Type:`}</h4>
              <p>{selectedItem?.collision_type}</p>
            </div>
          )}
          {selectedItem?.resolution_strategy && (
            <div className={css.reviewDiv}>
              <h4>{`Resolution Strategy:`}</h4>
              <p>{selectedItem?.resolution_strategy}</p>
            </div>
          )}

          <div className={css.reviewDiv}>
            <h4>Always hide this field:</h4>

            <DisplayYesOrNo value={!selectedItem?.display_on_checklist} />
          </div>

          <div className={css.reviewDiv}>
            <h4>Hide this field only when it has no value:</h4>

            <DisplayYesOrNo value={!selectedItem?.display_if_empty} />
          </div>

          {!selectedItem?.display_on_checklist && (
            <div className={css.reviewDiv}>
              <h4>Display annotation highlights:</h4>

              <DisplayYesOrNo value={selectedItem?.display_annotations} />
            </div>
          )}

          <div className={css.reviewDiv}>
            <h4>Hide accounting impact button:</h4>

            <DisplayYesOrNo value={!selectedItem?.display_accounting_impact} />
          </div>

          <div className={css.reviewDiv}>
            <h4>Display as default column in {t('Deals')} and Documents table views:</h4>

            <DisplayYesOrNo value={selectedItem?.default_field_on_dashboard} />
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>Select an item from the checklist to view</p>
      )}
    </>
  )
}
