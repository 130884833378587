import { Dialog, DialogContent, Typography } from '@mui/material'
import { Document } from '../../graphql/codegen/schemas'
import { DocumentPageWrapper } from '../../components/DocumentPageWrapper'
import { DocumentPendingProcessingFragment } from '../../graphql/codegen/operations'
import { Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { useDealsQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import { useTransitionDealStateFromAutocompleteToL1Review } from '../../hooks/useTransitionDealStateFromAutocompleteToL1Review'
import { useTranslation } from 'react-i18next'
import DataPointPanelContainer from '../../containers/DealDataPointPanelContainer'
import DealPageHeader from './DealPageHeader'
import ErrorPage from '../ErrorPage'
import Loader from '../../components/Loader'
import React, { FC, useMemo } from 'react'

// components

const DealPage: FC = () => {
  const { dealId } = useParams<{ dealId: string }>()
  const { data: dealData, error, loading: isDealLoading } = useDealsQuery({ variables: { id: dealId } })
  const { t } = useTranslation()

  const deal = useMemo(() => dealData?.deals?.edges?.[0]?.node, [dealData])
  const { isDealStateProcessing } = useTransitionDealStateFromAutocompleteToL1Review(dealId, deal)

  const { alias, assignee, counter_party, created_at, documents, name, processing_documents, state } = deal || {}
  const documentNodes = documents?.edges?.map(docEdge => docEdge?.node as Document)
  const documentsPendingProcessing = (processing_documents || []) as DocumentPendingProcessingFragment[]
  const withoutDocument = !isDealLoading && !documentNodes

  if (isDealLoading) return <Loader />

  if (error?.message) return <ErrorPage message={`${t('Deal')} not found.\n\n${error.message}`} />

  if (!deal) return <ErrorPage message={`You do not have access to this ${t('deal')}.`} />

  return (
    <>
      <DocumentPageWrapper
        counterparty={counter_party}
        dataPointPanel={<DataPointPanelContainer dealIsFinalized={state?.final || false} documents={documentNodes} />}
        dealIsFinalized={state?.final || false}
        documents={documentNodes || []}
        documentsPendingProcessing={documentsPendingProcessing}
        headerComponent={
          <DealPageHeader
            alias={alias || undefined}
            assigneeTo={assignee?.user_name}
            counter_party={counter_party}
            created_at={created_at}
            dealIsFinalized={state?.final || false}
            name={name}
            state={state}
          />
        }
        loading={isDealLoading}
        name={alias || name}
        withDocumentSelector={!withoutDocument}
      />

      <Dialog open={isDealStateProcessing} sx={{ zIndex: Z_INDEX_OVERLAY }}>
        <DialogContent>
          <Typography>Updating deal state…</Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DealPage
