import { Box, IconButton, Typography } from '@mui/material'
import { DELETE } from '../../../../../../../utils/cci'
import { DataPointFieldMentions } from '../../../../../../../components/DataPointFieldMentions'
import { FiXSquare } from 'react-icons/fi'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useConstRefValue } from '../../../../../../../hooks/useConstRefValue'
import React, { FC, useCallback } from 'react'
import RestoreIcon from '@mui/icons-material/SettingsBackupRestoreOutlined'

// types

type _FieldSemanticsInputProps = { handleData: (key: string, value: string | null) => void; value?: string }

// constants

const FIELD = 'field_semantics'

const LABEL = 'Description'

// components

export const FieldSemanticsInput: FC<_FieldSemanticsInputProps> = ({ handleData, value }) => {
  const { isCreateView, isEditView } = useCciChecklistGptContext()

  const initialValue = useConstRefValue(value || '')

  const handleChange = useCallback((event: { target: { value: string } }) => handleData(FIELD, event.target.value), [handleData])

  const handleDelete = useCallback(() => handleData(FIELD, initialValue ? DELETE : null), [handleData, initialValue])

  const handleRestore = useCallback(() => handleData(FIELD, null), [handleData])

  return (
    <Box sx={{ alignItems: 'baseline', display: 'flex', my: 1 }}>
      <Typography
        component="label"
        htmlFor="field-semantics-input"
        sx={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between', mr: 2, minWidth: 164 }}
        variant="body2"
      >
        {LABEL}
      </Typography>

      <Box sx={{ alignItems: 'center', display: 'flex', flex: 1 }}>
        <DataPointFieldMentions
          id="field-semantics-input"
          isDisabled={isEditView}
          label={LABEL}
          onChange={handleChange}
          placeholder={isEditView ? '' : undefined}
          value={value === DELETE ? '' : value || ''}
        />

        {isCreateView && Boolean(initialValue) && (
          <IconButton
            color="primary"
            disabled={!initialValue || value === initialValue}
            onClick={handleRestore}
            size="small"
            sx={{ ml: 1, height: 38, width: 38 }}
          >
            <RestoreIcon />
          </IconButton>
        )}

        {isCreateView && (
          <IconButton
            disabled={!value || value === DELETE}
            onClick={handleDelete}
            size="small"
            sx={{ borderRadius: 0.5, fontSize: 20, height: 28, ml: 1, padding: 0.5, width: 28 }}
          >
            <FiXSquare />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
