import {
  DealAttachmentsDocument,
  DocumentAttachmentsDocument,
  useDeleteDealAttachmentMutation,
  useDeleteDocumentAttachmentMutation
} from '../../graphql/codegen/hooks'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { PREVIEWABLE_FILE_EXTENSIONS } from '../../utils/fileUtils'
import { formatTimeAgo } from '../../utils/datetimeUtils'
import { useHistory, useParams } from 'react-router-dom'
import AttachmentToDocument from '../ModalOptions/AttachmentToDocument'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Modal from '../Modal'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

// types

type _AttachmentProps = { attachment: any; dealIsFinalized?: boolean }

// components

export const Attachment: FC<_AttachmentProps> = ({ attachment, dealIsFinalized }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const { dealId, documentId } = useParams<{ dealId?: string; documentId: string }>()
  const currentUser = useCurrentUser()
  const history = useHistory()
  const prefix = dealId ? `/deals/${dealId}` : `/documents/${documentId}`

  const canPreview = attachment.has_pdf_preview || attachment.name.match(PREVIEWABLE_FILE_EXTENSIONS)
  const hasDownloadAccess = useUserAccess({ feature: Features.ATTACHMENT, permission: Permissions.DOWNLOAD })
  const isCreatedByCurrentUser = currentUser?.id === attachment.created_by.id
  const shouldConvert = /(\.pdf|\.docx)/i.test(attachment?.name)

  const [deleteDealAttachmentMutation, { loading: isDeletingDealAttachment }] = useDeleteDealAttachmentMutation({
    refetchQueries: [{ query: DealAttachmentsDocument, variables: { dealId } }]
  })

  const [deleteDocumentAttachmentMutation, { loading: isDeletingDocumentAttachment }] = useDeleteDocumentAttachmentMutation({
    refetchQueries: [{ query: DocumentAttachmentsDocument, variables: { documentId } }]
  })

  const openModal = (menuOption: string) => {
    setIsOpen(true)
    setModalOption(menuOption)
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setIsDisabled(false)
      setModalOption('')
    }
  }

  const handleSubmit = () => {
    if (modalOption === 'Delete Attachment') {
      handleDelete()
    }
    closeModal()
  }

  const handleDownload = useCallback(
    async (attachment: any) => {
      const { data } = await axiosClient.get(`${prefix}/attachments/${attachment.id}`, { responseType: 'blob' })
      const href = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = attachment?.alias || attachment?.name
      document.body.appendChild(a)
      a.click()
    },
    [prefix]
  )

  const handleDelete = async () => {
    if (dealId) {
      deleteDealAttachmentMutation({ variables: { dealId, attachmentId: attachment.id } })
    } else if (documentId) {
      deleteDocumentAttachmentMutation({ variables: { documentId, attachmentId: attachment.id } })
    }
  }

  const menuItemList: _MenuItem[] = useMemo(
    () => [
      ...(canPreview ? [{ label: 'Preview', onClick: () => history.push(`${prefix}/attachments/${attachment.id}`) }] : []),
      ...(hasDownloadAccess ? [{ label: 'Download', onClick: () => handleDownload(attachment) }] : []),
      ...(dealIsFinalized ? [] : [{ label: 'Rename Attachment', onClick: () => openModal('Rename Attachment') }]),
      ...(!dealIsFinalized && attachment?.document_id && dealId && shouldConvert
        ? [{ label: 'Convert to Document', onClick: () => openModal('Convert Attachment') }]
        : []),
      ...(dealIsFinalized ? [] : [{ label: 'Delete', onClick: () => openModal('Delete Attachment') }])
    ],
    [attachment, canPreview, dealId, dealIsFinalized, handleDownload, hasDownloadAccess, history, prefix, shouldConvert]
  )

  useEffect(() => {
    if (isDeletingDocumentAttachment || isDeletingDocumentAttachment) {
      setLoading(true)
      setLoadingMessage('Deleting attachment…')
    } else {
      setLoading(false)
      setLoadingMessage('')
    }
  }, [isDeletingDealAttachment, isDeletingDocumentAttachment])

  return (
    <li className={css.attachment}>
      <div className={css.attachmentName}>
        <span>{attachment?.alias || attachment?.name}</span>

        {loading || <MoreMenu menuItemList={menuItemList} />}
      </div>

      <span>uploaded by </span>

      <span>{isCreatedByCurrentUser ? 'You' : attachment.created_by.first_name} </span>

      <span>{formatTimeAgo(attachment.created_at)} ago</span>

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalOption}>
        {loading || (modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />)}

        <div className={css.modal}>
          {modalOption === 'Convert Attachment' && (
            <AttachmentToDocument
              alias={attachment?.alias}
              attachment={attachment}
              closeModal={closeModal}
              dealId={dealId}
              name={attachment?.name}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}

          {modalOption === 'Rename Attachment' && (
            <RenameFile
              attachmentId={attachment?.id}
              closeModal={closeModal}
              dealId={dealId}
              documentId={documentId}
              fileAlias={attachment?.alias}
              fileName={attachment?.name}
              fileType="Attachment"
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}

          {modalOption === 'Delete Attachment' && (
            <>
              <p>Are you sure you want to delete attachment: {attachment?.alias || attachment.name}</p>

              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant="secondary">
                  Cancel
                </Button>

                <Button disabled={isDisabled} onClick={handleSubmit} title={isDisabled ? 'Select an option' : ''}>
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </li>
  )
}
