import { InlineQueryMenu, QueryNames } from '../../InlineQueryMenu'
import { useAddDocumentsToDealMutation, useCounterPartyDocumentsLazyQuery } from '../../../graphql/codegen/hooks'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import RadioButton from '../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import css from '../style.module.scss'

export default function AddExistingDocument({ counterpartyId, dealName, parentRef, setFormType, setLoading, setLoadingMessage }: any) {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { dealId } = useParams<{ dealId: string }>()
  const [selectedDocs, setSelectedDocs] = useState<string[]>([])
  const { t } = useTranslation()

  const [addDocumentsToDealMutation, { error, loading: addExistingLoading }] = useAddDocumentsToDealMutation({
    onCompleted: res => {
      const { edges } = res.edit_deal?.deal?.documents || {}
      const { id } = (edges && edges[edges.length - 1]?.node) || {}
      if (!id) return
      setSelectedDocs([])
      setLoading(false)
      setLoadingMessage(`Adding Document${selectedDocs?.length > 1 ? 's' : ''}…`)
      history.push(`${url}?documentTab=${id}`)
    }
  })

  const handleAdd = (selectedDocs: any[]) => {
    const documentIds = selectedDocs.map((doc: any) => doc.id)
    setLoading(true)
    setLoadingMessage('')
    addDocumentsToDealMutation({ variables: { dealId, documentIds } })
  }

  return (
    <>
      <h3 style={{ marginBottom: '8px' }}>Add documents to {dealName}</h3>

      <SelectExistingDocument
        counterpartyId={counterpartyId}
        dealId={dealId}
        parentRef={parentRef}
        selectedDocs={selectedDocs}
        setSelectedDocs={setSelectedDocs}
      />

      <div style={{ display: 'flex', paddingTop: '16px', borderTop: '1px solid lightgrey' }}>
        <Button onClick={() => setFormType('Initial')} variant="secondary">
          Cancel
        </Button>

        <Button disabled={Boolean(addExistingLoading || error || !selectedDocs.length)} onClick={() => handleAdd(selectedDocs)}>
          Add to {t('deal')}
        </Button>
      </div>
    </>
  )
}

function SelectExistingDocument({ counterpartyId, dealId, parentRef, selectedDocs, setSelectedDocs }: any) {
  const [isSearchingAllCustomers, setIsSearchingAllCustomers] = useState(false)
  const [queryVars, setQueryVars] = useState<any>('')
  const { t } = useTranslation()

  const [getCounterPartyDocuments, { data, loading }] = useCounterPartyDocumentsLazyQuery({
    fetchPolicy: 'no-cache' // Need to always get fresh search results from backend. Query is manually triggered by user, limited to 20 results max, and returns minor amount of data.
  })

  const getMaxHeight = () => parentRef?.current?.offsetHeight

  useEffect(() => {
    if (counterpartyId && dealId) {
      setQueryVars({
        required: { filters: JSON.stringify({ exclude_documents_by_deal_ids: [dealId] }) },
        optional: { counterpartyId: isSearchingAllCustomers ? undefined : counterpartyId }
      })
      getCounterPartyDocuments({
        variables: {
          counterpartyId: isSearchingAllCustomers ? undefined : counterpartyId,
          filters: JSON.stringify({ exclude_documents_by_deal_ids: [dealId] }),
          offset: 0,
          size: 20
        }
      })
    }
    // eslint-disable-next-line
  }, [isSearchingAllCustomers, counterpartyId, dealId])

  const handleChange = (action: string, docData: any) => {
    if (action === 'Add') {
      setSelectedDocs((prev: any) => {
        const newArray = [...prev, docData]
        // @ts-ignore
        const duplicatesRemoved = [...new Set(newArray)]
        return [...duplicatesRemoved]
      })
    } else if (action === 'Remove') {
      setSelectedDocs((prev: any) => {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i]?.id === docData?.id) {
            prev.splice(i, 1)
            break
          }
        }
        return [...prev]
      })
    }
  }

  return (
    <>
      <div className={css.radioRow}>
        <p>Include documents from other customers?</p>

        <div>
          <RadioButton
            handleChange={() => setIsSearchingAllCustomers(true)}
            id="isSearchingAllCustomers_true"
            isSelected={isSearchingAllCustomers}
            label="Yes"
            name="isSearchingAllCustomers"
            style={{ marginRight: '16px' }}
            value={true}
          />

          <RadioButton
            handleChange={() => setIsSearchingAllCustomers(false)}
            id="isSearchingAllCustomers_false"
            isSelected={!isSearchingAllCustomers}
            label="No"
            name="isSearchingAllCustomers"
            value={false}
          />
        </div>
      </div>

      <InlineQueryMenu
        dealCounterpartyId={counterpartyId}
        getMaxHeight={getMaxHeight}
        handleChange={handleChange}
        isAlwaysOpen
        isLoading={loading}
        isMulti
        key={isSearchingAllCustomers ? 'isSearchingAllCustomers' : counterpartyId} // Re-render when switching between all customers and a specific customer.
        menuItemClass={css.menuItemContainer}
        queryData={data}
        queryFunction={getCounterPartyDocuments}
        queryItemName="documents"
        queryName={QueryNames.DOCUMENTS_BY_COUNTERPARTY}
        queryNoResultsMessage={`No documents found. Documents already included in the ${t('deal')} are not shown in this list.`}
        queryPlaceholder="Select document(s) to add. Press Enter to search."
        queryVars={queryVars}
        selectedItems={selectedDocs}
      />
    </>
  )
}
