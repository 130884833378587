import { AiAnalyticsChatQueryError, AiAnalyticsChatQueryResponse, useSubmitAiAnalyticsChatQueryMutation } from '../../app/restApi'
import { Alert, Box, Button, FormControl, IconButton, Modal, Paper, Tooltip, Typography } from '@mui/material'
import { AnalyticsField } from '../../graphql/codegen/schemas'
import { CurrentRuntimeEnvironment } from '../../utils/envUtils'
import { Mention, MentionsInput } from 'react-mentions'
import { Opening } from '@hoologic/use-opening'
import { captureError } from '../../utils/sentry'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useAiAnalyticsFieldsQuery } from '../../graphql/codegen/hooks'
import { useContextInit } from '../../hooks/useContextInit'
import { useFormik } from 'formik'
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll'
import { useTranslation } from 'react-i18next'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import CloseIcon from '@mui/icons-material/Close'
import InsightsIcon from '@mui/icons-material/Insights'
import Loader from '../Loader'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import React, {
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import SendIcon from '@mui/icons-material/Send'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

// types

type _AiAnalyticsButtonProps = { opening: Opening }

type _AiAnalyticsDialogContext = {
  formik: any
  handleClose: () => void
  isFullscreen: boolean
  isQueryLoading: boolean
  messages: _Message[]
  opening: Opening
  setIsFullscreen: Dispatch<SetStateAction<boolean>>
}

type _AiAnalyticsDialogProps = { opening: Opening }

type _Message = {
  file_id?: string | null
  image?: string // Base64-encoded image string
  text?: string
  thread_id?: string | null
  timestamp?: number
  type: _MessageType
  warnings?: string[]
}

enum _MessageType {
  QUERY,
  RESPONSE
}

// constants

const AI_ANALYSIS_CHAT_WRAPUP_URL = `${CurrentRuntimeEnvironment.REACT_APP_API_ROOT}/ai_analysis_chat_wrapup`

const AI_ANALYSIS_MESSAGES_CACHE = 'ai-analysis-messages-cache'

const LOCAL_STORAGE_FILE_ID_KEY = 'ai_analysis_chat__file_id'

const LOCAL_STORAGE_THREAD_ID_KEY = 'ai_analysis_chat__thread_id'

const MODAL_Z_INDEX = 6001

// context

const AiAnalyticsDialogContext = createContext<_AiAnalyticsDialogContext | null>(null)

// hooks

const useAiAnalyticsDialogContext = () => useContextInit(AiAnalyticsDialogContext)

// components

export const AiAnalyticsButton: FC<_AiAnalyticsButtonProps> = ({ opening }) => (
  <Button
    onClick={opening.toggle}
    startIcon={<InsightsIcon />}
    sx={{
      backgroundColor: opening.isOpen ? grey[100] : 'transparent',
      border: '1px solid',
      borderColor: grey[400],
      color: grey[900],
      '&:hover': { backgroundColor: grey[100], borderColor: grey[400] }
    }}
    variant="outlined"
  >
    AI Analysis
  </Button>
)

export const AiAnalyticsDialog: FC<_AiAnalyticsDialogProps> = ({ opening }) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [messages, setMessages] = useState<_Message[]>([])
  const [submitAiAnalyticsChatQuery, { data: queryResponse, isLoading: isQueryLoading }] = useSubmitAiAnalyticsChatQueryMutation()

  const currentUser = useCurrentUser()
  const cacheKey = useMemo(() => `${currentUser?.id}/${currentUser?.customers?.edges?.[0]?.node?.id}`, [currentUser])

  const formik = useFormik({
    initialValues: { query: '' },
    onSubmit: ({ query }) => {
      const text = query.trim()

      if (!text) return

      formik.resetForm()

      updateMessages({ text, type: _MessageType.QUERY })

      submitAiAnalyticsChatQuery({
        file_id: localStorage.getItem(LOCAL_STORAGE_FILE_ID_KEY) || null,
        query: text,
        thread_id: localStorage.getItem(LOCAL_STORAGE_THREAD_ID_KEY) || null
      })
    }
  })

  // functions

  const handleClose = useCallback(() => {
    setIsFullscreen(false)

    opening.close()
  }, [opening])

  const updateMessages = async (message: _Message) => {
    const newMessage = { ...message, timestamp: Date.now() }

    // Save file_id and thread_id to localStorage for use in subsequent queries, if not already present.
    if (newMessage.file_id && !localStorage.getItem(LOCAL_STORAGE_FILE_ID_KEY)) localStorage.setItem(LOCAL_STORAGE_FILE_ID_KEY, newMessage.file_id)
    if (newMessage.thread_id && !localStorage.getItem(LOCAL_STORAGE_THREAD_ID_KEY)) localStorage.setItem(LOCAL_STORAGE_THREAD_ID_KEY, newMessage.thread_id)

    try {
      const cache = await caches.open(AI_ANALYSIS_MESSAGES_CACHE)
      const cachedMessages = await cache.match(cacheKey)
      const updatedMessages = cachedMessages ? [...(await cachedMessages.json()), newMessage] : [newMessage]

      await cache.put(cacheKey, new Response(JSON.stringify(updatedMessages)))
    } catch (error) {
      captureError(error, 'Error updating AI Analysis messages cache')
    } finally {
      setMessages(messages => [...messages, newMessage])
    }
  }

  // context

  const aiAnalyticsDialogContext = useMemo(
    () => ({ formik, handleClose, isFullscreen, isQueryLoading, messages, opening, setIsFullscreen }),
    [formik, handleClose, isFullscreen, isQueryLoading, messages, opening]
  )

  // effects

  useEffect(() => {
    // Terminate the chat session on the backend when the user closes the browser or navigates away from the site.
    const handleUnload = () => {
      try {
        const fileId = localStorage.getItem(LOCAL_STORAGE_FILE_ID_KEY) || null
        const threadId = localStorage.getItem(LOCAL_STORAGE_THREAD_ID_KEY) || null
        const url = fileId && threadId ? `${AI_ANALYSIS_CHAT_WRAPUP_URL}?file_id=${fileId}&thread_id=${threadId}` : null

        if (url) fetch(url, { keepalive: true }) // `await` is not necessary, as there is no need to process the response.
      } catch (error) {
        captureError(error, 'Error terminating AI Analysis chat')
      } finally {
        localStorage.removeItem(LOCAL_STORAGE_FILE_ID_KEY)
        localStorage.removeItem(LOCAL_STORAGE_THREAD_ID_KEY)
      }
    }

    window.addEventListener('beforeunload', handleUnload)
  }, [])

  useEffect(() => {
    // Load messages from cache on mount.
    ;(async () => {
      try {
        const cache = await caches.open(AI_ANALYSIS_MESSAGES_CACHE)
        const cachedMessages = await cache.match(cacheKey)

        if (!cachedMessages) return

        setMessages(await cachedMessages.json())
      } catch (error) {
        captureError(error, 'Error loading AI Analysis messages cache')
      }
    })()
  }, [cacheKey])

  useEffect(() => {
    if (!queryResponse) return

    const { message: errorMessage, response, ...rest } = queryResponse as AiAnalyticsChatQueryError & AiAnalyticsChatQueryResponse
    const { image, text } = response || {}

    updateMessages({ ...rest, image, text: text || errorMessage, type: _MessageType.RESPONSE })
  }, [queryResponse]) // eslint-disable-line react-hooks/exhaustive-deps

  // render

  return (
    <AiAnalyticsDialogContext.Provider value={aiAnalyticsDialogContext}>
      {isFullscreen ? (
        <Modal onClose={handleClose} open sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', zIndex: MODAL_Z_INDEX }}>
          <AiAnalyticsDialogContent />
        </Modal>
      ) : (
        <AiAnalyticsDialogContent />
      )}
    </AiAnalyticsDialogContext.Provider>
  )
}

// Wrap in forwardRef to avoid "Failed prop type: Invalid prop `children` supplied to `ForwardRef(Modal)`" error in StrictMode.
// See: https://mui.com/material-ui/guides/composition/#caveat-with-strictmode
const AiAnalyticsDialogContent = forwardRef<HTMLDivElement>((_, ref) => {
  const { data: fieldData, loading: isFieldDataLoading } = useAiAnalyticsFieldsQuery()
  const { formik, handleClose, isFullscreen, isQueryLoading, messages, opening, setIsFullscreen } = useAiAnalyticsDialogContext()
  const { getFieldProps, handleSubmit, values } = formik
  const [lockBodyScroll, unlockBodyScroll] = useLockBodyScroll()
  const inputRef = useRef<HTMLInputElement>(null)
  const messagesRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const regex = /@\[(.*?)\]\((.*?)\)/ // Matches `@[__display__](__id__)` with support for nested brackets in `__display__`.

  const isLoading = useMemo(() => isFieldDataLoading || isQueryLoading, [isFieldDataLoading, isQueryLoading])

  const mentionData = useMemo(
    () =>
      ((fieldData?.analytics_feature_fields as AnalyticsField[]) || [])
        .map(({ field_name, id }) => ({ display: field_name, id }))
        .sort((a, b) => a.display.localeCompare(b.display)),
    [fieldData]
  )

  // styles

  const dialogStyles = {
    background: 'white',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    bottom: 0,
    display: opening.isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    height: isFullscreen ? '90vh' : '70vh',
    maxWidth: isFullscreen ? 800 : 600,
    outline: 'none',
    position: isFullscreen ? 'initial' : 'fixed',
    right: isFullscreen ? 0 : 32,
    width: isFullscreen ? '80vw' : '40vw'
  }

  const dialogHeaderStyles = {
    alignItems: 'center',
    background: grey[100],
    borderBottom: '1px solid',
    borderBottomColor: grey[300],
    display: 'flex',
    justifyContent: 'space-between',
    pl: 2,
    pr: 0.5,
    py: 0.5
  }

  // functions

  const handleChange = useCallback((event: { target: { value: string } }) => formik.setFieldValue('query', event.target.value), [formik])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      // Insert newlines when pressing `Shift + Enter` – submit query when pressing `Enter` only.
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()

        if (!values.query.trim()) return

        inputRef.current?.blur()

        handleSubmit()
      }
    },
    [handleSubmit, inputRef, values]
  )

  const transformMentionMarkup = (string: string) => string.replace(RegExp(regex, 'g'), (_, label) => `"${label}"`)

  // effects

  useLayoutEffect(() => {
    // Scroll to the bottom of the chat panel and refocus on the input when adding a new message or toggling fullscreen.
    // Use `setTimeout()` to defer until after the modal has completed its transition (to ensure `scrollHeight` is correct).
    setTimeout(() => {
      if (messagesRef.current) messagesRef.current.scrollTop = messagesRef.current.scrollHeight

      inputRef.current?.focus()
    })
  }, [inputRef, isFullscreen, isLoading, messages, messagesRef, opening.isOpen])

  // render

  return (
    <Paper
      elevation={5}
      onMouseEnter={isFullscreen ? undefined : lockBodyScroll}
      onMouseLeave={isFullscreen ? undefined : unlockBodyScroll}
      ref={ref}
      sx={dialogStyles}
    >
      {/* Header */}
      <Box sx={dialogHeaderStyles}>
        <Typography sx={{ fontWeight: 600 }} variant="subtitle2">
          AI Analysis – Exclusive Alpha Version
        </Typography>

        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <IconButton aria-label="Open in full screen" onClick={() => setIsFullscreen(!isFullscreen)} size="small" sx={{ svg: { transform: 'scale(0.8)' } }}>
            {isFullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>

          <IconButton aria-label="Close window" onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Messages */}
      <Box ref={messagesRef} sx={{ flex: 1, overflow: 'auto', paddingY: 0 }}>
        {isEmpty(messages) ? (
          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', p: 2 }}>
            {isFieldDataLoading ? (
              <Loader size="s" />
            ) : (
              <Typography sx={{ color: grey[600], textAlign: 'center', width: 320 }} variant="body2">
                Enter messages below to receive answers and visual representations related to your data.
                <br />
                <br />
                Use &apos;@&apos; to reference {t('deal')} data point fields.
              </Typography>
            )}
          </Box>
        ) : (
          [...messages].map(({ image, text, timestamp, type, warnings }) => (
            <Box key={timestamp} sx={{ borderTop: `1px solid ${grey[100]}`, boxShadow: `0 2px 0 ${grey[100]}`, p: 2 }}>
              <Typography sx={{ fontWeight: 900, mb: 1 }} variant="subtitle2">
                {type === _MessageType.QUERY ? 'You' : 'Klarity'}
              </Typography>

              {image && (
                <img alt="AI Analysis" src={`data:image/png;base64,${image}`} style={{ border: `1px solid ${grey[100]}`, marginBottom: 8, width: '100%' }} />
              )}

              <Typography sx={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }} variant="body2">
                {transformMentionMarkup(text || '')}
              </Typography>

              {warnings?.map((warning, index) => (
                <Tooltip PopperProps={{ sx: { zIndex: MODAL_Z_INDEX } }} arrow key={index} title={warning}>
                  <Alert
                    severity="warning"
                    sx={{ cursor: 'default', marginTop: 1.5, '.MuiAlert-message': { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }}
                  >
                    {warning}
                  </Alert>
                </Tooltip>
              ))}
            </Box>
          ))
        )}

        {isQueryLoading && (
          <Box sx={{ p: 2, width: 'fit-content' }}>
            <Loader size="xs" />
          </Box>
        )}
      </Box>

      {/* Input */}
      <Box sx={{ borderTop: `2px solid ${grey[100]}`, p: 2, position: 'sticky' }}>
        <FormControl autoComplete="off" component="form" fullWidth onSubmit={handleSubmit}>
          <MentionsInput
            {...getFieldProps('query')}
            aria-label="Enter a message"
            classNames={css}
            disabled={isLoading}
            forceSuggestionsAboveCursor
            inputRef={inputRef}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          >
            <Mention className={css.mentions__mention} data={mentionData} regex={regex} trigger="@" />
          </MentionsInput>

          <IconButton
            aria-label="Submit"
            disabled={isLoading || !values.query}
            onClick={handleSubmit}
            size="small"
            sx={{ bottom: 5, color: isLoading ? grey[400] : grey[600], p: 0.25, position: 'absolute', right: 8 }}
          >
            <SendIcon sx={{ transform: 'scale(0.75) translateX(2px)' }} />
          </IconButton>
        </FormControl>
      </Box>
    </Paper>
  )
})
