import { DataPointFieldMentions } from '../../../../../../../components/DataPointFieldMentions'
import { FiXSquare } from 'react-icons/fi'
import { Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../../../../../app'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useEditFieldExtractionMethodInstanceMutation } from '../../../../../../../graphql/codegen/hooks'
import Button from '../../../../../../../components/Button'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, useCallback, useState } from 'react'
import css from './style.module.scss'

// constants

const LABEL = 'AI Prompt'

// components

export const ExtractionDescriptionInput: FC = () => {
  const { closeModal, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage, setSelectedItem } = useCciMainContext()
  const { setErrorMessage } = useAppContext()

  const selectedExtractionMethod = JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}')
  const { description, extraction_method_instance_id: extractionMethodInstanceId } = selectedExtractionMethod

  const [value, setValue] = useState(description || '')

  const [editFieldExtractionMethodInstance] = useEditFieldExtractionMethodInstanceMutation({
    onCompleted: () => {
      closeModal({ isForced: true })

      toast.success(`${LABEL} successfully updated`, { autoClose: 5000 })
    },
    onError: () => {
      closeModal({ isForced: true })

      setErrorMessage(`Failed to update ${LABEL}`)
    },
    update: (cache, { data }) => {
      const updatedExtractionMethodConfig = data?.edit_field_extraction_method_instance?.data_point_field?.extraction_method_instances_config?.[0]

      cache.modify({
        id: cache.identify({ __typename: 'DataPointField', id: selectedItem?.id }),
        fields: { extraction_method_instances_config: () => [updatedExtractionMethodConfig] }
      })

      setSelectedItem({ ...selectedItem, extraction_method_instances_config: [updatedExtractionMethodConfig] })
    }
  })

  const handleMutation = useCallback(
    (extractionDescription: string) => {
      if (!extractionMethodInstanceId || !selectedItem) return

      setIsModalOpen(true)
      setModalLoading(true)
      setModalLoadingMessage('Saving…')

      editFieldExtractionMethodInstance({ variables: { dataPointFieldId: selectedItem.id, extractionDescription, extractionMethodInstanceId } })
    },
    [extractionMethodInstanceId, editFieldExtractionMethodInstance, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage]
  )

  const handleBlur = useCallback(() => {
    const newValue = value?.trim()

    if (newValue === description) return

    handleMutation(newValue)
  }, [value, description, handleMutation])

  const handleClear = useCallback(() => {
    handleMutation('')

    setValue('')
  }, [handleMutation])

  if (!extractionMethodInstanceId) return null

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="extraction-description-input" sx={{ fontWeight: 600, minWidth: 164, mr: 2 }} variant="body2">
        {LABEL}

        <Tooltip arrow placement="right" title="Changes are saved automatically after clicking outside of the field.">
          <InfoOutlinedIcon sx={{ color: grey[500], fontSize: 16, ml: 0.5, mt: 0.25, position: 'absolute' }} />
        </Tooltip>
      </Typography>

      <DataPointFieldMentions
        id="extraction-description-input"
        label={LABEL}
        onBlur={handleBlur}
        onChange={(event: { target: { value: string } }) => setValue(event.target.value)}
        value={value}
      />

      {value && <Button className={css.cciButton} icon={<FiXSquare />} onClick={handleClear} />}
    </div>
  )
}
