import { useIsKlarityEmployee } from './useIsKlarityEmployee'
import useCurrentUser from './useCurrentUser'

// types

type _IsGoogleTenantOptions = { isNonKlarityUser?: true }

// hooks

export const useIsGoogleTenant = ({ isNonKlarityUser }: _IsGoogleTenantOptions = {}) => {
  const currentUser = useCurrentUser()
  const isKlarityEmployee = useIsKlarityEmployee()

  const isGoogleTenant = Boolean(currentUser?.customers?.edges[0]?.node?.name.match(/^Google /))

  if (isNonKlarityUser) return isGoogleTenant && !isKlarityEmployee

  return isGoogleTenant
}
