import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import React from 'react'
import css from './style.module.scss'
import logo from '../../assets/logo-full-dark.png'
import useCurrentUser from '../../hooks/useCurrentUser'

function NotFoundPage() {
  const currentUser = useCurrentUser()
  const history = useHistory()
  return (
    <div className={css.notFoundParent}>
      <div className={css.notFoundCard}>
        <div className={css.logoContainer}>
          <img alt="Klarity Logo" src={logo} />
        </div>
        <h2>{`Page not found`}</h2>
        <Button
          onClick={() => {
            history.push('/')
          }}
        >{`Return to the ${currentUser ? 'Dashboard' : 'home page'}`}</Button>
      </div>
    </div>
  )
}

export default NotFoundPage
