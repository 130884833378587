import { Box, Typography } from '@mui/material'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../../CCI_Main'
import React, { FC, useCallback } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import Skeleton from 'react-loading-skeleton'
import css from './style.module.scss'

// types

type _GroupInputProps = { [key: string]: any }

// constants

const FIELD = 'group'

const LABEL = 'Section'

const PLACEHOLDER = 'Select a section or type to create a new section'

// components

export const GroupInput: FC<_GroupInputProps> = () => {
  const { selectedItem } = useCciMainContext()
  const { fieldValues, groupOptions, groupsLoading, isCreateView, updateFieldValue } = useCciChecklistGptContext()

  const value = fieldValues?.group || selectedItem?.group

  const handleChange = useCallback((item: any) => updateFieldValue(FIELD, item?.value), [updateFieldValue])

  return isCreateView ? (
    <Box className={css.inputWrapper}>
      <Typography component="label" htmlFor="group-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      {groupsLoading ? (
        <Skeleton count={1} height={37} />
      ) : (
        <SelectInput
          creatable
          id="group-select-input"
          isClearable
          onChange={handleChange}
          options={groupOptions}
          placeholder={value || PLACEHOLDER}
          value={value}
        />
      )}
    </Box>
  ) : (
    <Box sx={{ alignItems: 'center', display: 'flex', height: 38, mb: 1 }}>
      <Typography sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <Typography sx={{ borderRadius: 4, flex: 1, overflow: 'hidden', py: 1, textOverflow: 'ellipsis', textWrap: 'nowrap' }} variant="body2">
        {value}
      </Typography>
    </Box>
  )
}
