import { Attachment } from '../Attachment'
import { captureError } from '../../utils/sentry'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { uploadAttachment } from '../../utils/uploadApiUtils'
import { useDealAttachmentsQuery, useDocumentAttachmentsQuery } from '../../graphql/codegen/hooks' // stealing this from comments for now
import { useIsGoogleTenant } from '../../hooks/useIsGoogleTenant'
import { useParams } from 'react-router-dom'
import CommentSkeleton from '../CommentSection/Skeleton'
import Dropzone from '../Dropzone'
import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _AttachmentsTabProps = { attachments: any[]; isDealFinalized?: boolean; isLoading: boolean; isProcessing: boolean; onDrop: (x: any) => void }

// components

const AttachmentsTab = ({ attachments, isDealFinalized = false, isLoading, isProcessing, onDrop }: _AttachmentsTabProps) => {
  const isGoogleTenantNonKlarityUser = useIsGoogleTenant({ isNonKlarityUser: true })

  return (
    <div className={clsx(css.inner, css.attachmentsTab)}>
      {!isLoading && !isDealFinalized && !isGoogleTenantNonKlarityUser && (
        <div className={css.dropzoneWrapper}>
          <Dropzone isLoading={isProcessing} onDrop={onDrop} />
        </div>
      )}

      {isLoading && <CommentSkeleton />}

      {!isLoading && !isEmpty(attachments) && (
        <ul className={css.attachments}>
          {attachments.map(attachment => {
            return <Attachment attachment={attachment} dealIsFinalized={isDealFinalized} key={attachment?.id} />
          })}
        </ul>
      )}

      {!isLoading && !attachments?.length && <div className={css.emptyState}>{`This document doesn't have any attachments yet.`}</div>}
    </div>
  )
}

/* Container for Deal Attachments Tab */
export function DealAttachmentsTabContainer({ dealIsFinalized }: { dealIsFinalized?: boolean }) {
  const { dealId } = useParams<{ dealId: string }>()
  const { data, loading: isLoading, refetch } = useDealAttachmentsQuery({ variables: { dealId } })
  const [isProcessing, setIsProcessing] = useState(false)

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsProcessing(true)
      try {
        await uploadAttachment(`deals/${dealId}/attachments`, acceptedFiles)
        await refetch()
      } catch (error) {
        captureError(error)
        toast.error('Something went wrong', { autoClose: 5000 })
      } finally {
        setIsProcessing(false)
      }
    },
    [dealId, refetch]
  )

  return (
    <AttachmentsTab
      attachments={data?.deal_attachments?.filter((a: any) => a.is_deleted === false) || []}
      isDealFinalized={dealIsFinalized}
      isLoading={isLoading}
      isProcessing={isProcessing}
      onDrop={onDrop}
    />
  )
}

/* Container for Document Attachments Tab */
export function DocumentAttachmentsTabContainer() {
  const { documentId } = useParams<{ documentId: string }>()
  const { data, loading: isLoading, refetch } = useDocumentAttachmentsQuery({ variables: { documentId } })
  const [isProcessing, setIsProcessing] = useState(false)

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsProcessing(true)
      try {
        await uploadAttachment(`documents/${documentId}/attachments`, acceptedFiles)
        await refetch()
      } catch (error) {
        captureError(error)
        toast.error('Something went wrong', { autoClose: 5000 })
      } finally {
        setIsProcessing(false)
      }
    },
    [documentId, refetch]
  )

  return (
    <AttachmentsTab
      attachments={data?.document_attachments?.filter((a: any) => a.is_deleted === false) || []}
      isLoading={isLoading}
      isProcessing={isProcessing}
      onDrop={onDrop}
    />
  )
}
