import React from 'react'
import css from './style.module.scss'
import logo from '../../assets/logo.png'

export default function ContactUs() {
  return (
    <div className={css.root}>
      <div className={css.content}>
        <img alt="Klarity Logo" src={logo} />
        <h1>Contact us</h1>
        <div>
          <a href="mailto:hello@klaritylaw.com">hello@klaritylaw.com</a>
        </div>
      </div>
    </div>
  )
}
