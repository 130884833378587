// constants

export const CSV_MIME_TYPE = 'text/csv'

export const PREVIEWABLE_FILE_EXTENSIONS = /^.*\.(csv|gif|jpg|jpeg|pdf|png|txt|xlsm|xlsx)$/

export const SPREADSHEET_FILE_EXTENSIONS = /^.*\.(csv|xlsm|xlsx)$/

export const SPREADSHEET_FILE_MIME_TYPES: Record<string, string> = {
  csv: 'text/csv',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const TEXT_FILE_EXTENSIONS = /^.*\.txt$/

// functions

export const convertBase64ToBlob = (base64Data: string, mimeType: string): Blob => {
  const base64Content = base64Data.includes('base64,') ? base64Data.split('base64,')[1] : base64Data
  const byteCharacters = window.atob(base64Content)
  const byteArray = new Uint8Array(Array.from(byteCharacters, character => character.charCodeAt(0)))

  return new Blob([byteArray], { type: mimeType })
}

export const createFileFromBlob = (blob: Blob, fileName: string): File =>
  // Special case for CSV files is necessary because the backend response wrongly declares their `Content-Type` as `text/plain` instead of `text/csv`.
  new File([blob], fileName, { type: fileName.toLowerCase().endsWith('.csv') ? CSV_MIME_TYPE : blob.type })

export const getSpreadsheetFileExtension = (fileName: string): string | undefined => {
  const match = fileName.match(SPREADSHEET_FILE_EXTENSIONS)

  return match?.[0].split('.').pop()?.toLowerCase() // Extract extension without the dot.
}
