import { formatKey } from '../../../../../../utils/stringUtils'
import React from 'react'
import css from '../../style.module.scss'

export default function CCI_LeftPanel_RoleCard({ roleId, users }: any) {
  // pass in mutation functions
  // add controls for desired actions
  // add 'selected item' to CCI main, pass down to this and to the right panel with context, so that clicking on the item in the left panel loads it into the right panel

  return (
    <>
      {users?.map((user: any) => {
        return (
          <div className={css.field} key={formatKey(`${roleId}${user?.user_name}`)}>
            <h4>{`User name:`}</h4>
            <p>{user?.user_name}</p>
            <h4>{`Name:`}</h4>
            <p style={{ marginBottom: '0' }}>{`${user?.first_name} ${user?.last_name}`}</p>
          </div>
        )
      })}
    </>
  )
}
