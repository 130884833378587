import 'tippy.js/dist/tippy.css'
import { FiChevronLeft, FiChevronRight, FiGlobe, FiLoader, FiXSquare } from 'react-icons/fi'
import { GoPrimitiveDot } from 'react-icons/go'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { MdOutlineDocumentScanner } from 'react-icons/md'
import { MoreMenu } from '../MoreMenu'
import { hideFileExtension } from '../../utils/stringUtils'
import { useHistory } from 'react-router'
import { useModalContext } from '../../app'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import ClearIcon from '@mui/icons-material/Clear'
import FreeText from '../DatapointInput/FreeText'
import React, { CSSProperties, FC, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import Tippy from '@tippyjs/react'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _FilteredMenuItemsProps = {
  dealCounterpartyId?: string
  handleChange: any
  isMulti?: boolean
  menuItems: _MenuItem[]
  queryName?: QueryNames
  selectedItems: _MenuItem[]
}

type _InlineQueryMenuProps = {
  clearSelectedValue?: any
  dealCounterpartyId?: string
  existingData?: any
  getMaxHeight?: any
  handleChange?: any
  isAlwaysOpen?: boolean
  isError?: boolean
  isLoading?: boolean
  isMulti?: boolean
  menuItemClass?: string
  mutationVars?: any
  queryData?: any
  queryFunction: any
  queryItemName?: string
  queryName: QueryNames
  queryNoResultsMessage?: string
  queryPlaceholder?: string
  querySubName?: QuerySubNames
  queryVars?: any
  selectedItems?: any
  setClearSelectedValue?: any
  setKey?: any
  style?: CSSProperties
}

type _MenuItem = {
  counterparty?: any
  dealCounterpartyId?: any
  handleChange?: any
  href?: string
  id?: string
  in_neutral_tagset?: boolean
  isAccountLevel?: boolean
  isMulti?: boolean
  isSelected?: boolean
  label: string
  onClick: (...args: any) => void
  queryName?: QueryNames
  setIsVisible?: (b: boolean) => void
  setSearchTerm?: any
  setSelectedValue?: any
}

type _MenuOptionsProps = {
  dealCounterpartyId?: any
  handleChange: any
  menuItems?: _MenuItem[]
  queryName?: QueryNames
  setIsVisible: any
  setSearchTerm: any
  setSelectedValue: any
}

type _NoResultsFoundProps = { menuItems?: _MenuItem[]; queryItemName?: string; queryNoResultsMessage?: string; queryPlaceholder?: string }

type _PaginationButtonsProps = { handleNext: any; handlePrev: any; nextDisabled: boolean; prevDisabled: boolean }

type _SelectedItemsProps = { dealCounterpartyId?: string; handleChange: any; queryName?: QueryNames; selectedItems: _MenuItem[] }

// enums

export enum QueryNames {
  CCI_NEUTRAL_TAGS,
  COUNTERPARTIES_BY_NAME,
  DEALS_BY_COUNTERPARTY,
  DOCUMENTS_BY_COUNTERPARTY,
  DOCUMENTS_BY_DEAL
}

export enum QuerySubNames {
  ADD_DOCUMENT_TO_DEAL,
  CHANGE_COUNTERPARTY
}

// components

/**
 * This is a deeply troubled component which dynamically renders a paginated menu of items fetched from a GraphQL query.
 * It was apparently designed to be a custom dropdown with a <textarea> for searching and displaying the selected value,
 * and a list of clickable <div> elements for the menu items. It may have been sounder to have implemented this as a
 * custom variant of a <select> element, as the current approach has resulted in much unnecessary complexity.
 *
 * One major pitfall to be aware of:
 *
 * Depending on the props configuration, the <textarea> must be focused for the menu to be open. However, the component
 * contains other interactive elements which can be clicked, causing the <textarea> to lose focus and the menu to close.
 * To address this, all interactive elements other than the <textarea> must have their `onMouseDown` events overridden
 * to prevent the <textarea> from losing focus. This is done by setting `onMouseDown={event => event.preventDefault()}`.
 */
export const InlineQueryMenu: FC<_InlineQueryMenuProps> = ({
  clearSelectedValue,
  dealCounterpartyId,
  existingData,
  getMaxHeight,
  handleChange,
  isAlwaysOpen,
  isError,
  isLoading,
  isMulti,
  menuItemClass,
  mutationVars,
  queryData,
  queryFunction,
  queryItemName,
  queryName,
  queryNoResultsMessage,
  queryPlaceholder,
  querySubName,
  queryVars,
  selectedItems,
  setClearSelectedValue,
  style
}) => {
  const [customStyles, setCustomStyles] = useState<CSSProperties>({})
  const [isFocused, setIsFocused] = useState(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true)
  const [isVisible, setIsVisible] = useState(false)
  const [menuItems, setMenuItems] = useState<_MenuItem[]>([])
  const [offset, setOffset] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const history = useHistory()
  const menuRef = useRef<HTMLDivElement | null>(null)

  // effects

  useEffect(() => {
    if (!isMulti && !selectedValue) {
      setSelectedValue(existingData)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!isAlwaysOpen) {
      setIsFocused(false)
      resetMenu()
    }
  }, [selectedValue]) // eslint-disable-line

  useEffect(() => {
    if (isFocused || isLoading) {
      setIsVisible(true)
    } else if (!isFocused && !isAlwaysOpen) {
      resetMenu()
    }
  }, [isAlwaysOpen, isFocused]) // eslint-disable-line

  useEffect(() => {
    if (clearSelectedValue) {
      setSelectedValue('')
      setClearSelectedValue(false)
    }
  }, [clearSelectedValue, setClearSelectedValue])

  useEffect(() => {
    if (getMaxHeight) {
      const parentHeight = getMaxHeight()
      const heightOffset = 190 - 16
      const paginationOffset = 44
      const includeDocumentsRowOffset = 69
      const calculatedHeight =
        isPrevButtonDisabled && isNextButtonDisabled ? parentHeight - heightOffset : parentHeight - heightOffset - paginationOffset - includeDocumentsRowOffset

      setCustomStyles({ maxHeight: `${calculatedHeight}px` })
    }
  }, [getMaxHeight, isPrevButtonDisabled, isNextButtonDisabled]) // eslint-disable-line

  useEffect(() => {
    // May need to add additional map functions/if statements to support different query types
    const items: _MenuItem[] = []
    let totalItemCount = 0

    switch (queryName) {
      case QueryNames.CCI_NEUTRAL_TAGS:
        if (searchTerm) {
          items.push({
            label: `Create “${searchTerm}”`,
            onClick: () => handleChange(searchTerm, searchTerm)
          })
        }
        queryData?.cci_neutral_tags?.forEach((item: any) => {
          if (item?.external_name && items.length < 10) {
            items.push({
              label: item?.external_name,
              id: item?.internal_name,
              in_neutral_tagset: item?.in_neutral_tagset,
              onClick: () => handleChange(item?.external_name, item?.internal_name)
            })
          }
        })
        totalItemCount = queryData?.cci_neutral_tags?.length
        break

      case QueryNames.COUNTERPARTIES_BY_NAME:
        if (searchTerm) {
          if (querySubName === QuerySubNames.CHANGE_COUNTERPARTY) {
            items.push({
              label: `Create “${searchTerm}”`,
              onClick: () => handleChange(undefined, searchTerm)
            })
          } else {
            items.push({
              label: `Create “${searchTerm}”`,
              onClick: () => handleChange('counterparty_name', searchTerm)
            })
          }
        }
        queryData?.counter_parties_by_name?.forEach((item: any) => {
          if (item?.name) {
            if (querySubName === QuerySubNames.CHANGE_COUNTERPARTY) {
              items.push({
                label: item?.name,
                id: item?.id,
                onClick: () => handleChange({ id: item?.id, name: item?.name }, '')
              })
            } else {
              items.push({
                label: item?.name,
                id: item?.id,
                onClick: () => {
                  handleChange('counterparty_name', item?.name)
                  handleChange('counterparty_id', item?.id)
                }
              })
            }
          }
        })
        totalItemCount = queryData?.counter_parties_by_name?.length
        break

      case QueryNames.DEALS_BY_COUNTERPARTY:
        if (querySubName !== QuerySubNames.ADD_DOCUMENT_TO_DEAL) {
          if (searchTerm) {
            items.push({
              label: `Create “${searchTerm}”`,
              onClick: () => handleChange('deal_name', searchTerm)
            })
          }
        }
        queryData?.deals_by_counter_party?.forEach((item: any) => {
          if (item?.name) {
            items.push({
              label: item?.alias || item?.name,
              onClick: () => {
                if (querySubName === QuerySubNames.ADD_DOCUMENT_TO_DEAL && checkForRequiredValues(mutationVars)) {
                  handleChange({ variables: { dealId: item?.id, documentIds: mutationVars?.required?.documentIds }, dealName: item?.name })
                } else {
                  handleChange('deal_name', item?.name)
                  handleChange('deal_id', item?.id)
                }
              }
            })
          }
        })
        totalItemCount = queryData?.deals_by_counter_party?.length
        break

      case QueryNames.DOCUMENTS_BY_COUNTERPARTY:
        queryData?.documents_by_counter_party?.forEach((item: any) => {
          if (item?.name && items.length < 10 && !existingData?.includes(item?.id)) {
            items.push({
              label: hideFileExtension(item?.alias || item?.name),
              id: item?.id,
              onClick: () => null,
              counterparty: item?.counter_party
            })
          }
        })
        totalItemCount = queryData?.documents_by_counter_party?.length
        break

      case QueryNames.DOCUMENTS_BY_DEAL:
        queryData?.documents_by_deal?.forEach((item: any) => {
          if (item?.name && items.length < 10 && !existingData?.includes(item?.id)) {
            items.push({
              label: hideFileExtension(item?.alias || item?.name),
              id: item?.id,
              href: `/documents/${item.id}`,
              onClick: () => null,
              counterparty: item?.counter_party,
              isAccountLevel: item?.is_counter_party_level
            })
          }
          totalItemCount = queryData?.documents_by_deal?.length
        })
        break

      default:
        break
    }

    setIsNextButtonDisabled(Boolean(!totalItemCount || totalItemCount < 11))
    setIsPrevButtonDisabled(Boolean(offset === 0))
    setMenuItems([...items])
  }, [searchTerm, queryData, queryName, handleChange, history, offset, existingData, isLoading]) // eslint-disable-line

  // functions

  const resetMenu = () => {
    setIsPrevButtonDisabled(true)
    setOffset(0)
    setSearchTerm('')
    setSearchValue('')

    if (!isFocused) setIsVisible(false)

    if (checkForRequiredValues(queryVars)) {
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm: '', offset: 0, size: 20 } })
    }
  }

  const checkForRequiredValues = (varObject: any) => {
    if (varObject?.required) {
      for (const key of Object.keys(varObject?.required)) {
        if (!varObject?.required[key]) {
          return false
        }
      }
      return true
    } else {
      return true
    }
  }

  const handleNext = () => {
    if (checkForRequiredValues(queryVars)) {
      const newOffset = offset + 10

      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
  }

  const handlePrev = () => {
    if (checkForRequiredValues(queryVars)) {
      const newOffset = offset - 10 <= 0 ? 0 : offset - 10

      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (searchTerm || (!searchTerm && searchValue)) {
        if (checkForRequiredValues(queryVars)) {
          queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: 0, size: 20 } })
          setOffset(0)
          setSearchValue(searchTerm)
        }
      }
    }
  }

  // render

  return (
    <>
      <div
        className={clsx(css.menuPane, isFocused && css.isFocused)}
        ref={menuRef}
        style={style ? { ...style, zIndex: isVisible || isAlwaysOpen ? 8001 : 0 } : { zIndex: isVisible || isAlwaysOpen ? 8001 : 0 }}
      >
        <ul style={{ cursor: 'default' }}>
          {/* Semantically incorrect, but wrapped in <header> to avoid complex style conflicts in the stylesheet. */}
          <header style={{ position: 'relative', width: '100%' }}>
            <FreeText
              ariaLabel={queryPlaceholder || 'Search…'}
              isError={isError}
              isFocused={isFocused}
              onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => setSearchTerm(event.currentTarget.value)}
              onKeyDown={handleKeyDown}
              placeholder={queryPlaceholder || 'Search…'}
              setFocused={setIsFocused}
              style={{ paddingRight: 42 }}
              value={isMulti || isFocused ? searchTerm : searchTerm || selectedValue}
            />

            {(isVisible || isAlwaysOpen) && !isLoading && (
              <IconButton
                aria-label={searchTerm ? 'Clear Search' : 'Refresh'}
                onClick={resetMenu}
                onMouseDown={event => event.preventDefault()}
                size="small"
                sx={{ position: 'absolute', right: 14, top: 6 }}
              >
                {searchTerm ? <ClearIcon sx={{ fontSize: 16 }} /> : <RefreshIcon sx={{ fontSize: 16 }} />}
              </IconButton>
            )}
          </header>

          {(isVisible || isAlwaysOpen) && (
            <>
              <div className={menuItemClass} onMouseDown={event => event.preventDefault()} style={customStyles}>
                {isMulti && (
                  <SelectedItems dealCounterpartyId={dealCounterpartyId} handleChange={handleChange} queryName={queryName} selectedItems={selectedItems} />
                )}

                {isLoading ? (
                  <div className={css.rotate}>
                    <FiLoader />
                  </div>
                ) : isMulti ? (
                  <FilteredMenuItems
                    dealCounterpartyId={dealCounterpartyId}
                    handleChange={handleChange}
                    menuItems={menuItems}
                    queryName={queryName}
                    selectedItems={selectedItems}
                  />
                ) : (
                  <MenuOptions
                    dealCounterpartyId={dealCounterpartyId}
                    handleChange={handleChange}
                    menuItems={menuItems}
                    queryName={queryName}
                    setIsVisible={setIsVisible}
                    setSearchTerm={setSearchTerm}
                    setSelectedValue={setSelectedValue}
                  />
                )}

                {!isLoading && (
                  <NoResultsFound
                    menuItems={menuItems}
                    queryItemName={queryItemName}
                    queryNoResultsMessage={queryNoResultsMessage}
                    queryPlaceholder={queryPlaceholder}
                  />
                )}
              </div>

              {!isLoading && (
                <PaginationButtons handleNext={handleNext} handlePrev={handlePrev} nextDisabled={isNextButtonDisabled} prevDisabled={isPrevButtonDisabled} />
              )}
            </>
          )}
        </ul>
      </div>
    </>
  )
}

const MenuItem: FC<_MenuItem> = ({
  counterparty,
  dealCounterpartyId,
  handleChange,
  href,
  id,
  in_neutral_tagset,
  isAccountLevel,
  isMulti,
  isSelected,
  label,
  onClick,
  queryName,
  setIsVisible,
  setSearchTerm,
  setSelectedValue
}) => {
  const { openPreview } = useModalContext()

  const handleClick = () => {
    if (isMulti && !isSelected) {
      handleChange('Add', { label, id, counterparty })
    } else {
      onClick()
      setSelectedValue(label)
      setSearchTerm('')
      setIsVisible?.(false)
    }
  }

  if (queryName === QueryNames.DOCUMENTS_BY_DEAL) {
    const isForeignDocument = counterparty?.id ? counterparty?.id !== dealCounterpartyId : false
    const menuItemList: _MenuItem[] = [{ label: 'Preview', onClick: () => openPreview(id, true, `${label}.pdf`) }]

    return (
      <div className={css.menuItem} style={{ display: 'flex', alignItems: 'center', cursor: 'unset' }}>
        {isAccountLevel && !isForeignDocument && (
          <Tippy content="Customer Level Document">
            <div className={css.accountLevelTooltip}>
              <FiGlobe />
            </div>
          </Tippy>
        )}

        {isForeignDocument && (
          <Tippy
            content={
              <>
                {`This document is from the customer:`}
                <div>{counterparty?.name}</div>
              </>
            }
          >
            {/* The div breaks the tooltip text to a new line */}
            <div className={css.accountLevelTooltip}>
              <MdOutlineDocumentScanner style={{ width: '22px', height: '22px' }} />
            </div>
          </Tippy>
        )}

        {href ? (
          <WithTooltip content="Open Document">
            <Link to={href}>{label}</Link>
          </WithTooltip>
        ) : (
          <p>{label}</p>
        )}

        <MoreMenu menuItemList={menuItemList} />
      </div>
    )
  } else if (queryName === QueryNames.DOCUMENTS_BY_COUNTERPARTY) {
    const isForeignDocument = counterparty?.id ? counterparty?.id !== dealCounterpartyId : false
    const menuItemList: _MenuItem[] = [{ label: 'Preview', onClick: () => openPreview(id, true, `${label}.pdf`) }]

    return (
      <div
        className={isSelected ? css.menuItemSelected : css.menuItem}
        onClick={isSelected ? undefined : handleClick}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isForeignDocument && (
            <Tippy
              content={
                <>
                  {`This document is from the customer:`}
                  <div>{counterparty?.name}</div>
                </>
              }
            >
              {/* The div breaks the tooltip text to a new line */}
              <div className={css.accountLevelTooltip}>
                <MdOutlineDocumentScanner style={{ width: '22px', height: '22px' }} />
              </div>
            </Tippy>
          )}

          {href ? (
            <WithTooltip content="Open link">
              <Link to={href}>{label}</Link>
            </WithTooltip>
          ) : (
            <p>{label}</p>
          )}
        </div>

        <div onClick={event => event.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
          <WithTooltip content="More Options">
            <MoreMenu menuItemList={menuItemList} />
          </WithTooltip>

          <WithTooltip content="Deselect Item">
            <>{isSelected && <Button aria-label="Remove" icon={<FiXSquare />} onClick={() => handleChange('Remove', { label, id })} />}</>
          </WithTooltip>
        </div>
      </div>
    )
  } else if (queryName === QueryNames.CCI_NEUTRAL_TAGS) {
    return (
      <div className={css.menuItem} onClick={handleClick}>
        {in_neutral_tagset ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WithTooltip content="This tag is in the Neutral Tagset">
              <GoPrimitiveDot style={{ margin: '4px 8px 0 0' }} />
            </WithTooltip>
            <p>{label}</p>
          </div>
        ) : (
          <p>{label}</p>
        )}
      </div>
    )
  }

  return (
    <div className={isSelected ? css.menuItemSelected : css.menuItem} onClick={isSelected ? undefined : handleClick}>
      {href ? (
        <WithTooltip content="Open link">
          <Link to={href}>{label}</Link>
        </WithTooltip>
      ) : (
        <p>{label}</p>
      )}

      {isSelected && <Button aria-label="Remove" icon={<FiXSquare />} onClick={() => handleChange('Remove', { label, id })} />}
    </div>
  )
}

const SelectedItems: FC<_SelectedItemsProps> = ({ dealCounterpartyId, handleChange, queryName, selectedItems }) => (
  <>
    {selectedItems.map((item, index) => (
      <MenuItem
        key={`${item.label}, ${index}`}
        {...item}
        dealCounterpartyId={dealCounterpartyId}
        handleChange={handleChange}
        isMulti={true}
        isSelected={true}
        queryName={queryName}
      />
    ))}
  </>
)

// Remove already selected items from the menu options
const FilteredMenuItems: FC<_FilteredMenuItemsProps> = ({ dealCounterpartyId, handleChange, menuItems, queryName, selectedItems }) => (
  <>
    {menuItems?.length > 0 ? (
      <>
        {(() => {
          let length = selectedItems?.length
          const items = menuItems?.map((item, index) => {
            for (let i = 0; i <= selectedItems?.length; i++) {
              if (length < 10) {
                if (selectedItems[i]?.id === item?.id) {
                  break
                } else if (i >= selectedItems?.length) {
                  length += 1
                  return (
                    <MenuItem
                      key={`${item.label}, ${index}`}
                      {...item}
                      dealCounterpartyId={dealCounterpartyId}
                      handleChange={handleChange}
                      isMulti={true}
                      queryName={queryName}
                    />
                  )
                }
              }
            }
            return undefined
          })
          return items
        })()}
      </>
    ) : null}
  </>
)

const MenuOptions: FC<_MenuOptionsProps> = ({ dealCounterpartyId, handleChange, menuItems, queryName, setIsVisible, setSearchTerm, setSelectedValue }) => (
  <>
    {menuItems &&
      menuItems?.length > 0 &&
      menuItems
        .slice(0, 10)
        ?.map((item, index) => (
          <MenuItem
            key={`${item.label}, ${index}`}
            {...item}
            dealCounterpartyId={dealCounterpartyId}
            handleChange={handleChange}
            queryName={queryName}
            setIsVisible={setIsVisible}
            setSearchTerm={setSearchTerm}
            setSelectedValue={setSelectedValue}
          />
        ))}
  </>
)

const NoResultsFound: FC<_NoResultsFoundProps> = ({ menuItems, queryItemName, queryNoResultsMessage, queryPlaceholder }) => {
  const { t } = useTranslation()

  return queryPlaceholder === `Create customer’s first ${t('deal')}` ? null : (
    <>
      {menuItems?.length === 0 && (
        <li className={css.menuItem} style={{ cursor: 'default' }}>
          {queryNoResultsMessage || `No ${queryItemName ? (queryItemName === 'deals' ? t('deals') : queryItemName) : 'results'} found`}
        </li>
      )}
    </>
  )
}

const PaginationButtons: FC<_PaginationButtonsProps> = ({ handleNext, handlePrev, nextDisabled, prevDisabled }) => (
  <>
    {!(prevDisabled && nextDisabled) && (
      <li className={css.pagination} onMouseDown={event => event.preventDefault()}>
        <Button aria-label="Previous Page" className={clsx(prevDisabled && css.isDisabled)} icon={<FiChevronLeft />} onClick={handlePrev} />

        <Button
          aria-label="Next Page"
          className={clsx(nextDisabled && css.isDisabled)}
          icon={<FiChevronRight />}
          onClick={handleNext}
          style={{ marginLeft: 'auto' }}
        />
      </li>
    )}
  </>
)
