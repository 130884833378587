import Loader from '../Loader'
import React, { ReactNode } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

function Button({
  children,
  className,
  danger,
  htmlType,
  icon,
  loading,
  noWrap = false,
  setRef,
  size,
  variant,
  ...rest
}: {
  [x: string]: any
  children?: ReactNode
  className?: string
  danger?: boolean
  disabled?: boolean
  htmlType?: 'button' | 'submit' | undefined
  icon?: ReactNode | null
  loading?: boolean
  noWrap?: boolean
  onClick?: (e: any) => void
  ref?: any
  rest?: any
  setRef?: any
  size?: 's'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger'
}) {
  return (
    <button
      ref={setRef}
      type={htmlType}
      {...rest}
      className={clsx(
        css.button,
        className,
        icon ? css.icon : css[variant || 'primary'],
        danger && css.danger,
        loading && css.loading,
        size && css[`size-${size}`],
        noWrap && css.noWrap
      )}
    >
      {loading && <Loader size="s" />}
      {!loading && (
        <>
          {icon}
          {children}
        </>
      )}
    </button>
  )
}

export default Button
