import { useEffect, useState } from 'react'

export default function useTablePagination({ total }: { total?: number }) {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLength, setPageLength] = useState<10 | 25 | 50 | undefined>(undefined)
  const [offset, setOffset] = useState((currentPage - 1) * (pageLength || 10))
  const [pageCount, setPageCount] = useState(total ? Math.ceil(total / (pageLength || 10)) : 1)

  const nextPage = () => {
    setCurrentPage(curr => Math.min(curr + 1, pageCount))
  }
  const prevPage = () => {
    setCurrentPage(curr => Math.max(curr - 1, 1))
  }
  const jumpToPage = (p: number) => setCurrentPage(p)

  useEffect(() => {
    setCurrentPage(1)
    setOffset(0)
    if (total && pageCount !== Math.ceil(total / (pageLength || 10))) {
      setPageCount(Math.ceil(total / (pageLength || 10)))
    }
    // eslint-disable-next-line
  }, [pageLength, total])

  useEffect(() => {
    setOffset((currentPage - 1) * (pageLength || 10))
    // eslint-disable-next-line
  }, [currentPage])

  return {
    currentPage,
    pageCount,
    offset,
    pageLength,
    setPageLength,
    nextPage,
    prevPage,
    jumpToPage
  }
}
