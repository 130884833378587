import { Axis, Draggable } from '../index'
import {
  DndContext,
  KeyboardSensor,
  Modifiers,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  Translate,
  useDraggable,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import React, { CSSProperties, useState } from 'react'

const defaultCoordinates = {
  x: 0,
  y: 0
}

interface Props {
  activationConstraint?: PointerActivationConstraint
  axis?: Axis
  content?: any
  handle?: boolean
  modifiers?: Modifiers
  style?: CSSProperties
}

export const DraggableContainer = ({ activationConstraint, axis, content, handle, modifiers, style }: Props) => {
  const [{ translate }, setTranslate] = useState<{
    initialTranslate: Translate
    translate: Translate
  }>({ initialTranslate: defaultCoordinates, translate: defaultCoordinates })
  const [initialWindowScroll, setInitialWindowScroll] = useState(defaultCoordinates)
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint
  })
  const keyboardSensor = useSensor(KeyboardSensor, {})
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor)

  return (
    <DndContext
      modifiers={modifiers}
      onDragCancel={() => {
        setTranslate(({ initialTranslate }) => ({
          translate: initialTranslate,
          initialTranslate
        }))
        setInitialWindowScroll(defaultCoordinates)
      }}
      onDragEnd={() => {
        setTranslate(({ translate }) => {
          return {
            translate,
            initialTranslate: translate
          }
        })
        setInitialWindowScroll(defaultCoordinates)
      }}
      onDragMove={({ delta }) => {
        setTranslate(({ initialTranslate }) => ({
          initialTranslate,
          translate: {
            x: initialTranslate.x + delta.x - initialWindowScroll.x,
            y: initialTranslate.y + delta.y - initialWindowScroll.y
          }
        }))
      }}
      onDragStart={() => {
        setInitialWindowScroll({
          x: window.scrollX,
          y: window.scrollY
        })
      }}
      sensors={sensors}
    >
      <DraggableItem axis={axis} content={content} handle={handle} style={style} translate={translate} />
    </DndContext>
  )
}

interface DraggableItemProps {
  axis?: Axis
  content: any
  handle?: boolean
  style?: CSSProperties
  translate: Translate
}

function DraggableItem({ axis, content, handle, style, translate }: DraggableItemProps) {
  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({
    id: 'draggable'
  })

  return (
    <Draggable
      axis={axis}
      content={content}
      dragging={isDragging}
      handle={handle}
      listeners={listeners}
      ref={setNodeRef}
      style={style}
      translate={translate}
      {...attributes}
    />
  )
}
