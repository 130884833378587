import { useLayoutEffect, useState } from 'react'

// this react hook helps you to understand and watch
// which are the device's width and height

const useWindowSize = () => {
  // initializes the default width and height with the current screen state
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useLayoutEffect(() => {
    // updates the size everytime it changes
    handleSize()

    // adds a listener to update the state values everytime the current screen changes
    window.addEventListener('resize', handleSize)

    // removes the listener when the parent component is unmounted
    return () => window.removeEventListener('resize', handleSize)
  }, [])

  return windowSize
}

export default useWindowSize
