import Button from '../Button'
import Modal from '../Modal'
import React, { useState } from 'react'
import UserSelector from '../UserSelector'

interface Props {
  handleSave: (selectedUserId: string) => void
  isOpen: boolean
  loading: boolean
  setIsOpen: (isOpen: boolean) => void
}

export default function AssigneeModal({ handleSave, isOpen, loading, setIsOpen }: Props) {
  const [selected, setSelected] = useState('')

  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Update Assignee">
      <div style={{ marginBottom: 8 }}>{`Update Assignee to`}</div>
      <UserSelector onChange={({ value }: { value: string }) => setSelected(value)} />

      <div style={{ display: 'flex', marginTop: 8 }}>
        <Button disabled={loading || selected === undefined} loading={loading} onClick={() => handleSave(selected)}>{`Update Assignee`}</Button>
        <Button onClick={() => setIsOpen(false)} variant="secondary">{`Cancel`}</Button>
      </div>
    </Modal>
  )
}
