import { Button } from '@mui/material'
import { Check } from 'react-feather'
import { formatDate } from '../../utils/datetimeUtils'
import { grey } from '@mui/material/colors'
import { useIsKlarityEmployee } from '../../hooks/useIsKlarityEmployee'
import { useLatestStateAuditsLazyQuery, useTransitionAuditsLazyQuery } from '../../graphql/codegen/hooks'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import LifecycleAudits from '../ModalOptions/AuditModals/LifecycleAudits'
import Modal from '../Modal'
import React, { FC, useEffect, useState } from 'react'
import Steps from 'rc-steps'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _LifecycleProps = {
  created_at?: any
  dealId?: string
  documentId?: string
  state?: any
}

type _StepsButtonProps = {
  children: any
  onClick: () => void
}

// cpomponents

export const Lifecycle: FC<_LifecycleProps> = ({ created_at, dealId, documentId, state }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')

  const [currentStep, setCurrentStep] = useState<any>(undefined)
  const [stepsComponent, setStepsComponent] = useState<any>(<></>)
  const isKlarityEmployee = useIsKlarityEmployee()

  const [getStateAudits, { data: stateAuditsData, loading: stateAuditsLoading }] = useLatestStateAuditsLazyQuery()
  const [getTransitionStates, { data: transitionStateData, loading: transitionStatesLoading }] = useTransitionAuditsLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    // @ts-ignore
    if (dealId || documentId) {
      // @ts-ignore
      getTransitionStates({ variables: { dealOrDocumentId: dealId || documentId } })
    }
    if (state?.workflow?.id && (dealId || documentId)) {
      getStateAudits({ variables: { workflowId: state?.workflow?.id, dealId, documentId, all_state_audits: false } })
    }
    // eslint-disable-next-line
  }, [dealId, documentId, state?.workflow?.id])

  useEffect(() => {
    const states = transitionStateData?.transition_audit?.states // @ts-ignore
    const current = states?.findIndex(({ active }: { active: boolean }) => active) // @ts-ignore
    const steps = states?.map(({ id, name }: { id: string; name: string }, index: number) => {
      // @ts-ignore
      const complete = index < current
      const currentStateAudit = stateAuditsData?.latest_state_audits?.filter((item: any) => item?.state_id === id)
      let tippyContent: any = ''

      if (currentStateAudit?.length === 1) {
        const { created_at, first_name, last_name, old_value, user_name } = currentStateAudit[0]?.audit || {}
        const transitionDate = created_at ? formatDate(created_at) : undefined
        const user = user_name?.includes('klaritylaw.com') ? (isKlarityEmployee ? `${first_name} ${last_name}` : 'Klarity') : `${first_name} ${last_name}`
        tippyContent = (
          <>
            {old_value && <div>{`Previous State: ${old_value}`}</div>}
            {transitionDate && <div>{`Transition Date: ${transitionDate}`}</div>}
            {user && first_name && last_name && <div>{`Moved By: ${user}`}</div>}
          </>
        )
      } else if (name === 'Processing' && created_at) {
        tippyContent = `Creation Date: ${formatDate(created_at)}`
      }

      return <TooltipStep icon={complete && <Check />} key={`${id}${index}`} tippyContent={tippyContent} title={name} />
    })

    setCurrentStep(current)
    setStepsComponent(
      <StepsButton onClick={() => setIsOpen(true)}>
        <Steps current={current}>{steps}</Steps>
      </StepsButton>
    )
    // eslint-disable-next-line
  }, [transitionStateData, stateAuditsData])

  return (
    <>
      {!stateAuditsLoading && !transitionStatesLoading && transitionStateData?.transition_audit?.states ? (
        state?.workflow?.is_non_linear ? (
          <StepsButton onClick={() => setIsOpen(true)}>
            {' '}
            {/* We display them in a tooltip on hover for documents with 'non-linear' workflows. */}
            <WithTooltip className={css.lifecycleTooltip} content={stepsComponent}>
              <div className={css.lifecycleTag}>{transitionStateData?.transition_audit?.states[currentStep]?.name}</div>
            </WithTooltip>
          </StepsButton>
        ) : (
          stepsComponent
        )
      ) : null}
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Lifecycle Audit Log">
        <ComponentLoadingOverlay loading={modalLoading} message={modalLoadingMessage} />

        <LifecycleAudits
          dealId={dealId}
          documentId={documentId}
          setIsOpen={setIsOpen}
          setModalLoading={setModalLoading}
          setModalLoadingMessage={setModalLoadingMessage}
          workflowId={state?.workflow?.id}
        />
      </Modal>
    </>
  )
}

const LocalStep = ({
  active,
  className,
  description,
  disabled,
  icon,
  iconPrefix,
  icons,
  prefixCls,
  progressDot,
  status,
  stepIcon,
  stepNumber,
  style,
  subTitle,
  tailContent,
  tippyContent,
  title
}: any) => {
  const isString = (str: unknown): boolean => typeof str === 'string'

  const renderIconNode = () => {
    let iconNode
    const iconClassName = clsx(
      `${prefixCls}-icon`,
      `${iconPrefix}icon`,
      icon && isString(icon) && `${iconPrefix}icon-${icon}`,
      !icon && status === 'finish' && ((icons && !icons.finish) || !icons) && `${iconPrefix}icon-check`,
      !icon && status === 'error' && ((icons && !icons.error) || !icons) && `${iconPrefix}icon-cross`
    )
    const iconDot = <span className={`${prefixCls}-icon-dot`} />
    // `progressDot` enjoy the highest priority
    if (progressDot) {
      if (typeof progressDot === 'function') {
        iconNode = <span className={`${prefixCls}-icon`}>{progressDot(iconDot, { index: stepNumber - 1, status, title, description })}</span>
      } else {
        iconNode = <span className={`${prefixCls}-icon`}>{iconDot}</span>
      }
    } else if (icon && !isString(icon)) {
      iconNode = <span className={`${prefixCls}-icon`}>{icon}</span>
    } else if (icons && icons.finish && status === 'finish') {
      iconNode = <span className={`${prefixCls}-icon`}>{icons.finish}</span>
    } else if (icons && icons.error && status === 'error') {
      iconNode = <span className={`${prefixCls}-icon`}>{icons.error}</span>
    } else if (icon || status === 'finish' || status === 'error') {
      iconNode = <span className={iconClassName} />
    } else {
      iconNode = <span className={`${prefixCls}-icon`}>{stepNumber}</span>
    }
    if (stepIcon) {
      iconNode = stepIcon({ index: stepNumber - 1, status, title, description, node: iconNode })
    }

    return iconNode
  }

  const classString = clsx(
    `${prefixCls}-item`,
    `${prefixCls}-item-${status}`,
    className,
    icon && `${prefixCls}-item-custom`,
    active && `${prefixCls}-item-active`,
    disabled && `${prefixCls}-item-disabled`
  )

  return (
    <WithTooltip content={tippyContent}>
      <div className={classString} style={{ ...style }}>
        <div className={`${prefixCls}-item-container`}>
          <div className={`${prefixCls}-item-tail`}>{tailContent}</div>
          <div className={`${prefixCls}-item-icon`}>{renderIconNode()}</div>
          <div className={`${prefixCls}-item-content`}>
            <div className={`${prefixCls}-item-title`}>
              {title}
              {subTitle && (
                <div className={`${prefixCls}-item-subtitle`} title={typeof subTitle === 'string' ? subTitle : undefined}>
                  {subTitle}
                </div>
              )}
            </div>
            {description && <div className={`${prefixCls}-item-description`}>{description}</div>}
          </div>
        </div>
      </div>
    </WithTooltip>
  )
}

const StepsButton: FC<_StepsButtonProps> = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    sx={{ color: grey[700], justifyContent: 'space-between', overflow: 'auto', pb: 2, pl: 1, pr: 0, pt: 0, width: '100%', '&:hover': { background: 'none' } }}
  >
    {children}
  </Button>
)

// Copying the contents of <Step /> from rc-step was necessary in order to add the Tippy tooltip. Props were not getting passed correctly when trying to add it as a wrapper
const TooltipStep = ({ icon, tippyContent, title, ...rest }: { [x: string]: any; icon?: any; tippyContent?: any; title?: string }) => (
  <LocalStep icon={icon} tippyContent={tippyContent} title={title} {...rest} />
)
