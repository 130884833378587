import * as Documents from './documents'
import { baseApi } from './baseApi'
import type * as Types from './types'

export const codegenApi = baseApi.injectEndpoints({
  endpoints: build => ({
    cciRolesWithFeatures: build.query<Types.CciRolesWithFeaturesQuery, Types.CciRolesWithFeaturesQueryVariables | void>({
      query: variables => ({ document: Documents.CciRolesWithFeaturesDocument, variables })
    }),
    cciUsers: build.query<Types.CciUsersQuery, Types.CciUsersQueryVariables | void>({
      query: variables => ({ document: Documents.CciUsersDocument, variables })
    }),
    currentUser: build.query<Types.CurrentUserQuery, Types.CurrentUserQueryVariables | void>({
      query: variables => ({ document: Documents.CurrentUserDocument, variables })
    })
  })
})
