import { BiSelectMultiple } from 'react-icons/bi'
import { Column, DocumentQueryAction } from '../../../reducers/dashboardQueryReducer'
import { formatFieldList } from '../../../utils/fieldUtils'
import {
  useAdvancedAttachmentsDashboardFieldsLazyQuery,
  useAdvancedDashboardFieldsLazyQuery,
  useDealsDashboardFieldsLazyQuery
} from '../../../graphql/codegen/hooks'
import { useIsZuoraTenant } from '../../../hooks/useIsZuoraTenant'
import Button from '../../Button'
import Input from '../../Input'
import Modal from '../../Modal'
import React, { Dispatch, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'
import useGetExcludedFields from '../../../hooks/useGetExcludedFields'

function FieldSelectionModal({
  dashboardTab,
  dispatch,
  fieldsToHide,
  isOpen,
  selectedColumns,
  setDefaultFields,
  ...rest
}: {
  [x: string]: any
  dashboardTab: string
  dispatch: Dispatch<DocumentQueryAction>
  fieldsToHide?: any
  isOpen: boolean
  selectedColumns: Column[]
  setDefaultFields: () => void
}) {
  const [data, setData] = useState<any>(undefined)
  const [fieldsLoading, setFieldsLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [options, setOptions] = useState<Column[] | null>(null)
  const [filteredOptions, setFilteredOptions] = useState<Column[] | null>(null)
  const fieldsToExclude = useGetExcludedFields(dashboardTab, 'FieldSelector')
  const isZuoraTenant = useIsZuoraTenant()

  const getFieldsToExclude = useMemo(() => {
    if (!fieldsToHide?.length) {
      return fieldsToExclude
    }
    return [...fieldsToExclude, ...fieldsToHide]
  }, [fieldsToExclude, fieldsToHide])

  const [getDealFields, { loading: dealFieldsLoading }] = useDealsDashboardFieldsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      setData(formatFieldList(data, getFieldsToExclude))
    }
  })
  const [getDocumentFields, { loading: documentFieldsLoading }] = useAdvancedDashboardFieldsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      setData(formatFieldList(data, getFieldsToExclude))
    }
  })
  const [getAttachmentFields, { loading: attachmentFieldsLoading }] = useAdvancedAttachmentsDashboardFieldsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      setData(formatFieldList(data, getFieldsToExclude))
    }
  })
  const selectedIds = selectedColumns.map(({ value }) => value)

  useEffect(() => {
    if (dashboardTab === 'Deals') {
      getDealFields()
    } else if (dashboardTab === 'Documents') {
      getDocumentFields()
    } else if (dashboardTab === 'Attachments') {
      getAttachmentFields()
    }
    // eslint-disable-next-line
  }, [dashboardTab])

  useEffect(() => {
    if (data) {
      const fields = Object.values(data)
      const sorted = fields?.sort((a: any, b: any) => {
        return a.label.localeCompare(b.label)
      })
      // @ts-ignore
      setOptions(sorted ? [...sorted] : null)
    } else {
      setOptions(null)
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!filter) {
      return setFilteredOptions(null)
    }
    const sorted = options?.filter((opt: Column) => opt.label.toLowerCase().includes(filter.toLowerCase())) || null
    setFilteredOptions(sorted)
  }, [filter, options])

  useEffect(() => {
    if (!isOpen) {
      setFilter('')
      setFilteredOptions(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (dealFieldsLoading || documentFieldsLoading || attachmentFieldsLoading) {
      setFieldsLoading(true)
    } else {
      setFieldsLoading(false)
    }
  }, [dealFieldsLoading, documentFieldsLoading, attachmentFieldsLoading])

  const handleChange = (event: SyntheticEvent<HTMLInputElement>, selectedColumn: Column) =>
    dispatch({ type: event.currentTarget.checked ? 'ADD_COLUMN' : 'REMOVE_COLUMN', selectedColumn })

  const handleSelectAll = () => {
    if (options) {
      dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns: options })
    }
  }

  const checkedFields = (filteredOptions || options)
    ?.sort((a: Column, b: Column) => {
      return a.label.toLowerCase() === b.label.toLowerCase() ? 0 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    })
    ?.filter((col: Column) => {
      return !!selectedIds.includes(col.value) && (!isZuoraTenant || col.name !== 'state')
    })
    ?.map((column: Column) => {
      const { label, value } = column
      const checked = !!selectedIds.includes(value)
      return (
        <li key={value}>
          <label>
            <input checked={checked} onChange={e => handleChange(e, column)} type="checkbox" />
            {label}
          </label>
        </li>
      )
    })

  const uncheckedFields = (filteredOptions || options)
    ?.sort((a: Column, b: Column) => {
      return a.label.toLowerCase() === b.label.toLowerCase() ? 0 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    })
    ?.filter((col: Column) => {
      return !selectedIds.includes(col.value) && (!isZuoraTenant || col.name !== 'state')
    })
    ?.map((column: Column) => {
      const { label, value } = column
      const checked = !!selectedIds.includes(value)
      return (
        <li key={value}>
          <label>
            <input checked={checked} onChange={e => handleChange(e, column)} type="checkbox" />
            {label}
          </label>
        </li>
      )
    })

  return (
    <Modal isOpen={isOpen} {...rest} title="Select Display Fields">
      <div className={css.searchRow}>
        <Input onChange={(e: SyntheticEvent<HTMLInputElement>) => setFilter(e.currentTarget.value)} placeholder="Filter options" />
        <Button onClick={handleSelectAll} variant="secondary">{`Select All`}</Button>
        <Button onClick={setDefaultFields}>{`Restore Defaults`}</Button>
      </div>
      <ul style={{ display: 'flex', marginTop: '16px' }}>
        {fieldsLoading ? (
          <Skeleton count={10} height={24} style={{ margin: '8px 0' }} />
        ) : (
          <>
            <div className={css.fieldList} style={{ marginRight: '0' }}>
              <h4>{`Field Options`}</h4>
              <div className={css.overflowContainer}>{uncheckedFields}</div>
            </div>
            <div className={css.fieldList} style={{ marginLeft: '24px' }}>
              <h4>{`Selected Fields`}</h4>
              <div className={css.overflowContainer}>{checkedFields}</div>
            </div>
          </>
        )}
      </ul>
    </Modal>
  )
}

export default function ModalButton({
  dashboardTab,
  dispatch,
  fieldsToHide,
  selectedColumns,
  setDefaultFields
}: {
  dashboardTab: string
  dispatch: Dispatch<DocumentQueryAction>
  fieldsToHide?: any
  selectedColumns: { label: string; value: string }[]
  setDefaultFields: () => void
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <FieldSelectionModal
        dashboardTab={dashboardTab}
        dispatch={dispatch}
        fieldsToHide={fieldsToHide}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        selectedColumns={selectedColumns}
        setDefaultFields={setDefaultFields}
      />

      <WithTooltip content="Select display fields">
        <Button aria-label="Select display fields" icon={<BiSelectMultiple />} onClick={() => setIsModalOpen(p => !p)} variant="primary" />
      </WithTooltip>
    </>
  )
}
