import { Box, TextField, Tooltip, Typography } from '@mui/material'
import { CciDealDataPointFieldsQuery } from '../../../../../../../graphql/codegen/operations'
import { grey } from '@mui/material/colors'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../../../../../app'
import { useCciDealDataPointFieldsQuery, useChangeNeutralTagMutation } from '../../../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../../../CCI_Main'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, useCallback, useEffect, useState } from 'react'
import css from './style.module.scss'

// constants

const LABEL = 'Automation Tag'

// functions

export const validateAutomationTag = (
  automationTag: string | undefined,
  checklistData: CciDealDataPointFieldsQuery | undefined,
  currentFieldId?: string
): string | undefined => {
  const trimmedAutomationTag = automationTag?.trim()

  if (!trimmedAutomationTag) return undefined

  const existingField = checklistData?.cci_deal_data_point_fields?.find(
    field => field !== null && (!currentFieldId || field.id !== currentFieldId) && (field.external_mapping?.includes(trimmedAutomationTag) ?? false)
  )

  return existingField ? `${LABEL} “${trimmedAutomationTag}” is already in use by the field “${existingField.name}”` : undefined
}

// components

export const AutomationTagInput: FC = () => {
  const { closeModal, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage, setSelectedItem } = useCciMainContext()
  const { data: cciDealDataPointFieldsData } = useCciDealDataPointFieldsQuery()
  const { setErrorMessage } = useAppContext()

  const [validationError, setValidationError] = useState<string>()
  const [value, setValue] = useState(selectedItem?.external_mapping?.[0] || '')

  const [editAutomationTag] = useChangeNeutralTagMutation({
    onCompleted: data => {
      closeModal({ isForced: true })

      // Updates to Apollo's cache are not automatically dispatched to `selectedItem`, so it must be updated manually.
      setSelectedItem(data.change_neutral_tag?.data_point_fields?.[0])

      toast.success(`${LABEL} successfully updated`, { autoClose: 5000 })
    },
    onError: () => {
      closeModal({ isForced: true })

      setErrorMessage(`Failed to update ${LABEL}`)
    }
  })

  const handleMutation = useCallback(
    (newValue: string) => {
      if (!selectedItem?.id || !newValue || validationError) return

      setIsModalOpen(true)
      setModalLoading(true)
      setModalLoadingMessage('Saving…')

      editAutomationTag({
        variables: {
          change_annotations: true,
          change_other_fields: false,
          data_point_field_id: selectedItem.id,
          new_neutral_tag: newValue,
          old_neutral_tag: selectedItem.external_mapping?.[0]
        }
      })
    },
    [editAutomationTag, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage, validationError]
  )

  const handleBlur = useCallback(() => {
    const newValue = value?.trim()

    if (!newValue) {
      setValue(selectedItem?.external_mapping?.[0])
    } else if (newValue !== selectedItem?.external_mapping?.[0]) {
      handleMutation(newValue)
    }
  }, [handleMutation, selectedItem, setValue, value])

  useEffect(() => {
    const validationError = validateAutomationTag(value, cciDealDataPointFieldsData, selectedItem.id)
    setValidationError(validationError)
  }, [cciDealDataPointFieldsData, selectedItem, value])

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="automation-tag-input" sx={{ flexShrink: 0, fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}

        <Tooltip arrow placement="right" title="Changes are saved automatically after clicking outside of the field.">
          <InfoOutlinedIcon sx={{ color: grey[500], fontSize: 16, ml: 0.5, mt: 0.25, position: 'absolute' }} />
        </Tooltip>
      </Typography>

      <TextField
        FormHelperTextProps={{ sx: { ml: 0 } }}
        InputProps={{ sx: { fontSize: 14 } }}
        autoComplete="off"
        error={Boolean(validationError)}
        fullWidth
        helperText={validationError}
        id="automation-tag-input"
        onBlur={handleBlur}
        onChange={event => setValue(event.target.value)}
        size="small"
        value={value}
        variant="outlined"
      />
    </Box>
  )
}
