import { ClassificationLevel } from '../../../graphql/codegen/schemas'
import { FiXSquare } from 'react-icons/fi'
import { useChangeDocumentClassificationLevelMutation, useDealsAffectedByDocumentLevelChangeLazyQuery } from '../../../graphql/codegen/hooks'
import { useTranslation } from 'react-i18next'
import AddDocToDeal from '../AddDocToDeal'
import Button from '../../Button'
import ComponentLoadingOverlay from '../../ComponentLoadingOverlay'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../TypeToConfirm'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

export default function ConvertDocumentLevel({
  closeModal,
  counterpartyId,
  deal_id,
  document_id,
  documentName,
  isAccountLevel,
  setModalLoading,
  setModalTitle
}: {
  closeModal: any
  counterpartyId?: string
  deal_id?: string
  documentName: string
  document_id: string
  isAccountLevel?: boolean
  setModalLoading?: any
  setModalTitle: any
}) {
  const [loading, setLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [mutationSuccess, setMutationSuccess] = useState<boolean>(false)
  const [confirmed, setConfirmed] = useState(false)
  const [selectedDeal, setSelectedDeal] = useState<any>('')
  const { t } = useTranslation()

  const [getAffectedCounts, { data: countsData, error: countsError, loading: countsLoading }] = useDealsAffectedByDocumentLevelChangeLazyQuery()

  const [changeDocumentLevel, { error: changeDocumentError, loading: changeDocumentLoading }] = useChangeDocumentClassificationLevelMutation({
    onCompleted: () => {
      setLoadingMessage('')
      setMutationSuccess(true)
    },
    update: (cache, { data }) => {
      if (data?.change_document_classification_level?.document) {
        const { id, is_counter_party_level, is_deal_level } = data.change_document_classification_level.document

        cache.modify({
          id: cache.identify({ __typename: 'AdvancedDashboardData', id }),
          fields: {
            is_counter_party_level: () => is_counter_party_level,
            is_deal_level: () => is_deal_level
          }
        })
      }
    }
  })

  const handleClose = () => {
    if (!loading) {
      closeModal()
    }
  }

  const handleSubmit = () => {
    if (!loading && document_id && (!isAccountLevel || deal_id || selectedDeal?.variables?.dealId)) {
      changeDocumentLevel({
        variables: {
          document_id,
          deal_id: isAccountLevel ? deal_id || selectedDeal?.variables?.dealId : null,
          classification_level: isAccountLevel ? ClassificationLevel.Deal : ClassificationLevel.CounterParty
        }
      })
    }
  }

  useEffect(() => {
    if (countsLoading) {
      setLoading(true)
      setLoadingMessage('')
    } else if (changeDocumentLoading) {
      setLoading(true)
      setLoadingMessage('Converting Document…')
    } else {
      setLoading(false)
      if (setModalLoading) {
        setModalLoading(false)
      }
    }
    // eslint-disable-next-line
    }, [countsLoading, changeDocumentLoading])

  useEffect(() => {
    if (isAccountLevel) {
      setModalTitle(`Convert to ${t('Deal')} Level`)
    } else {
      setModalTitle('Convert to Customer Level')
    }
    // eslint-disable-next-line
    }, [isAccountLevel])

  useEffect(() => {
    if (document_id) {
      getAffectedCounts({ variables: { document_id } })
    }
    // eslint-disable-next-line
    }, [])

  return (
    <>
      {(loading || !countsData) && <ComponentLoadingOverlay loading={loading} message={loadingMessage} />}

      <h5 style={{ textAlign: 'center' }}>{documentName}</h5>

      {mutationSuccess ? (
        <h5 style={{ textAlign: 'center' }}>Successfully converted document.</h5>
      ) : isAccountLevel && !deal_id ? (
        <>
          {!selectedDeal ? (
            <>
              <h5 style={{ margin: '8px 0' }}>Select destination {t('deal')}:</h5>

              <AddDocToDeal
                closeModal={closeModal}
                counterpartyId={counterpartyId}
                documentId={document_id}
                loading={loading}
                parentComponent={'ConvertDocumentLevel'}
                setLoading={setLoading}
                setLoadingMessage={setLoadingMessage}
                setSelectedDeal={setSelectedDeal}
              />
            </>
          ) : (
            !countsError &&
            !changeDocumentError && (
              <>
                <h5 style={{ margin: '8px 0' }}>Selected {t('Deal')}:</h5>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h6 style={{ marginLeft: '8px', maxWidth: '95%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {selectedDeal?.dealName}
                  </h6>

                  <Button
                    aria-label={`Clear selected ${t('deal')}`}
                    icon={<FiXSquare />}
                    onClick={() => setSelectedDeal('')}
                    style={{ marginLeft: '8px', borderRadius: '4px' }}
                  />
                </div>

                <AffectedCountsAndConfirm
                  confirmed={confirmed}
                  countsData={countsData}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  setConfirmed={setConfirmed}
                />
              </>
            )
          )}
        </>
      ) : (
        !countsError &&
        !changeDocumentError && (
          <AffectedCountsAndConfirm
            confirmed={confirmed}
            countsData={countsData}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            setConfirmed={setConfirmed}
          />
        )
      )}

      {countsError && <p>Error loading data: {countsError?.message}</p>}

      {changeDocumentError && <p>Error converting document: {changeDocumentError?.message}</p>}
    </>
  )
}

const AffectedCountsAndConfirm = ({
  confirmed,
  countsData,
  handleClose,
  handleSubmit,
  setConfirmed
}: {
  confirmed?: boolean
  countsData?: any
  handleClose?: any
  handleSubmit?: any
  setConfirmed?: any
}) => {
  const { t } = useTranslation()

  return (
    <>
      <p style={{ margin: '8px auto 8px auto', textAlign: 'center', minWidth: '602px' }}>
        Making this change will update field values for all active {t('deals')} with this customer.
      </p>

      <p style={{ margin: '0 auto 16px auto', textAlign: 'center', minWidth: '602px' }}>Completed {t('deals')} will be unaffected.</p>

      <p style={{ textAlign: 'center' }}>
        Active {t('deals')} to update: {countsData?.deals_affected_by_document_level_change?.active_deals}
      </p>

      <TypeToConfirm setConfirmed={setConfirmed} />

      <div className={css.modalButtonRow}>
        <Button onClick={handleClose} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content={confirmed ? '' : 'Type Confirm to proceed'}>
          <Button disabled={!confirmed} onClick={handleSubmit}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}
