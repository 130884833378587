import LoadingSkeleton from 'react-loading-skeleton'
import React from 'react'

export default function Skeleton() {
  return (
    <div>
      {new Array(5).fill(true).map((_, idx) => {
        return (
          <div key={`comment-skeleton-${idx}`} style={{ margin: 16 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LoadingSkeleton circle={true} height={50} width={50} />
              <span style={{ flex: 1, marginLeft: 16 }}>
                <LoadingSkeleton />
              </span>
            </div>
            <div>
              <LoadingSkeleton count={2}></LoadingSkeleton>
            </div>
          </div>
        )
      })}
    </div>
  )
}
