import * as queryString from 'query-string'
import { BulkExport } from '../../../components/ModalOptions/BulkExport'
import { BulkExportWithPdfs } from '../../../components/ModalOptions/BulkExport/BulkExportWithPdfs'
import { ColumnControls } from '../ColumnControls'
import { DataCompletenessCheck } from '../../../components/ModalOptions/DataCompletenessCheck'
import { DealList } from '../../../components/DealList'
import { DealSearchContainer } from '../../../containers/DealSearchContainer'
import { Features, Permissions, useUserAccess } from '../../../hooks/useUserAccess'
import { QueryTypes } from '../../../hooks/usePrevQueryType'
import { UploadModal } from '../../../components/UploadModal'
import { compareArrayOfStrings } from '../../../utils/stringUtils'
import { decodeQuery, encodeQuery } from '../../../utils/dashboardQueryUtils'
import { useDealsDashboardFieldsQuery, useDealsDashboardLazyQuery } from '../../../graphql/codegen/hooks'
import { useIsGoogleTenant } from '../../../hooks/useIsGoogleTenant'
import { useLocation } from 'react-router'
import Card from '../../../components/Card'
import DocumentUploadModal from '../../../components/DocumentUploadModal'
import FieldSelectionModal from '../../../components/ModalOptions/FieldSelectionModal'
import React, { FC, useCallback, useEffect, useReducer, useState } from 'react'
import css from './style.module.scss'
import dashboardQueryReducer, {
  getSavedColumns,
  initialState,
  parseTemporaryColumnsFromQuery,
  resetToDefaultColumns
} from '../../../reducers/dashboardQueryReducer'
import useDashboardQString from '../../../hooks/useDashboardQString'
import useGetExcludedFields from '../../../hooks/useGetExcludedFields'
import usePrevQuery from '../../../hooks/usePrevQuery'

// components

export const DealsTab: FC = () => {
  const dealsDashboardLazyQueryResult = useDealsDashboardLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
    // these fetch policies force the first query of the page to be from the network, then to prefer the cache afterwards.
    // this is needed in order to have the search re-fire when the search button is pressed.
  })

  const isGoogleTenantNonKlarityUser = useIsGoogleTenant({ isNonKlarityUser: true })
  const [{ selectedColumns }, dispatch] = useReducer(dashboardQueryReducer, initialState)
  const [isQueryActive, setIsQueryActive] = useState(false)
  const [isInitialRender, setIsInitialRender] = useState(true) // this is to prevent temp query columns from being overwritten with partially loaded data
  const [areColumnsLoaded, setAreColumnsLoaded] = useState(true) // this is to prevent temp query columns from being overwritten with partially loaded data
  const location = useLocation()
  const { data, loading: fieldsLoading } = useDealsDashboardFieldsQuery()
  let fieldsToExclude = useGetExcludedFields('Deals', 'FieldSelector')
  fieldsToExclude = [...fieldsToExclude, 'assignee']
  const savedColumns = getSavedColumns()
  const { queryColumns } = useDashboardQString()
  const prevQuery = usePrevQuery()
  const formattedQueryColumns = typeof queryColumns === 'string' ? queryColumns.split(',') : queryColumns
  const temporaryColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)
  const hasAutoUploadAccess = useUserAccess({ feature: Features.AUTOMATED_DOCUMENT, permission: Permissions.CREATE }) && !isGoogleTenantNonKlarityUser
  const hasManualUploadAccess = useUserAccess({ feature: Features.MANUAL_DOCUMENT, permission: Permissions.CREATE }) && !isGoogleTenantNonKlarityUser
  const hasBulkExportAccess = useUserAccess({ feature: Features.CUSTOMER_DATA, permission: Permissions.EXPORT })
  const hasBulkExportWithPdfsAccess = useUserAccess({ feature: Features.CUSTOMER_DATA_WITH_PDFS, permission: Permissions.EXPORT })
  const hasDataCompletenessAccess = useUserAccess({ feature: Features.DATA_COMPLETENESS, permission: Permissions.EXPORT })

  const [totalCount, setTotalCount] = useState(0)
  const [filteredCount, setFilteredCount] = useState(0)
  const [clearAll, setClearAll] = useState(false)

  const setDefaultFields = useCallback(() => {
    resetToDefaultColumns(data, [...fieldsToExclude, 'assignee'], dispatch)
  }, [data, fieldsToExclude])

  // update saved query if there is a search string, otherwise check if one exists to determine if isQueryActive is true
  useEffect(() => {
    const customerId = localStorage.getItem('customerId')

    if (location.search) {
      localStorage.setItem(`${customerId}__${QueryTypes.PREVIOUS_DEAL_QUERY}`, location.search)
      setIsQueryActive(true)
    } else if (localStorage.getItem(`${customerId}__${QueryTypes.PREVIOUS_DEAL_QUERY}`)) {
      setIsQueryActive(true)
    } else {
      setIsQueryActive(false)
    } // eslint-disable-next-line
  }, [location.key]); //listening for changes to the key catches when history changes but url does not- like when clear all is pressed while viewing a prev query

  // this useEffect either loads temporary columns from the search or prev query, and if not found loads saved columns or default columns if none are saved.
  useEffect(() => {
    if (!isInitialRender && !fieldsLoading) {
      const temporaryColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)
      if (temporaryColumns) {
        dispatch({ type: 'SAVE_TEMPORARY_COLUMNS', selectedColumns: temporaryColumns })
        setAreColumnsLoaded(true)
      } else if (savedColumns) {
        dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns: savedColumns })
        setAreColumnsLoaded(true)
      } else {
        setDefaultFields()
      }
    } else if (isInitialRender) {
      setIsInitialRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsLoading, JSON.stringify(queryColumns)])

  // this useEffect updates the stored previous query if new columns are selected or reordered so that those changes are persisted
  useEffect(() => {
    if (prevQuery && !fieldsLoading) {
      // this is to check that tempColumns are not still loading
      const tempColumnIds = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)?.map(column => column.value)
      const newColumnIds = selectedColumns?.map(column => column.value)

      // check for differences between prev and current (COLUMNS ONLY- adding/removing/updating rules is handled by hitting the search button)
      const arraysAreEqual = compareArrayOfStrings(tempColumnIds || [], newColumnIds || [])

      if (!arraysAreEqual && areColumnsLoaded) {
        const customerId = localStorage.getItem('customerId')

        // update the query with the new columns/order
        const prevQueryRules = prevQuery?.q ? decodeQuery(prevQuery?.q as string) : ''
        const cols = selectedColumns?.map(column => column.value)
        const updatedPrevQuery = queryString.stringify({ cols, q: encodeQuery(prevQueryRules) }, { arrayFormat: 'comma' })

        localStorage.setItem(`${customerId}__${QueryTypes.PREVIOUS_DEAL_QUERY}`, updatedPrevQuery)
      }
    }
    // eslint-disable-next-line
  }, [selectedColumns])

  return (
    <Card>
      <div className={css.tableHeader}>
        <DealSearchContainer
          clearAll={clearAll}
          filteredCount={filteredCount}
          selectedColumns={selectedColumns}
          setClearAll={setClearAll}
          totalCount={totalCount}
        />

        <div className={css.modalButtonWrapper}>
          <ColumnControls
            dispatch={dispatch}
            fieldsLoading={fieldsLoading}
            isQueryActive={isQueryActive}
            savedColumns={savedColumns}
            selectedColumns={selectedColumns}
            temporaryColumns={temporaryColumns}
          />

          {hasDataCompletenessAccess && <DataCompletenessCheck dashboardTab="Deals" dashboardType="DEAL" selectedColumns={selectedColumns} />}

          {hasBulkExportWithPdfsAccess ? (
            <BulkExportWithPdfs dashboard_type="DEAL" selectedColumns={selectedColumns} />
          ) : hasBulkExportAccess ? (
            <BulkExport dashboard_type="DEAL" selectedColumns={selectedColumns} />
          ) : null}

          <FieldSelectionModal
            dashboardTab={'Deals'}
            dispatch={dispatch}
            fieldsToHide={['assignee']}
            selectedColumns={selectedColumns}
            setDefaultFields={setDefaultFields}
          />

          {hasManualUploadAccess ? <UploadModal /> : hasAutoUploadAccess ? <DocumentUploadModal /> : null}
        </div>
      </div>

      <DealList
        dealsDashboardLazyQueryResult={dealsDashboardLazyQueryResult}
        dispatch={dispatch}
        selectedColumns={selectedColumns}
        setClearAll={setClearAll}
        setFilteredCount={setFilteredCount}
        setTotalCount={setTotalCount}
      />
    </Card>
  )
}
