import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import Modal from '../components/Modal'
import React, { useEffect, useState } from 'react'
import UserSelector from '../components/UserSelector'
import WithTooltip from '../components/WithTooltip'
import css from './style.module.scss'

export default function BulkEditAssigneeButton({
  bulkEditAssigneeMutation,
  entityName,
  resourceName,
  selectedFlatRows
}: {
  bulkEditAssigneeMutation: any
  entityName: 'Assignee' | 'Auditor'
  resourceName: 'document' | 'deal'
  selectedFlatRows: Row<any>[]
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)
  const [selected, setSelected] = useState<string | null | undefined>()
  const { t } = useTranslation()

  const { length } = selectedFlatRows

  const [mutation, { loading }] = bulkEditAssigneeMutation({
    onCompleted: () => {
      setSelected('')
      setIsOpen(false)
    }
  })

  useEffect(() => {
    const finalizedDealNames = selectedFlatRows
      ?.filter((row: Row) => {
        return row?.values?.state?.final
      }) // filter out non-final deals
      .map((row: Row) => {
        return row?.values?.document?.value
      }) // turn into array of final deal names

    if (finalizedDealNames.length > 0) {
      setIsDisabled(true)
      setDisabledMessage(
        <>
          <p>Finalized {t('deals')} cannot have their assignee changed.</p>

          <div>Please unselect the following {t('deals')}:</div>

          {finalizedDealNames.map((dealName, index) => (
            <div key={`${dealName} ${index}`}>{dealName}</div>
          ))}
        </>
      )
    } else {
      setIsDisabled(false)
      setDisabledMessage(undefined)
    }
  }, [selectedFlatRows, t])

  return (
    <>
      <WithTooltip content={disabledMessage} tippyClass={css.assigneeButtonTippyClass}>
        <Button disabled={isDisabled} onClick={() => setIsOpen(true)} variant="primary">{`Edit ${entityName}`}</Button>
      </WithTooltip>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title={`Edit ${entityName}`}>
        <div style={{ marginBottom: 8 }}>
          Edit {entityName} for {length} {resourceName === 'deal' ? t('deal') : resourceName}
          {length === 1 ? '' : 's'} to
        </div>

        <UserSelector onChange={({ value }: { value: string }) => setSelected(value)} withNegativeOption />

        <div style={{ display: 'flex', marginTop: 8 }}>
          <Button
            disabled={loading || selected === undefined}
            loading={loading}
            onClick={() =>
              mutation({
                variables: {
                  dealIds: selectedFlatRows.map((r: any) => r.values.document.id),
                  documentIds: selectedFlatRows.map((r: any) => r.values.document.id),
                  userId: selected
                }
              })
            }
          >
            {`${selected === null ? 'Unassign ' : 'Edit '}${entityName}`}
          </Button>
          <Button onClick={() => setIsOpen(false)} variant="secondary">{`Cancel`}</Button>
        </div>
      </Modal>
    </>
  )
}
