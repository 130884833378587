import { Box, IconButton } from '@mui/material'
import { DataTableEditor, _DataTableList } from '../dialogs/DataTableEditor'
import { Features, Permissions, useIsAnnotator, useUserAccess } from '../../hooks/useUserAccess'
import { FiChevronDown, FiChevronUp, FiDownload, FiZoomIn, FiZoomOut } from 'react-icons/fi'
import { GrDocumentPdf, GrDocumentVideo } from 'react-icons/gr'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { Opening } from '@hoologic/use-opening'
import { TablesQueryButton, TablesQueryResult } from './TablesQueryButton'
import { downloadDocument } from '../../utils/documentApiUtils'
import { grey } from '@mui/material/colors'
import { useConfidenceGlobalContext } from '../Confidence'
import Button from '../Button'
import Loader from '../Loader'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useGetAnnotations from '../../hooks/useGetAnnotations'
import useIsPreAnnot from '../../hooks/useIsPreAnnot'
import useQString from '../../hooks/useQString'

// types

type _ToolbarProps = {
  activeDocumentId: string | null
  areNovelSegmentsPresent: boolean
  currentPage: number
  dataTableList: _DataTableList
  documentFormat: string
  name?: string
  savedOpening: Opening
  setCurrentPage: Dispatch<SetStateAction<number>>
  setShowAllTags: Dispatch<SetStateAction<boolean>>
  showAllTags: boolean
  tablesQueryResult: TablesQueryResult
  toggleDocumentFormat: () => void
  totalPages: number
  v2: boolean
  zoom: (factor: number) => void
}

type _PageNavigationProps = { currentPage: number; totalPages: number }

// components

const Toolbar: FC<_ToolbarProps> = ({
  activeDocumentId,
  areNovelSegmentsPresent,
  currentPage,
  dataTableList,
  documentFormat,
  name,
  savedOpening,
  setCurrentPage,
  setShowAllTags,
  showAllTags,
  tablesQueryResult,
  toggleDocumentFormat,
  totalPages,
  v2,
  zoom
}: _ToolbarProps) => {
  const { isConfidenceVisibleMap, setIsConfidenceVisibleMap } = useConfidenceGlobalContext()
  const [dlLoading, setDlLoading] = useState(false)
  const isAnnotator = useIsAnnotator()
  const { isPreAnnot } = useIsPreAnnot()
  const { page } = useQString()
  const isDownloadEnabled = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.DOWNLOAD })

  const toggleConfidenceVisibility = useCallback(
    () => setIsConfidenceVisibleMap(current => ({ ...current, [activeDocumentId!]: !current[activeDocumentId!] })),
    [activeDocumentId, setIsConfidenceVisibleMap]
  )

  useEffect(() => {
    const viewer = window.PdfViewer?.viewer
    if (viewer && page) {
      // prevent crashing on initial load
      // this jumps to the page specified in url params (?documentTab=...blah&page=10)
      viewer.scrollPageIntoView({ pageNumber: Number(page) })
    }
    setCurrentPage(Number(page) || 1)
  }, [page, setCurrentPage, totalPages])

  const menuItemList: _MenuItem[] = useMemo(
    () => [
      { label: `${showAllTags ? 'Hide' : 'Show'} all tags`, onClick: () => setShowAllTags(prev => !prev) },
      { label: documentFormat === 'PDF' ? 'Show original document' : 'Show annotated document', onClick: toggleDocumentFormat },
      ...(areNovelSegmentsPresent
        ? [{ label: isConfidenceVisibleMap[activeDocumentId!] ? 'Hide novel segments' : 'Show novel segments', onClick: toggleConfidenceVisibility }]
        : [])
    ],
    [
      activeDocumentId,
      areNovelSegmentsPresent,
      documentFormat,
      isConfidenceVisibleMap,
      setShowAllTags,
      showAllTags,
      toggleConfidenceVisibility,
      toggleDocumentFormat
    ]
  )

  return (
    <Box className={css.toolbar}>
      {isAnnotator && (
        <Box sx={{ m: '0 auto 0 8px' }}>
          <MissingAnnotations />
        </Box>
      )}

      <Box sx={{ alignItems: 'center', display: 'flex', ml: 'auto' }}>
        {v2 && documentFormat === 'PDF' && !isPreAnnot && activeDocumentId && (
          <Box display="flex">
            <DataTableEditor dataTableList={dataTableList} documentId={activeDocumentId} savedOpening={savedOpening} />

            <TablesQueryButton tablesQueryResult={tablesQueryResult} totalPages={totalPages} />
          </Box>
        )}

        {isAnnotator && (
          <WithTooltip content={documentFormat === 'PDF' ? 'Show Original Document' : 'Show Scanned Document'}>
            <Box sx={{ borderRight: `1px solid ${grey[400]}`, mr: 0.5, pr: 0.5 }}>
              <IconButton
                aria-label={documentFormat === 'PDF' ? 'Show Original Document' : 'Show Scanned Document'}
                className={css.toolbarButton}
                onClick={e => {
                  e.stopPropagation()
                  toggleDocumentFormat()
                }}
                sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
              >
                {documentFormat === 'PDF' ? <GrDocumentPdf /> : <GrDocumentVideo />}
              </IconButton>
            </Box>
          </WithTooltip>
        )}

        {isDownloadEnabled && activeDocumentId && name && (
          <WithTooltip content={dlLoading ? 'Downloading File…' : 'Download File'}>
            <Box sx={{ borderRight: `1px solid ${grey[400]}`, pr: 0.5 }}>
              <IconButton
                aria-label={dlLoading ? 'Downloading File…' : 'Download File'}
                className={css.toolbarButton}
                onClick={async e => {
                  e.stopPropagation()
                  if (!dlLoading) {
                    setDlLoading(true)
                    await downloadDocument(activeDocumentId, name)
                    setDlLoading(false)
                  }
                }}
                sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
              >
                {dlLoading ? <Loader size="s" /> : <FiDownload />}
              </IconButton>
            </Box>
          </WithTooltip>
        )}

        {v2 && (
          <>
            {!!totalPages && (
              <Box className={css.toolbarPages}>
                <PageNavigation currentPage={currentPage} totalPages={totalPages} />
              </Box>
            )}

            <WithTooltip content="Zoom In">
              <IconButton
                aria-label="Zoom In"
                className={css.toolbarButton}
                onClick={e => {
                  e.stopPropagation()
                  zoom(1.1)
                }}
                sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
              >
                <FiZoomIn />
              </IconButton>
            </WithTooltip>

            <WithTooltip content="Zoom Out">
              <IconButton
                aria-label="Zoom Out"
                className={css.toolbarButton}
                onClick={e => {
                  e.stopPropagation()
                  zoom(0.9)
                }}
                sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
              >
                <FiZoomOut />
              </IconButton>
            </WithTooltip>

            <WithTooltip content="More Controls">
              <Box className={css.buttonWrapper}>
                <MoreMenu menuItemList={menuItemList} />
              </Box>
            </WithTooltip>
          </>
        )}
      </Box>
    </Box>
  )
}

const MissingAnnotations: FC = () => {
  const [currentItem, setCurrentItem] = useState(0)
  const annotations = useGetAnnotations()

  const missingAnnotations = useMemo(() => {
    if (!annotations) {
      return []
    } else {
      return annotations?.filter(anno => anno?.labelType === 'MISSING_ANNO')
    }
  }, [annotations])

  const uniquePageNumbers = useMemo(() => {
    const missing = missingAnnotations?.map(anno => {
      return anno?.position?.pageNumber
    })
    // @ts-ignore
    const uniquePageNumbers = [...new Set(missing)]
    return uniquePageNumbers?.sort((a, b) => {
      if (!a || !b) {
        return 0
      } else {
        return a - b
      }
    })
  }, [missingAnnotations])

  const handleClick = () => {
    const viewer = window.PdfViewer?.viewer
    const pageNumber = uniquePageNumbers?.[currentItem]
    if (viewer && pageNumber) {
      viewer.scrollPageIntoView({ pageNumber })
    }

    const nextItem = currentItem + 1
    setCurrentItem(uniquePageNumbers && currentItem === uniquePageNumbers?.length - 1 ? 0 : nextItem)
  }

  return (
    <>
      {uniquePageNumbers?.length > 0 && (
        <div className={css.missingAnnotations}>
          <p>{`Missing Annotations: ${missingAnnotations?.length}`}</p>
          <Button onClick={handleClick} size={'s'}>
            Jump to approximate page
          </Button>
        </div>
      )}
    </>
  )
}

export const PageNavigation: FC<_PageNavigationProps> = ({ currentPage, totalPages }) => {
  const [inputValue, setInputValue] = useState(currentPage)

  const handleClick = (direction: string) => {
    const viewer = window.PdfViewer?.viewer
    if (direction === 'up') {
      viewer?.scrollPageIntoView({ pageNumber: currentPage === 1 ? 1 : currentPage - 1 })
    } else {
      viewer?.scrollPageIntoView({ pageNumber: currentPage === totalPages ? totalPages : currentPage + 1 })
    }
  }

  const handleChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') updatePageView(e)
  }

  const updatePageView = (e: any) => {
    if (e.target.value >= 1 && e.target.value <= totalPages) {
      const viewer = window.PdfViewer?.viewer
      viewer?.scrollPageIntoView({ pageNumber: Number(e.target.value) })
    } else {
      setInputValue(currentPage)
    }
  }

  useEffect(() => {
    setInputValue(currentPage)
  }, [currentPage])

  if (!currentPage) return null

  return (
    <>
      <div>
        {/* Styling handled in .toolbarPages div:first-child */}
        <p>{`Page: `}</p>
        <input aria-label="Page number" onBlur={updatePageView} onChange={handleChange} onKeyDown={handleKeyDown} value={inputValue} />
        <p>{`of ${totalPages}`}</p>
      </div>

      <WithTooltip content="Page up">
        <IconButton
          aria-label="Page up"
          className={css.toolbarButton}
          onClick={() => handleClick('up')}
          sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
        >
          <FiChevronUp />
        </IconButton>
      </WithTooltip>

      <WithTooltip content="Page down">
        <IconButton
          aria-label="Page down"
          className={css.toolbarButton}
          onClick={() => handleClick('down')}
          sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
        >
          <FiChevronDown />
        </IconButton>
      </WithTooltip>
    </>
  )
}

export default Toolbar
