import { TFunction } from 'i18next'
import { countTruthyValues } from '../../utils/dataPointUtils'

export function formatResolutionMessage(dataPoint: any, t: TFunction<'translation', undefined>) {
  if (!dataPoint) return null

  const valueCount = countTruthyValues(dataPoint)
  const maybePluralDocs = 'document' + (valueCount === 1 ? '' : 's')

  // If the collision does not need to be resolved (we have to use false because otherwise the value can be true or null)
  if (!(dataPoint.is_collision_resolved === false)) {
    switch (dataPoint.data_point_field?.collision_type) {
      case 'CASCADE':
      case 'EQUIVALENCE':
        return `The “${dataPoint.data_point_field.name}” field was found in ${valueCount} ${maybePluralDocs} within this ${t(
          'deal'
        )}. Select one prevailing value.`
      case 'UNION':
        return `The “${dataPoint.data_point_field.name}” field was found in ${valueCount} ${maybePluralDocs} within this ${t(
          'deal'
        )}. Select a subset of the prevailing values if needed.`
      default:
        return null
    }
  }

  switch (dataPoint.data_point_field?.collision_type) {
    case 'CASCADE':
    case 'EQUIVALENCE':
      return `The “${dataPoint.data_point_field.name}” field was found in ${valueCount} ${maybePluralDocs} within this ${t(
        'deal'
      )} and all did not contain the same value. Select the prevailing value.`
    case 'UNION':
      return `The “${dataPoint.data_point_field.name}” field was found in ${valueCount} ${maybePluralDocs} within this ${t(
        'deal'
      )}. You may select a subset of the prevailing values if needed.`
    default:
      return null
  }
}

// for comparing sets of collision ids
export function areEqualSets(a: Set<string>, b: Set<string>) {
  if (a.size !== b.size) return false
  // @ts-ignore
  for (const x of a) {
    if (!b.has(x)) return false
  }
  return true
}
