import './scss/index.scss'
import { API_ROOT } from './utils/apiUtils'
import { Admin, WorkspaceApps } from './pages/Admin'
import { App, theme } from './app'
import { CurrentRuntimeEnvironment } from './utils/envUtils'
import { CurrentUserDocument } from './graphql/codegen/hooks'
import { ThemeProvider } from '@mui/material/styles'
import { captureError, initializeSentry, setSentryUsername } from './utils/sentry'
import { deleteCaches } from './utils/cacheUtils'
import { getCurrentUserInApolloFormat, getIdToken, logOut } from './utils/sessionApiUtils'
import { handleOktaLoginRedirectError, oktaClient, startOktaClient } from './utils/oktaClient'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

// functions

const initialize = async () => {
  initializeSentry()

  await startOktaClient()

  loadApp()

  window.addEventListener('beforeunload', deleteCaches)
}

const loadApp = async () => {
  await deleteCaches() // Ensure caches are deleted even if the 'beforeunload' event listener failed when the app was last closed.

  if (oktaClient.isLoginRedirect()) {
    try {
      const tokenData = await oktaClient.token.parseFromUrl()

      if (tokenData?.tokens) {
        oktaClient.tokenManager.setTokens(tokenData.tokens)

        const from = window.localStorage.getItem('from') || '/'

        window.localStorage.removeItem('from')

        return window.location.replace(from) // Redirect to previous route or dashboard.
      }
    } catch (error: unknown) {
      handleOktaLoginRedirectError(error)
    }
  }

  const currentCustomer = localStorage.getItem('customerId')
  const idToken = await getIdToken()
  const isLogoutRedirect = window.location.pathname === '/logout'
  const root = document.getElementById('revrec-app-root')

  // Render the admin page if the user is a super admin who has not yet selected a customer
  if (idToken && !currentCustomer && !isLogoutRedirect) {
    try {
      const { customers, is_super_admin } = (await axios.get(`${API_ROOT}/user/customers`, { headers: { Authorization: `Bearer: ${idToken}` } })).data

      if (is_super_admin) {
        ReactDOM.render(
          <ThemeProvider theme={theme}>
            <Admin />
          </ThemeProvider>,
          root,
          removeLoader
        )

        return
      }

      if (customers[0].workspace_app === WorkspaceApps.KLARITY_ARCHITECT) {
        window.location.href = `${CurrentRuntimeEnvironment.REACT_APP_ARCHITECT_URL}?customerId=${customers[0].id}`
      } else {
        localStorage.setItem('customerId', customers[0].id)
      }
    } catch (error) {
      captureError(error)

      logOut()
    }
  }

  const currentUserQuery = { query: CurrentUserDocument, data: { current_user: await getCurrentUserInApolloFormat() } }

  if (currentUserQuery.data.current_user?.user_name) {
    setSentryUsername(currentUserQuery.data.current_user?.user_name)
  }

  ReactDOM.render(<App currentUserQuery={currentUserQuery} />, root, removeLoader)
}

const removeLoader = () => {
  window.requestAnimationFrame(() => {
    const loader = document.getElementById('root-loader')

    if (!loader) {
      return
    }

    loader.addEventListener('transitionend', () => loader.remove())

    loader.classList.add('-remove')
  })
}

initialize()
