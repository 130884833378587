import { Box, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, ReactNode, useEffect, useState } from 'react'

// types

type _BooleanInputsProps = { handleData: (key: string, value: boolean) => void }
type _TooltipInfoProps = { tooltipTitle: NonNullable<ReactNode> }

// components

export const BooleanInputs: FC<_BooleanInputsProps> = ({ handleData }) => {
  const [defaultFieldOnDashboard, setDefaultFieldOnDashboard] = useState(false)
  const [displayAccountingImpact, setDisplayAccountingImpact] = useState(true)
  const [displayAnnotations, setDisplayAnnotations] = useState(true)
  const [displayIfEmpty, setDisplayIfEmpty] = useState(true)
  const [displayOnChecklist, setDisplayOnChecklist] = useState(true)
  const { t } = useTranslation()

  const handleChange = (key: string, value: boolean) => {
    switch (key) {
      case 'defaultFieldOnDashboard':
        handleData('default_field_on_dashboard', value)
        setDefaultFieldOnDashboard(value)
        break

      case 'displayAccountingImpact':
        handleData('display_accounting_impact', value)
        setDisplayAccountingImpact(value)
        break

      case 'displayAnnotations':
        handleData('display_annotations', value)
        setDisplayAnnotations(value)
        break

      case 'displayIfEmpty':
        handleData('display_if_empty', value)
        setDisplayIfEmpty(value)
        break

      case 'displayOnChecklist':
        handleData('display_on_checklist', value)
        setDisplayOnChecklist(value)

        if (value && !displayAnnotations) {
          handleData('display_annotations', true)
          setDisplayAnnotations(true)
        } else if (!value && displayAnnotations) {
          handleData('display_annotations', false)
          setDisplayAnnotations(false)
        }

        break

      default:
        console.error(`BooleanInputs handleChange error: Unsupported button name: ${key}`)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      handleData('default_field_on_dashboard', defaultFieldOnDashboard)
      handleData('display_accounting_impact', displayAccountingImpact)
      handleData('display_annotations', displayAnnotations)
      handleData('display_if_empty', displayIfEmpty)
      handleData('display_on_checklist', displayOnChecklist)
    }, 200)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography sx={{ color: grey[600], display: 'block', letterSpacing: 0.4, mt: 3 }} variant="overline">
        Display Settings
      </Typography>

      <Box>
        <FormControlLabel
          control={<Switch checked={!displayOnChecklist} onChange={event => handleChange('displayOnChecklist', !event.target.checked)} />}
          label={
            <Typography variant="body2">
              <>Always hide this field</>

              <TooltipInfo tooltipTitle="Toggle this option to hide this field from the checklist. This is useful for fields that are only used internally (e.g., as inputs for other fields)." />
            </Typography>
          }
        />
      </Box>

      <Box sx={{ ml: 6, my: 0.5 }}>
        {displayOnChecklist ? (
          <FormControlLabel
            control={<Switch checked={!displayIfEmpty} onChange={event => handleChange('displayIfEmpty', !event.target.checked)} />}
            label={<Typography variant="body2">Hide this field only when it has no value</Typography>}
          />
        ) : (
          <FormControlLabel
            control={<Switch checked={displayAnnotations} onChange={event => handleChange('displayAnnotations', event.target.checked)} />}
            label={
              <Typography variant="body2">
                <>Display annotation highlights</>

                <TooltipInfo tooltipTitle="When enabled, annotation highlights are visible to the user even if the field is hidden in the checklist. (Klarity staff can always see annotations for QA purposes.)" />
              </Typography>
            }
          />
        )}
      </Box>

      <Box sx={{ mb: 0.5 }}>
        <FormControlLabel
          control={<Switch checked={!displayAccountingImpact} onChange={event => handleChange('displayAccountingImpact', !event.target.checked)} />}
          label={<Typography variant="body2">Hide accounting impact button</Typography>}
        />
      </Box>

      <Box sx={{ mb: 0.5 }}>
        <FormControlLabel
          control={<Switch checked={defaultFieldOnDashboard} onChange={event => handleChange('defaultFieldOnDashboard', event.target.checked)} />}
          label={<Typography variant="body2">Display as default column in {t('Deals')} and Documents table views</Typography>}
        />
      </Box>
    </>
  )
}

const TooltipInfo: FC<_TooltipInfoProps> = ({ tooltipTitle }) => (
  <Tooltip arrow placement="right" title={tooltipTitle}>
    <InfoOutlinedIcon sx={{ color: grey[500], fontSize: 16, ml: 0.5, mt: 0.25, position: 'absolute' }} />
  </Tooltip>
)
