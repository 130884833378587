import { decodeQuery, formatDashboardQueryFilters, getQueryColumns } from '../utils/dashboardQueryUtils'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import formatMongoFilters from '../utils/formatMongoFilters'
import usePrevQuery from './usePrevQuery'
import useQString from './useQString'

const DEFAULT_GQL_FILTERS = { filters: '{}', chosenDataPointFieldIds: [], sortConfig: [], size: 10, offset: 0 }
const DEFAULT_HOOK_STATE = { queryBuilderQuery: null, gqlFilters: DEFAULT_GQL_FILTERS }

// Listen on qString, updating query in formats for react-querybuilder and also our gql backend
export default () => {
  const [state, setState] = useState<any>(DEFAULT_HOOK_STATE)
  const location = useLocation()
  const qString = useQString()
  const prevQuery = usePrevQuery()

  useEffect(() => {
    const queryString = location.search ? qString : prevQuery || ''
    const { cols, q, sortConfig } = queryString || {}

    const queryBuilderQuery = decodeQuery(q as string) || undefined
    const queryColumns = cols || getQueryColumns(q as string) || null // returning null on empty is important- other things parse this return, and JSON.parse(null) is fine, but parsing undefined or '' will error.
    const formattedSortConfig = sortConfig && JSON.parse(sortConfig as string).map((w: any) => JSON.stringify(w))
    // The gql backend requires filters in a very specific format
    // This formatter function stringifies certain fields and converts 'filters' from querybuilder format to mongo
    const gqlFilters = formatDashboardQueryFilters({
      ...DEFAULT_GQL_FILTERS,
      filters: queryBuilderQuery ? formatMongoFilters(queryBuilderQuery) : {},
      sortConfig: formattedSortConfig || []
    })

    return setState({ queryBuilderQuery, queryString: q, queryColumns, gqlFilters, sortConfig: sortConfig ? JSON.parse(sortConfig as string) : [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.key])

  return state
}

// Example of sortConfig:
// [
//   { sort_data_points: { '...': -1, '...': 1 } },
//   { sort_document_name: 1 },
//   { sort_customer_name: -1 },
//   { sort_status: 1 },
//   { sort_data_points: { 'ID_....': -1, 'ID_...': -1 } },
// ];
