import * as queryString from 'query-string'
import { Column } from '../reducers/dashboardQueryReducer'
import { RuleGroupType } from 'react-querybuilder'
import { encodeQuery, generateColumnsToSave } from './dashboardQueryUtils'

export const generateSortFunction = (
  history: any,
  qString: RuleGroupType,
  sortConfig: any,
  sortKey: string,
  selectedColumns: Array<Column>,
  datapointFieldId?: string
) => {
  return () => {
    // query strings must be generated any time columns change in order to add all the needed columns and in the correct order to the new search string that includes sort config
    // Also, columns must be re-generated if sort config changes.
    // base query (qString) is queryBuilderQuery aka Q
    // cols get generated by selected columns and by pulling columns from the rules inside Q
    // combine, stringify, and push.
    const cols = generateColumnsToSave(qString, selectedColumns)
    const sortDirection = generateSortDirection(sortConfig, sortKey, datapointFieldId)

    if (datapointFieldId) {
      history.push({
        search: queryString.stringify(
          {
            cols,
            q: encodeQuery(qString),
            sortConfig: JSON.stringify([{ sort_data_points: { [datapointFieldId]: sortDirection === 1 ? -1 : ((sortDirection + 1) as -1 | 0 | 1) } }])
          },
          { arrayFormat: 'comma' }
        )
      })
    } else {
      history.push({
        search: queryString.stringify(
          { cols, q: encodeQuery(qString), sortConfig: JSON.stringify([{ [sortKey]: sortDirection === 1 ? -1 : ((sortDirection + 1) as -1 | 0 | 1) }]) },
          { arrayFormat: 'comma' }
        )
      })
    }
  }
}

export const generateSortDirection = (sortConfig: any, sortKey: string, datapointFieldId?: string) => {
  return datapointFieldId
    ? sortConfig?.find((a: any) => a.sort_data_points)?.sort_data_points?.[datapointFieldId] || 0
    : sortConfig?.find((a: any) => a[sortKey])?.[sortKey] || 0
}

export const generateDashboardColumns = (
  history: any,
  qString: any,
  sortConfig: any,
  selectedColumns: Array<Column>,
  columns: { Cell?: any; accessor: string; disableSort?: boolean; draggable?: boolean; headerName: string; sortStringName?: string }[]
) => {
  return columns.map(column => {
    const { Cell, accessor, disableSort, draggable, headerName, sortStringName } = column
    return {
      Header: headerName,
      accessor,
      width: 240,
      draggable,
      Cell: Cell || (({ value }: any) => value?.value || null),
      sortFunction: disableSort ? undefined : generateSortFunction(history, qString, sortConfig, `sort_${sortStringName || accessor}`, selectedColumns),
      sortDirection: generateSortDirection(sortConfig, `sort_${sortStringName || accessor}`)
    }
  })
}
