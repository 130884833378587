import { ChatTab } from './ChatTab'
import { Check, Edit, MessageSquare, Paperclip } from 'react-feather'
import { ChecklistTab } from './ChecklistTab'
import { ChecklistTabAnnotatorFooter } from './ChecklistTabAnnotatorFooter'
import { DealAttachmentsTabContainer, DocumentAttachmentsTabContainer } from './AttachmentsTab'
import { Document } from '../../graphql/codegen/schemas'
import { Features, Permissions, useIsAnnotator, useUserAccess } from '../../hooks/useUserAccess'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import { TabWithTooltipOnDisable } from '../TabWithTooltipOnDisable'
import { debounce, isEmpty } from 'lodash'
import { useGetDealPageChatStatusQuery, useGetDocumentPageChatStatusQuery } from '../../app/restApi'
import { useIsAssignedToMe } from '../../hooks/useIsAssignedToMe'
import { useTranslation } from 'react-i18next'
import DataPointOverlay from '../DataPointOverlay'
import DealNotesTab from './DealNotesTab'
import DocumentNotesTab from './DocumentNotesTab'
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type DataPointPanelProps = {
  attachmentCount: number
  dealDocuments?: Document[]
  dealId?: string
  dealIsFinalized?: boolean
  documentId?: string
  noteCount: number
}

// hooks

const useActiveTab = (tabs: string[]): string => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState(tabs[0])

  useEffect(() => {
    const tab = pathname.split('/').pop()

    if (tab && tabs.includes(tab)) {
      setActiveTab(tab)
    }
  }, [pathname, tabs])

  return activeTab
}

// components

const DataPointPanel = ({ attachmentCount, dealDocuments, dealId, dealIsFinalized, documentId, noteCount }: DataPointPanelProps) => {
  const { path, url } = useRouteMatch()
  const [isDataPointOverlayOpen, setIsDataPointOverlayOpen] = useState(false)
  const [tabsKey, setTabsKey] = useState(0)
  const { t } = useTranslation()
  const activeTab = useActiveTab(['checklist', 'chat', 'notes', 'attachments'])
  const checklistPaths = [path, `${path}/checklist`, `${path}/checklist/:dataPointId`]
  const hasChatAccess = useUserAccess({ feature: Features.CHAT, permission: Permissions.READ })
  const headerRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const isAnnotator = useIsAnnotator()
  const isAssignedToMe = useIsAssignedToMe(documentId)
  const isChatTabVisible = hasChatAccess && !isAnnotator
  const panelRef = useRef<HTMLDivElement>(null)

  const {
    data: dealPageChatStatusByDocumentId,
    isError: isDealPageChatStatusError,
    isFetching: isDealPageChatStatusFetching
  } = useGetDealPageChatStatusQuery({ dealId: dealId || '' }, { skip: !dealId || !hasChatAccess })

  const {
    data: isDocumentPageChatAvailable,
    isError: isDocumentPageChatStatusError,
    isFetching: isDocumentPageChatStatusFetching
  } = useGetDocumentPageChatStatusQuery({ documentId: documentId || '' }, { skip: !documentId || !hasChatAccess })

  const isChatTabDisabled = Boolean(
    (dealId && (!dealPageChatStatusByDocumentId || isEmpty(dealPageChatStatusByDocumentId) || !Object.values(dealPageChatStatusByDocumentId).includes(true))) ||
      (documentId && !isDocumentPageChatAvailable)
  )

  const chatTabTooltipTitle =
    isDealPageChatStatusFetching || isDocumentPageChatStatusFetching
      ? 'Loading…'
      : isDealPageChatStatusError || isDocumentPageChatStatusError
      ? 'Chat is not currently available'
      : isChatTabDisabled
      ? `Chat is not currently available for this ${dealId ? t('deal') : 'document'}`
      : ''

  // Re-render Tabs when the left panel is resized.
  useEffect(() => {
    if (headerRef.current) {
      const resizeObserver = new ResizeObserver(debounce(() => setTabsKey(previousKey => previousKey + 1), 300))

      resizeObserver.observe(headerRef.current)

      return () => resizeObserver.disconnect()
    }
  }, [])

  // Make all non-overlay child elements inside panelRef unfocusable/untababble when the DataPointOverlay is open.
  useEffect(() => {
    const panelElement = panelRef.current

    if (!panelElement) return

    const tabIndexElements = Array.from(panelElement.querySelectorAll('[tabindex]'))
    const children = Array.from(panelElement.children) as HTMLElement[]

    if (isDataPointOverlayOpen) {
      children.filter(child => child.id !== 'dataPointOverlay').forEach(child => (child.style.cssText += 'visibility: hidden; pointer-events: none;'))

      tabIndexElements.filter(element => !element.closest('#dataPointOverlay')).forEach(element => element.setAttribute('tabindex', '-1'))
    } else {
      tabIndexElements.forEach(element => element.setAttribute('tabindex', '0'))

      children
        .filter(child => child.id !== 'dataPointOverlay')
        .forEach(child => {
          child.style.visibility = ''
          child.style.pointerEvents = ''
        })
    }
  }, [isDataPointOverlayOpen])

  return (
    <div className={clsx(css.panel, activeTab && css[activeTab])} ref={panelRef}>
      <div className={css.header} ref={headerRef}>
        <div className={css.topRow}>
          <Tabs key={tabsKey} onChange={(_, value) => history.replace(`${url}/${value}${history.location.search}`)} scrollButtons value={activeTab}>
            <Tab icon={<Check />} label={`${isAnnotator ? 'Customer ' : ''}Checklist`} tabIndex={isDataPointOverlayOpen ? -1 : 0} value="checklist" />

            {isChatTabVisible && (
              <TabWithTooltipOnDisable
                disabled={isChatTabDisabled}
                icon={<MessageSquare />}
                label="Chat"
                tabIndex={isDataPointOverlayOpen ? -1 : 0}
                tooltipProps={{ arrow: true, title: chatTabTooltipTitle }}
                value="chat"
              />
            )}

            <Tab
              icon={<Edit />}
              label={<>Notes {Boolean(noteCount) && <div className={css.badge}>{noteCount}</div>}</>}
              tabIndex={isDataPointOverlayOpen ? -1 : 0}
              value="notes"
            />

            <Tab
              icon={<Paperclip />}
              label={<>Attachments {Boolean(attachmentCount) && <div className={css.badge}>{attachmentCount}</div>}</>}
              tabIndex={isDataPointOverlayOpen ? -1 : 0}
              value="attachments"
            />
          </Tabs>
        </div>
      </div>

      <Switch>
        <Route path={[path, `${path}/:activeTab`]}>
          <Route path={`${path}/attachments`}>
            {dealId ? <DealAttachmentsTabContainer dealIsFinalized={dealIsFinalized} /> : <DocumentAttachmentsTabContainer />}
          </Route>

          <Route path={`${path}/notes`}>{dealId ? <DealNotesTab dealIsFinalized={dealIsFinalized || false} /> : <DocumentNotesTab />}</Route>

          <Route path={`${path}/chat`}>
            {isChatTabDisabled ? (
              <Redirect to={`${url}/checklist`} />
            ) : (
              <ChatTab
                dealDocuments={dealDocuments?.map(document => ({ ...document, isChatAvailable: Boolean(dealPageChatStatusByDocumentId?.[document.id]) }))}
              />
            )}
          </Route>

          <Route path={`${path}/checklist/:dataPointId`}>
            <DataPointOverlay dealIsFinalized={dealIsFinalized || false} setIsOpen={setIsDataPointOverlayOpen} />
          </Route>

          <Route exact path={checklistPaths}>
            <ChecklistTab dealId={dealId} dealIsFinalized={dealIsFinalized || false} documentId={documentId} />

            {isAssignedToMe && <ChecklistTabAnnotatorFooter />}
          </Route>
        </Route>
      </Switch>
    </div>
  )
}

export default DataPointPanel
