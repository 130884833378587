import { useDeleteDealMutation, useDocumentsAffectedByDealDeleteLazyQuery } from '../../../graphql/codegen/hooks'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import RadioButton from '../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../TypeToConfirm'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

export default function DeleteDeal({
  closeModal,
  deal_id,
  dealName,
  setLoadingMessage,
  setModalLoading
}: {
  closeModal: any
  dealName?: string
  deal_id?: string
  setLoadingMessage?: any
  setModalLoading?: any
}) {
  const [deleteDocuments, setDeleteDocuments] = useState(false)
  const [mutationSuccess, setMutationSuccess] = useState<boolean>(false)
  const [confirmed, setConfirmed] = useState(false)
  const { t } = useTranslation()

  const [getAffectedCounts, { data: documentsAffectedData, error: documentsAffectedError, loading: documentsAffectedLoading }] =
    useDocumentsAffectedByDealDeleteLazyQuery()
  const [deleteDeal, { error: deleteDealError, loading: deleteDealLoading }] = useDeleteDealMutation({
    onCompleted: () => {
      setLoadingMessage('')
      setMutationSuccess(true)
    },
    update(cache) {
      const normalizedId = cache.identify({ id: deal_id, __typename: 'AdvancedDashboardDocumentMeta' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const handleSubmit = () => {
    if (deal_id) {
      deleteDeal({ variables: { deal_id, delete_documents: deleteDocuments } })
    }
  }

  useEffect(() => {
    if (documentsAffectedLoading) {
      setModalLoading(true)
      setLoadingMessage('')
    } else if (deleteDealLoading) {
      setModalLoading(true)
      setLoadingMessage(`Deleting ${t('Deal')}…`)
    } else {
      setModalLoading(false)
      if (setModalLoading) {
        setModalLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [documentsAffectedLoading, deleteDealLoading])

  useEffect(() => {
    if (deal_id) {
      getAffectedCounts({ variables: { deal_id } })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <h5 style={{ textAlign: 'center' }}>{dealName}</h5>
      {mutationSuccess ? (
        <h5 style={{ textAlign: 'center' }}>Successfully deleted the {t('deal')}.</h5>
      ) : (
        !documentsAffectedError &&
        !deleteDealError && (
          <AffectedCountsAndConfirm
            confirmed={confirmed}
            deleteDocuments={deleteDocuments}
            docsToDelete={documentsAffectedData?.documents_affected_by_deal_delete?.documents_to_delete}
            docsToPreserve={documentsAffectedData?.documents_affected_by_deal_delete?.documents_to_preserve}
            handleClose={closeModal}
            handleSubmit={handleSubmit}
            setConfirmed={setConfirmed}
            setDeleteDocuments={setDeleteDocuments}
          />
        )
      )}

      {documentsAffectedError && <p>Error loading data: {documentsAffectedError?.message}</p>}

      {deleteDealError && (
        <p>
          Error deleting {t('deal')}: {deleteDealError?.message}
        </p>
      )}
    </>
  )
}

function AffectedCountsAndConfirm({
  confirmed,
  deleteDocuments,
  docsToDelete,
  docsToPreserve,
  handleClose,
  handleSubmit,
  setConfirmed,
  setDeleteDocuments
}: {
  confirmed?: boolean
  deleteDocuments?: boolean
  docsToDelete?: any
  docsToPreserve?: any
  handleClose?: any
  handleSubmit?: any
  setConfirmed?: any
  setDeleteDocuments?: any
}) {
  const { t } = useTranslation()

  const handleChange = (e: any) => {
    const actualValue = e?.target?.value === 'true'
    setDeleteDocuments(actualValue)
  }

  return (
    <>
      <div className={css.radioRow}>
        <h4>Also delete the {t('deal')}’s documents?</h4>
        <div>
          <RadioButton
            handleChange={handleChange}
            id={'deleteDocuments_true'}
            isSelected={!!deleteDocuments}
            label={'Yes'}
            name={'deleteDocuments'}
            style={{ marginRight: '16px' }}
            value={true}
          />
          <RadioButton
            handleChange={handleChange}
            id={'deleteDocuments_false'}
            isSelected={!deleteDocuments}
            label={'No'}
            name={'deleteDocuments'}
            value={false}
          />
        </div>
      </div>
      {deleteDocuments && (
        <>
          {(!docsToDelete || docsToDelete?.length === 0) && (!docsToPreserve || docsToPreserve?.length === 0) ? (
            <p style={{ fontWeight: 501 }}>This {t('deal')} doesn’t have any documents.</p>
          ) : docsToDelete?.length > 0 ? (
            <>
              <p style={{ fontWeight: 501 }}>{`These documents will be deleted:`}</p>
              {docsToDelete?.map((item: any, index: number) => {
                return (
                  <p key={item?.name + index} style={{ fontSize: '14px', textAlign: 'left' }}>
                    {item?.name}
                  </p>
                )
              })}
            </>
          ) : (
            <p style={{ fontWeight: 501 }}>{`No documents will be deleted.`}</p>
          )}
          {
            docsToPreserve?.length > 0 ? (
              <>
                <p style={{ margin: '16px 0', fontWeight: 501, fontSize: '15px' }}>
                  Documents that are customer level or belong to multiple {t('deals')} will be preserved.
                </p>

                <p style={{ fontWeight: 501 }}>These documents will not be deleted:</p>

                {docsToPreserve?.map((item: any, index: number) => (
                  <p key={item?.name + index} style={{ fontSize: '14px', textAlign: 'left' }}>
                    {item?.name}
                  </p>
                ))}
              </>
            ) : (
              <></>
            ) // 'no documents will be preserved', doesn't seem necessary
          }
        </>
      )}
      <p style={{ marginTop: '16px', textAlign: 'center' }}>Are you sure you want to delete this {t('deal')}?</p>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={handleClose} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={confirmed ? '' : 'Type Confirm to proceed'}>
          <Button disabled={!confirmed} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
