import { CopyableContentDialog } from '../dialogs/CopyableContentDialog'
import { ExpandedView, _ErrorValue } from '../dashboard/ErrorsCell'
import { IconButton, Tooltip } from '@mui/material'
import { isEmpty } from 'lodash'
import { useAreFailureNotificationsEnabled } from '../../hooks/useAreFailureNotificationsEnabled'
import { useDealsQuery, useDocumentsQuery } from '../../graphql/codegen/hooks'
import { useOpening } from '@hoologic/use-opening'
import NotificationsIcon from '@mui/icons-material/Notifications'
import React, { FC } from 'react'

// types

type _FailureNotificationsProps = { dealId?: string; documentId?: string }

// components

export const FailureNotifications: FC<_FailureNotificationsProps> = ({ dealId, documentId }) => {
  const areFailureNotificationsEnabled = useAreFailureNotificationsEnabled()
  const { data: dealData } = useDealsQuery({ skip: !dealId, variables: { id: dealId! } })
  const { data: documentData } = useDocumentsQuery({ skip: !documentId, variables: { documentId } })
  const opening = useOpening()

  if (!areFailureNotificationsEnabled || (!dealId && !documentId)) return null

  const errors: _ErrorValue[] =
    (dealId
      ? dealData?.deals?.edges?.[0]?.node?.errors?.edges?.map(edge => edge?.node)
      : documentData?.documents?.edges?.[0]?.node?.errors?.edges?.map(edge => edge?.node)
    )?.filter((error): error is _ErrorValue => !!error) || []

  if (isEmpty(errors)) return null

  const text = errors.map(error => error?.error_message).join('\n\n')

  return (
    <>
      <Tooltip arrow placement="top" title="Failure notifications">
        <IconButton color="error" onClick={opening.open} size="small">
          <NotificationsIcon />
        </IconButton>
      </Tooltip>

      <CopyableContentDialog expandedView={<ExpandedView errors={errors} />} header="Errors" opening={opening} text={text} />
    </>
  )
}
