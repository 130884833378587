import { useCallback, useEffect, useState } from 'react'

export interface AsyncState<T> {
  data?: T
  error?: Error | any
  loading: boolean
}

const useAsync = <T>(fn: () => Promise<T>, args: any[] = []) => {
  const [state, setState] = useState<AsyncState<T>>({
    loading: true
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoized = useCallback(fn, args)

  useEffect(() => {
    setState({
      loading: true
    })

    memoized().then(
      data => {
        setState({
          loading: false,
          data
        })
      },
      error =>
        setState({
          loading: false,
          error
        })
    )
  }, [memoized])

  return state
}

export default useAsync
