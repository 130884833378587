import { codegenApi } from './codegenApi'

// constants

export const TAGS = {
  cciRolesWithFeatures: 'CCI Roles With Features',
  cciUsers: 'CCI Users',
  user: 'User'
}

export const api = codegenApi.enhanceEndpoints({
  addTagTypes: Object.values(TAGS),
  endpoints: {
    cciRolesWithFeatures: {
      providesTags: [TAGS.cciRolesWithFeatures]
    },
    cciUsers: {
      providesTags: [TAGS.cciUsers]
    },
    currentUser: {
      providesTags: [TAGS.user]
    }
  }
})

export const { useCciRolesWithFeaturesQuery, useCciUsersQuery, useCurrentUserQuery } = api
