import { SPREADSHEET_FILE_MIME_TYPES, convertBase64ToBlob, createFileFromBlob, getSpreadsheetFileExtension } from '../../utils/fileUtils'
import { SpreadsheetFileViewer } from './SpreadsheetFileViewer'
import { captureError } from '../../utils/sentry'
import { useAppContext } from '../../app'
import Loader from '../Loader/Loader'
import React, { FC, useEffect, useState } from 'react'
import axiosClient from '../../utils/axiosClient'

// types

type SpreadsheetDocumentWrapperProps = { documentId: string; documentName: string }

// functions

export const getSpreadsheetDocumentFile = async (documentId: string, documentName: string): Promise<File | null> => {
  const fileExtension = getSpreadsheetFileExtension(documentName)

  if (!fileExtension) {
    throw new Error('Unsupported file type')
  }

  const url = `/document/${documentId}?type=${fileExtension.toUpperCase()}`
  const response = await axiosClient(url)

  const base64Data = response.data.data
  const mimeType = SPREADSHEET_FILE_MIME_TYPES[fileExtension]

  const blob = convertBase64ToBlob(base64Data, mimeType)

  return createFileFromBlob(blob, documentName)
}

// components

export const SpreadsheetDocumentWrapper: FC<SpreadsheetDocumentWrapperProps> = ({ documentId, documentName }) => {
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  useEffect(() => {
    ;(async () => {
      if (!documentId) return

      try {
        const documentFile = await getSpreadsheetDocumentFile(documentId, documentName)

        setFile(documentFile)
      } catch (error: unknown) {
        captureError(error)

        const { message, stack } = error as Error

        setErrorMessage(message)
        setExtendedErrorMessage(stack || message)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [documentId, documentName, setErrorMessage, setExtendedErrorMessage])

  return isLoading ? (
    <Loader style={{ borderTop: '1px solid #e0e0e0', background: 'white', height: '100%' }} />
  ) : file ? (
    <SpreadsheetFileViewer file={file} id={documentId} />
  ) : null
}
