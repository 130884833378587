import React from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

export default function ComponentLoadingOverlay({ loading, message, style }: { loading: boolean; message?: string; style?: any }) {
  return (
    <div className={clsx(css.rootLoader, css.loadingOverlay, loading && css.visible)} style={style}>
      <div></div>
      {message && <p>{message}</p>}
    </div>
  )
}
