import { formatDate, formatTimeAgo } from '../../../../utils/datetimeUtils'
import { useLatestStateAuditsLazyQuery } from '../../../../graphql/codegen/hooks'
import { useTranslation } from 'react-i18next'
import Button from '../../../Button'
import React, { useEffect, useState } from 'react'
import Tippy from '@tippyjs/react'
import css from './style.module.scss'

export default function LifecycleAudits({
  dealId,
  documentId,
  setIsOpen,
  setModalLoading,
  setModalLoadingMessage,
  workflowId
}: {
  dealId?: string
  documentId?: string
  setIsOpen: any
  setModalLoading?: any
  setModalLoadingMessage?: any
  workflowId?: string
}) {
  const [getStateAudits, { data: stateAuditsData, loading: stateAuditsLoading }] = useLatestStateAuditsLazyQuery()
  const [sortedArray, setSortedArray] = useState<any>(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (workflowId && (dealId || documentId) && !(dealId && documentId)) {
      getStateAudits({ variables: { workflowId, dealId, documentId, all_state_audits: true } })
    }
    // eslint-disable-next-line
  }, [workflowId, dealId, documentId])

  useEffect(() => {
    if (stateAuditsData) {
      const arrayData = stateAuditsData?.latest_state_audits ? [...stateAuditsData?.latest_state_audits] : [] // @ts-ignore
      setSortedArray(arrayData?.sort((a, b) => new Date(a?.audit?.created_at) - new Date(b?.audit?.created_at)))
    }
    // eslint-disable-next-line
  }, [stateAuditsData])

  useEffect(() => {
    if (stateAuditsLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Loading Lifecycle Audit logs…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
  }, [stateAuditsLoading])

  return (
    <>
      {!stateAuditsLoading && (
        <>
          <div className={css.auditContainer} tabIndex={0}>
            {sortedArray?.map((audit: any, index: number) => {
              const { created_at, first_name, last_name, new_value, old_value } = audit?.audit || {}
              return (
                <div className={css.changeLogItem} key={`${audit?.id}${index}`}>
                  <span>{`${first_name} ${last_name} moved the ${dealId ? t('deal') : 'document'} from ${old_value} to ${new_value} `}</span>
                  <Tippy content={`${formatDate(created_at)}`}>
                    <span>
                      {formatTimeAgo(created_at)}
                      {` ago`}
                    </span>
                  </Tippy>
                </div>
              )
            })}
            {(!sortedArray || sortedArray?.length === 0) && <p style={{ textAlign: 'center' }}>{`No changes to Lifecycle found.`}</p>}
          </div>
          <div className={css.modalButtonRow} style={{ maxWidth: '65%', margin: '32px auto 0 auto' }}>
            <Button
              onClick={() => {
                setIsOpen(false)
              }}
              variant={'secondary'}
            >{`Close`}</Button>
          </div>
        </>
      )}
    </>
  )
}
