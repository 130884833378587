import Button from '../Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../TypeToConfirm'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

export default function ConfirmMoveDocumentModal({ onClose, onSubmit }: { onClose: () => void; onSubmit: () => void }) {
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    setIsDisabled(!confirmed)
  }, [confirmed])

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>Confirm Submission</h2>

      <p style={{ margin: '8px auto 0', maxWidth: '600px', textAlign: 'center' }}>
        The document contains sections that have not been completed in the Section Annotation tool.
      </p>

      <p style={{ margin: '8px auto 0', maxWidth: '600px', textAlign: 'center' }}>
        Please open the Miniapps and filter by document URL to check which queues have not been completed before confirming your submission below.
      </p>

      <TypeToConfirm setConfirmed={setConfirmed} />

      <div className={css.modalButtonRow}>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content="Type Confirm to proceed">
          <Button disabled={isDisabled} onClick={onSubmit}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}
