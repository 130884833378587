import { ArrowLeft, Download } from 'react-feather'
import { BasePDFDoc } from '../PDFDoc'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { SPREADSHEET_FILE_EXTENSIONS, TEXT_FILE_EXTENSIONS, createFileFromBlob } from '../../utils/fileUtils'
import { SpreadsheetFileViewer } from '../SpreadsheetViewer'
import { TextFileViewer, getTextDocumentContent } from '../TextFileViewer'
import { Typography } from '@mui/material'
import { getSpreadsheetDocumentFile } from '../SpreadsheetViewer/SpreadsheetDocumentWrapper'
import { useDocumentNameLazyQuery } from '../../graphql/codegen/hooks'
import { useKeyPressed } from '../../hooks/useKeyPressed'
import Loader from '../Loader'
import React, { FC, useEffect, useState } from 'react'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useAsync from '../../hooks/useAsync'

// types

type _DashboardPreviewOverlayProps = { fileId?: string; fileName?: string; handleClose?: () => void; isDocument?: boolean }

// components

export const DashboardPreviewOverlay: FC<_DashboardPreviewOverlayProps> = ({ fileId = '', fileName = '', handleClose, isDocument }) => {
  const [loading, setLoading] = useState(false)
  const [tooltipContent, setTooltipContent] = useState('')

  const hasAttachmentDownloadAccess = useUserAccess({ feature: Features.ATTACHMENT, permission: Permissions.DOWNLOAD })
  const hasDocumentDownloadAccess = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.DOWNLOAD })

  const isEscapePressed = useKeyPressed(event => event.key === 'Escape')

  const isPdf = fileName?.slice(-4) === '.pdf'
  const isSpreadsheet = Boolean(fileName.match(SPREADSHEET_FILE_EXTENSIONS))
  const isTxt = Boolean(fileName.match(TEXT_FILE_EXTENSIONS))

  const [getDocumentName, { data: documentNameData }] = useDocumentNameLazyQuery()

  const { data, error } = useAsync(async () => {
    if (!fileId) return null

    if (isDocument && (isSpreadsheet || isTxt)) {
      return isSpreadsheet ? await getSpreadsheetDocumentFile(fileId, fileName) : await getTextDocumentContent(fileId)
    } else {
      // For legacy reasons, the `attachments` endpoint works for both Documents and Attachments for non-spreadsheet and non-text files.
      const { data } = await axiosClient.get(`attachments/${fileId}?preview=1`, { responseType: 'blob' })

      return isTxt ? await data.text() : isSpreadsheet ? createFileFromBlob(data, fileName) : URL.createObjectURL(data)
    }
  }, [fileId, fileName, isDocument, isSpreadsheet, isTxt])

  // functions

  const handleDownload = async () => {
    const fileExtension = fileName.split('.').pop()?.toUpperCase()

    const documentDownloadUrl = `/document/${fileId}?type=${isSpreadsheet || isTxt ? fileExtension : 'ORIGINAL_PDF'}`
    const attachmentDownloadUrl = `attachments/${fileId}`

    setLoading(true)

    const data = await axiosClient
      .get(`${isDocument ? documentDownloadUrl : attachmentDownloadUrl}`, {
        responseType: isDocument ? undefined : 'blob',
        onDownloadProgress(progressEvent) {
          const total = progressEvent.total ?? 0
          const progress = total ? Math.round((progressEvent.loaded / total) * 100) : 0

          setTooltipContent(`Downloading File: ${progress}%`)
        }
      })
      .then(response => {
        setLoading(false)
        setTooltipContent('')

        return isDocument ? response?.data?.data : URL.createObjectURL(response?.data)
      })
      .catch(error => {
        setLoading(false)
        setTooltipContent('')

        return error
      })

    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = data
    a.download =
      isSpreadsheet || isTxt ? fileName : fileName ? fileName.split('.')[0] + '.pdf' : documentNameData?.documents?.edges[0]?.node?.name?.split('.')[0] + '.pdf'

    document.body.appendChild(a)
    a.click()
  }

  // effects

  useEffect(() => {
    if (!fileName) getDocumentName({ variables: { document_id: fileId } })
  }, [fileName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEscapePressed) handleClose?.()
  }, [handleClose, isEscapePressed])

  // render

  return (
    <div className={css.overlay}>
      <div className={css.header}>
        <WithTooltip content="Close Preview" style={{ cursor: 'pointer' }}>
          <span onClick={handleClose}>
            <ArrowLeft />
          </span>
        </WithTooltip>

        {fileName || (documentNameData?.documents?.edges[0]?.node?.name && <span>{fileName || documentNameData?.documents?.edges[0]?.node?.name}</span>)}

        {fileId &&
          (fileName || documentNameData?.documents?.edges[0]?.node?.name) &&
          ((isDocument && hasDocumentDownloadAccess) || (!isDocument && hasAttachmentDownloadAccess)) && (
            <WithTooltip content={tooltipContent || loading ? 'Downloading File…' : 'Download File'} style={{ cursor: 'pointer' }}>
              {loading ? <Loader variant="light" /> : <Download onClick={handleDownload} />}
            </WithTooltip>
          )}
      </div>

      <div className={css.attachmentWrapper}>
        {error && <Typography sx={{ color: 'white' }}>An error occurred. This file format may not be supported for preview.</Typography>}

        {!error && !data && <Loader variant="light" />}

        {data &&
          (isSpreadsheet ? (
            <SpreadsheetFileViewer file={data} id={fileId} />
          ) : isTxt ? (
            <TextFileViewer textContent={data} />
          ) : isPdf || isDocument ? (
            <BasePDFDoc data={data} error={error} loading={loading} />
          ) : (
            <img alt={fileName} src={data} />
          ))}
      </div>
    </div>
  )
}
