/* eslint-disable react/prop-types */
import { BsArrowsMove } from 'react-icons/bs'
import React, { CSSProperties, forwardRef } from 'react'
import clsx from 'clsx'
import styles from './Draggable.module.scss'
import type { DraggableSyntheticListeners, Translate } from '@dnd-kit/core'

export enum Axis {
  All,
  Vertical,
  Horizontal
}

interface Props {
  axis?: Axis
  content?: any
  dragOverlay?: boolean
  dragging?: boolean
  handle?: boolean
  label?: string
  listeners?: DraggableSyntheticListeners
  style?: CSSProperties
  translate?: Translate
}

export const Draggable = forwardRef<HTMLButtonElement, Props>(function Draggable(
  { content, dragOverlay, dragging, handle, label, listeners, translate, ...props },
  ref
) {
  return (
    <div
      className={clsx(styles.Draggable, dragOverlay && styles.dragOverlay, dragging && styles.dragging, handle && styles.handle)}
      style={
        {
          '--translate-x': `${translate?.x ?? 0}px`,
          '--translate-y': `${translate?.y ?? 0}px`
        } as CSSProperties
      }
    >
      <button
        ref={ref}
        {...props}
        aria-label={label || 'draggable modal'}
        {...(handle ? {} : listeners)}
        className={styles.draggableButton}
        tabIndex={handle ? -1 : undefined}
      >
        <BsArrowsMove style={{ color: 'white', fontSize: 18 }} />
      </button>
      {content ? <>{content}</> : null}
    </div>
  )
})
