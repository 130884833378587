// Example: @[name@email.com](graphqlId=)
const mentionRegex = /(@\[([^\s@]+@[^\s@]+\.[^\s@]+)\]\((.*?=)\))/

export function formatMentions(comment: string): string {
  const replaced = comment.replace(mentionRegex, '[@$3]')
  const hasMore = mentionRegex.test(replaced)

  if (hasMore) {
    return formatMentions(replaced)
  }
  return replaced
}

// Example: [@graphqlId=]
const klarityMentionRegex = /\[@(.*?=)\]/

export function formatCommentText(commentText: string, users: any[]): string {
  const [klarityMention, userId] = commentText.match(klarityMentionRegex) || []
  if (userId && klarityMention) {
    const userName = users?.filter((u: any) => u.id === userId)?.[0]?.display
    return formatCommentText(commentText.replace(klarityMention, `<span>${userName}</span>`), users)
  }

  return commentText
}
