import { Box, Tooltip } from '@mui/material'
import { CopyableContentDialog } from '../dialogs/CopyableContentDialog'
import { ExpandButton } from '../DatapointInput/EnhancedTextField/EnhancedTextField'
import { grey } from '@mui/material/colors'
import { useOpening } from '@hoologic/use-opening'
import { useOverflowDetection } from '../../hooks/useOverflowDetection'
import LanguageIcon from '@mui/icons-material/Language'
import React, { FC, ReactNode, SyntheticEvent, useRef, useState } from 'react'

// types

type _CellViewProps = {
  children?: ReactNode
  expandedView?: ReactNode
  header: string
  isCustomerLevelDocument?: boolean
  menu?: ReactNode
  textValue: string
  title?: string
}

// constants

const EXPAND_VIEW_ICON_DISPLAY_DELAY = 667

// components

export const CellView: FC<_CellViewProps> = ({ children, expandedView, header, isCustomerLevelDocument = false, menu, textValue, title }) => {
  const opening = useOpening()
  const displayValueRef = useRef(null)
  const isHoveringRef = useRef(false)
  const { isOverflowingHorizontally } = useOverflowDetection(displayValueRef)
  const [isExpandedViewIconShowing, setIsExpandedViewIconShowing] = useState(false)

  if (!textValue) return null

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault()

    handleMouseLeave()

    opening.open()
  }

  const handleMouseEnter = () => {
    if (opening.isOpen) return

    isHoveringRef.current = true

    setTimeout(() => {
      if (isHoveringRef.current && isOverflowingHorizontally) {
        setIsExpandedViewIconShowing(true)
      }
    }, EXPAND_VIEW_ICON_DISPLAY_DELAY)
  }

  const handleMouseLeave = () => {
    if (opening.isOpen) return

    setIsExpandedViewIconShowing(false)

    isHoveringRef.current = false
  }

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={{ px: 1.5, py: 1 }}>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        {menu && <Box sx={{ ml: -1.5, my: -1.25 }}>{menu}</Box>}

        {isCustomerLevelDocument && (
          <Tooltip arrow placement="top" title="Customer-level Document">
            <LanguageIcon sx={{ color: grey[600] }} />
          </Tooltip>
        )}

        <Box ref={displayValueRef} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', p: 0.25, ...(isCustomerLevelDocument && { ml: 1 }) }} title={title}>
          {children || textValue}
        </Box>
      </Box>

      <Box
        key={String(isHoveringRef.current)} // Re-render to ensure proper handling of focus/hover states.
        sx={{
          background: 'white',
          display: isOverflowingHorizontally ? 'block' : 'none',
          opacity: isExpandedViewIconShowing ? 1 : 0,
          pointerEvents: isExpandedViewIconShowing ? 'auto' : 'none',
          position: 'absolute',
          px: 1,
          py: 0.25,
          right: 0,
          top: 0,
          '&::before': {
            background: `linear-gradient(to right, transparent, white)`,
            content: '""',
            height: '100%',
            left: -6,
            position: 'absolute',
            top: 0,
            width: 6
          }
        }}
      >
        <ExpandButton
          iconButtonProps={{
            onFocusVisible: () => isOverflowingHorizontally && setIsExpandedViewIconShowing(true),
            onBlur: () => !isHoveringRef.current && setIsExpandedViewIconShowing(false),
            tabIndex: 0
          }}
          onClick={handleClick}
        />
      </Box>

      {opening.isOpen && <CopyableContentDialog expandedView={expandedView} header={header} opening={opening} text={textValue} />}
    </Box>
  )
}
