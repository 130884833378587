import { Box, ToggleButton, Tooltip, Typography } from '@mui/material'
import { Z_INDEX_1, Z_INDEX_HEADER, Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { captureError } from '../../utils/sentry'
import Loader from '../Loader/Loader'
import React, { FC, useEffect, useState } from 'react'
import WrapTextIcon from '@mui/icons-material/WrapText'
import axiosClient from '../../utils/axiosClient'

// types

// When `textContent` is supplied, it takes precedence over fetching a document by `documentId`.
type _TextFileViewerProps = { documentId?: string; textContent?: string }

// constants

const TOOLBAR_HEIGHT = 48

const TOOLBAR_SX = {
  alignItems: 'center',
  bgcolor: 'white',
  boxShadow: '0 0.125em 0.313em rgba(50, 50, 93, 0.09), 0 0.063em 0.125em rgba(0, 0, 0, 0.07)',
  display: 'flex',
  justifyContent: 'flex-end',
  minHeight: TOOLBAR_HEIGHT,
  position: 'relative',
  px: 1,
  zIndex: Z_INDEX_HEADER
}

// functions

export const getTextDocumentContent = async (documentId: string): Promise<string> => {
  const { data } = (await axiosClient.get(`/document/${documentId}?type=TXT`)).data

  const base64Content = data.includes('base64,') ? data.split('base64,')[1] : data

  return window.atob(base64Content)
}

// components

export const TextFileViewer: FC<_TextFileViewerProps> = ({ documentId, textContent }) => {
  const [content, setContent] = useState(textContent || '')
  const [isLoading, setIsLoading] = useState(true)
  const [isTextWrapped, setIsTextWrapped] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        if (documentId) {
          const textDocumentContent = await getTextDocumentContent(documentId)

          setContent(textDocumentContent)
        }
      } catch (error) {
        captureError(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [documentId])

  return (
    <>
      <Box sx={TOOLBAR_SX}>
        <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow placement="left" title="Toggle text wrap">
          <ToggleButton aria-label="Toggle text wrap" onChange={() => setIsTextWrapped(!isTextWrapped)} selected={isTextWrapped} size="small" value="wrap">
            <WrapTextIcon />
          </ToggleButton>
        </Tooltip>
      </Box>

      <Box sx={{ bgcolor: 'white', display: 'flex', flexDirection: 'column', height: `calc(100% - ${TOOLBAR_HEIGHT}px)`, overflow: 'auto' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', height: '-webkit-fill-available', width: '100%', zIndex: Z_INDEX_1 }}>
            <Loader />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', p: 2 }}>
            <Typography component="pre" sx={{ fontFamily: 'monospace', lineHeight: 1.15, textAlign: 'left', whiteSpace: isTextWrapped ? 'pre-wrap' : 'pre' }}>
              {content}
            </Typography>

            {/* Hack to add padding to the right of unwrapped text. */}
            {!isTextWrapped && <Box sx={{ pr: 2 }} />}
          </Box>
        )}
      </Box>
    </>
  )
}
