import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Accordions, useCciMainContext } from '../../../../../../CCI_Main'
import { AdditionalInfoInput } from '../../EditInputs/AdditionalInfoInput'
import { BooleanInputs } from '../../EditInputs/BooleanInputs'
import { CollisionTypeInput } from '../../SharedInputs/CollisionTypeInput'
import { DataTableMatchRules, MatchRule } from '../../SharedInputs/MatchRule'
import { DefaultValueInput } from '../../EditInputs/DefaultValueInput'
import { ExtractionFieldTypeInput } from '../../SharedInputs/ExtractionFieldTypeInput'
import { Features, Permissions, useUserAccess } from '../../../../../../../../hooks/useUserAccess'
import { FieldOptions } from '../../EditInputs/FieldOptions'
import { MatchFields } from '../../SharedInputs/MatchFields'
import { MatchPrompt } from '../../SharedInputs/MatchPrompt'
import { ResolutionStrategyInput } from '../../SharedInputs/ResolutionStrategyInput'
import { Sources } from '../../CreateInputs/SourceInput'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FieldTypeInput from '../../EditInputs/FieldTypeInput'
import React, { FC } from 'react'

// types

type _FieldConfigurationAccordionProps = { handleData: (key: string, value: any) => void; values?: any }

// components

export const FieldConfigurationAccordion: FC<_FieldConfigurationAccordionProps> = ({ handleData, values }) => {
  const { expandedAccordionMap, selectedItem, toggleAccordion } = useCciMainContext()

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  return (
    <Accordion
      disableGutters
      expanded={Boolean(expandedAccordionMap[Accordions.FIELD_CONFIGURATION])}
      onChange={() => toggleAccordion(Accordions.FIELD_CONFIGURATION)}
      sx={{
        borderBottom: '1px solid #e0e0e0',
        boxShadow: 'none',
        m: '0 -20px',
        '&:before': { bgcolor: '#e0e0e0', opacity: 1 },
        '&.Mui-expanded:before': { bgcolor: '#e0e0e0', opacity: 1 }
      }}
    >
      <AccordionSummary aria-controls="field-configuration-content" expandIcon={<ExpandMoreIcon />} id="field-configuration-header">
        <Typography sx={{ p: 0.5 }} variant="h4">
          Field Configuration
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ pb: 2.5, pt: 2, px: 2.5 }}>
        <FieldTypeInput
          currentValue={selectedItem?.field_type || ''}
          handleData={handleData}
          isReadOnly={!hasFullEditAccess || selectedItem?.source === Sources.MATCHING}
          selectedValue={values?.field_type || ''}
        />

        <ExtractionFieldTypeInput
          handleData={handleData}
          source={values?.source || selectedItem?.source}
          value={values?.extraction_field_type || selectedItem?.extraction_field_type || null}
        />

        <FieldOptions
          currentDefaultValue={selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.default_value : ''}
          currentFieldType={selectedItem?.field_type || ''}
          currentItemId={selectedItem?.id}
          currentOptions={selectedItem?.options}
          currentValueFormat={selectedItem?.value_format}
          handleData={handleData}
          newDefaultValue={values?.default_value || ''}
          newFieldType={values?.field_type || ''}
          newValueFormat={values?.value_format}
        />

        {selectedItem?.source === Sources.MATCHING && (
          <>
            <MatchFields isReadOnly key={JSON.stringify(selectedItem.match_config.match_fields.edges)} list={selectedItem.match_config.match_fields.edges} />

            <MatchRule isEditView rule={selectedItem.match_config.rule} />

            {[
              DataTableMatchRules.TABLE_MATCHING,
              DataTableMatchRules.TABLE_MATCHING_ALPHA,
              DataTableMatchRules.TABLE_MATCHING_BETA,
              DataTableMatchRules.TABLE_MATCHING_GAMMA,
              DataTableMatchRules.TABLE_MATCHING_SONNET_V2_OCT_2024
            ].includes(selectedItem.match_config.rule) && <MatchPrompt handleData={handleData} ruleConfig={selectedItem.match_config.rule_config} />}
          </>
        )}

        {selectedItem?.source !== Sources.MATCHING && (
          <DefaultValueInput
            currentFieldType={selectedItem?.field_type || ''}
            currentItemId={selectedItem?.id}
            currentOptions={selectedItem?.options || undefined}
            currentValue={selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.default_value : ''}
            handleData={handleData}
            newFieldType={values?.field_type || ''}
            newOptions={values?.options || undefined}
            newValue={values?.default_value || ''}
            valueFormat={values?.value_format || selectedItem?.value_format || undefined}
          />
        )}

        <CollisionTypeInput
          collisionType={values?.collision_type || selectedItem?.collision_type}
          handleData={handleData}
          source={values?.source || selectedItem?.source}
        />

        <ResolutionStrategyInput
          collisionType={values?.collision_type || selectedItem?.collision_type}
          handleData={handleData}
          resolutionStrategyList={
            values?.resolution_strategy || (selectedItem?.inheritance_logic ? JSON.parse(selectedItem.inheritance_logic).resolution_strategy : []) || []
          }
          source={values?.source || selectedItem?.source}
        />

        <AdditionalInfoInput currentValue={selectedItem?.description || ''} handleData={handleData} newValue={values?.description} />

        <BooleanInputs
          currentDefaultFieldOnDashboard={selectedItem?.default_field_on_dashboard}
          currentDisplayAccountingImpact={selectedItem?.display_accounting_impact}
          currentDisplayAnnotations={selectedItem?.display_annotations}
          currentDisplayIfEmpty={selectedItem?.display_if_empty}
          currentDisplayOnChecklist={selectedItem?.display_on_checklist}
          currentItemId={selectedItem?.id}
          handleData={handleData}
        />
      </AccordionDetails>
    </Accordion>
  )
}
